import WIDGETS_COMMON from "../common/widgets";
import { combineElements } from "../../../parser/allparser";

const ABSOLUTE = {
	iconList: {},
	navMenu: {},
	countdown: {},
	codeview: {},
	viewer3d: {},
	audioPlayer: {},
	qrCode: {},
	map: {},
	socialIcons: {
		input: {
			showText: true,
			itemGap: 0.9,
			iconGap: 1,
			iconSize: {
				type: "custom",
				params: 18,
				value: "18px",
			},
		}
	},
	invisibleButton: {},
};

const WIDGETS_ABSOLUTE = combineElements(WIDGETS_COMMON, ABSOLUTE);
export default WIDGETS_ABSOLUTE;
