import styles from "./styles/DraggablePanel.module.scss";
import React, { useState } from "react";
import Draggable from "react-draggable";
import classNames from "classnames";
import { Button, Icon } from "semantic-ui-react";

const DraggablePanel = ({ children }) => {
	const [extended, setExtended] = useState(true);

	const panelStyle = extended
		? {
			width: "300px",
			minHeight: "400px",
		}
		: {
			width: "auto",
			minHeight: "auto",
			borderRadius: "12px",
			overflow: "hidden",
		};

	return (
		<Draggable bounds="body" handle=".dragHandleDP">
			<div className={styles.panelDraggable} style={panelStyle}>
				{extended ? (
					<div className={styles.header}>
						<Icon
							link
							name="chevron circle down"
							// size="large"
							onClick={() => setExtended(false)}
						/>
						<div
							className={classNames(
								styles.dragHandle,
								"dragHandleDP"
							)}
						>
							: : : : : : :
						</div>
					</div>
				) : (
					<div
						className={classNames(styles.foldedBtn, "dragHandleDP")}
					>
						<p>Customize</p>
						<Icon
							link
							name="chevron circle right"
							size="big"
							onClick={() => setExtended(true)}
						/>
					</div>
				)}

				{extended && children}
			</div>
		</Draggable>
	);
};

export default DraggablePanel;
