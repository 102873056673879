import { useMutation, useQuery } from "react-query";
import { secureAxios } from "../_helper/auth";
import { queryClient } from "../_helper/query";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const ORG_TABLES = ["qd_product", "qd_product_category", "directus_users"];

function getOrgFilterString(collection, org) {
    if (org) {
        if (ORG_TABLES.includes(collection)) {
            return `&filter[organization][_eq]=${org}`;
        }
        return null;
    }
    return null;
}

export const useSingleItem = (collection, queryString, enabled) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    }
    return useQuery(
        ["items", collection, queryString],
        () =>
            secureAxios.get(`/${query}${queryString}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useUpdateItemMutation = () => {
    return useMutation(
        (data) => {
            return secureAxios.patch(
                `/items/${data.collection}/${data.id}`,
                data.data
            );
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(["items", variables.collection]);
            },
        }
    );
};

export const useItemsFine = (
    collection,
    fieldsString,
    enabled,
    refresh,
    org
) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "roles" || collection === "directus_roles") {
        query = "roles";
    } else if (collection === "directus_files") {
        query = "files";
    }

    const org_filter_string = getOrgFilterString(collection, org);
    if (org_filter_string) {
        fieldsString = `${fieldsString}${org_filter_string}`;
    }
    return useQuery(
        ["items", collection, fieldsString],
        () =>
            secureAxios
                .get(`/${query}?${fieldsString}`)
                .then((res) => res.data),
        {
            enabled: enabled,
            staleTime: twentyFourHoursInMs,
            refetchInterval: refresh && refresh > 0 ? refresh * 1000 : false,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
        }
    );
};
