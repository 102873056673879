/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ContentEditable from "react-contenteditable";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function PageLink({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;
    let content = input?.content;

    const onPaste = (eve) => {
        eve.preventDefault();
        var text = eve.clipboardData.getData("text");
        document.execCommand("insertText", false, text);
    };

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    textAlign: input.textAlign,
                    // "p::first-letter": {
                    //     fontSize: "200%",
                    // },
                }}
            >
                <ContentEditable
                    // html={input ? input.content : data.input.content}
                    html={content ? content : ""}
                    disabled={true}
                    tagName="p"
                    placeholder="Start Editing..."
                    onPaste={onPaste}
                    style={{
                        lineHeight: ps.style.lineHeight,
                        fontFamily: ps.style.fontFamily,
                        outline: "none",
                        background: "transparent",
                        // whiteSpace: "pre-wrap",
                    }}
                />
            </div>
        </ElementWrapper>
    );
}

export default PageLink;
