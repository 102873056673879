import React, { useEffect } from "react"
import { Dropdown, Form } from "semantic-ui-react";
import log from "cslog"
import { GFONTS } from "../extra/google_fonts";


export let GOOGLE_FONTS = []
const fonts = Object.values(GFONTS).map(item => item.slice(0, 30)).flat();
GOOGLE_FONTS = fonts;
log.d(GOOGLE_FONTS, "Google Fonts in FontPicker");



// export const GOOGLE_FONTS = [
// 	"Roboto",
// 	"Patrick Hand",
// 	"Open Sans",
// 	"Lato",
// 	"Old Standard TT",
// 	"Abril Fatface",
// 	"PT Serif",
// 	"Ubuntu",
// 	"Vollkorn",
// 	"Droid",
// 	"Montserrat",
// 	"Oswald",
// 	"Anton",
// 	"Teko",
// 	"Fjalla One",
// 	"Shadows Into Light",
// 	"Lilita One",
// 	"Barriecito",
// 	"Source Sans Pro",
// 	"Raleway",
// 	"Merriweather",
// 	"Pacifico",
// 	"Annie Use Your Telescope",
// 	"Monoton",
// 	"Lobster",
// ];

export function QFontLoader() {
	useEffect(() => {
		window.WebFont.load({
			google: {
				families: GOOGLE_FONTS,
			},
		});
	});
	// return <h2>Hello World</h2>;
	return null;
}

function FontPicker({ label, selectedFont, setSelectedFont }) {
	const options = GOOGLE_FONTS.map((item) => {
		return {
			key: item,
			text: <span style={{ fontFamily: item }}>{item}</span>,
			value: item,
		};
	});

	return (
		<Form.Field>
			<label>{label}</label>

			<Dropdown
				placeholder="Select Font"
				options={options}
				selection
				search
				allowAdditions
				value={selectedFont}
				onChange={(e, { value }) => setSelectedFont(value)}
			/>
		</Form.Field>
	);
}

export default FontPicker