import React, { useState } from "react";

import { Modal, Button, Tab, Form, Card, Placeholder } from "semantic-ui-react";
import ImageLibrery from "./ImageLibrery";
import SVGLibrary from "./SVGLibrary";
import Unsplash from "./Unsplash";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    elementState,
    selectedElementState,
    selectedImageState,
} from "../db/elementDb";
import log from "cslog";
import Texture from "./Texture";
import TenorGif from "./TenorGif";
import { editorTypeState } from "../db/editorConfig";
import Trash from "./Trash";
import { imageLibState } from "../db/imageLibDb";
import Pexels from "./Pexles";
import ShapeLibrary from "./ShapeLibrary";
import Pixabay from "./Pixabay";

const ImagePlaceholder = () => {
    return [1, 2].map((ittem) => (
        <Card.Group key={ittem} itemsPerRow={3}>
            {[1, 2, 3].map((item) => (
                <Card key={item}>
                    <Card.Content>
                        <Placeholder>
                            <Placeholder.Image rectangular />
                        </Placeholder>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    ));
};

//type= img, svg
const ImagePanel = ({
    type = "img",
    onPhoto,
    label,
    button,
    multiple = false,
    minNumber = 2,
}) => {
    const etype = useRecoilValue(editorTypeState);
    const [imageLib, setImageLib] = useRecoilState(imageLibState);
    // const selectedImage = useRecoilValue(selectedImageState);
    const selectedImage = useRecoilValue(selectedImageState);
    const [open, setOpen] = useState(false);
    const [sourceIndex, setSourceIndex] = useState(0);
    const selectedElement = useRecoilValue(selectedElementState);
    const edata = useRecoilValue(elementState(selectedElement));
    const [pdata, setPData] = useRecoilState(elementState(edata.pid));

    // log.d(edata, "EDATA");
    // log.d(pdata, "PDATA");

    const isSelected = multiple
        ? Array.isArray(selectedImage)
            ? selectedImage.length >= minNumber
            : false
        : selectedImage.id !== undefined;

    const onPhotoSelect = (photo) => {
        // log.d(photo, "seletedPhoto");

        // if (etype.type === "magazine") {
        if (!multiple) {
            onPhoto(photo);
            if (etype.layout === "absolute") {
                if (pdata?.childs && edata?.id) {
                    const child_data = pdata?.childs[edata.id];
                    setPData({
                        ...pdata,
                        childs: {
                            ...pdata.childs,
                            [edata.id]: {
                                ...child_data,
                                width: child_data.width,
                                height:
                                    (child_data.width * photo.height) /
                                    photo.width,
                            },
                        },
                    });
                }
            }
        } else {
            onPhoto(photo.filter((item) => item?.id !== undefined));
        }
    };

    const panes =
        type === "img"
            ? [
                  {
                      menuItem: {
                          key: "collection",
                          icon: "globe",
                          content: "Collection",
                          pagination: false,
                      },
                      render: () => (
                          <ImageLibrery
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "unsplash",
                          icon: "images",
                          content: "Unsplash",
                          pagination: true,
                      },
                      render: () => (
                          <Unsplash
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "pexels",
                          icon: "images",
                          content: "Pexels",
                          pagination: true,
                      },
                      render: () => (
                          <Pexels
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "pixabay",
                          icon: "images",
                          content: "Pixabay",
                          pagination: true,
                      },
                      render: () => (
                          <Pixabay
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "pixabay_png",
                          icon: "images",
                          content: "Pixabay PNG",
                          pagination: true,
                      },
                      render: () => (
                          <Pixabay
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                              type="png"
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "tenor",
                          icon: "images",
                          content: "GIF",
                      },
                      render: () => (
                          <TenorGif
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "texture",
                          icon: "images",
                          content: "Texture",
                      },
                      render: () => (
                          <Texture
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
                  {
                      menuItem: {
                          key: "trash",
                          icon: "trash alternate",
                          content: "Trash",
                      },
                      render: () => (
                          <Trash
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
              ]
            : type === "shape"
            ? [
                  {
                      menuItem: {
                          key: "shape",
                          icon: "trash alternate",
                          content: "Shape",
                      },
                      render: () => (
                          <ShapeLibrary
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
              ]
            : [
                  {
                      menuItem: {
                          key: "svg",
                          icon: "trash alternate",
                          content: "SVG",
                      },
                      render: () => (
                          <SVGLibrary
                              placeholder={<ImagePlaceholder />}
                              multiple={multiple}
                          />
                      ),
                  },
              ];

    const getTabInfo = (index) => {
        return panes[index]?.menuItem;
    };
    return (
        <Modal
            size="large"
            open={open}
            // trigger={<Button primary>Select Photo</Button>}
            trigger={
                <Form.Field>
                    {label && <label>{label}</label>}
                    {button ? button : <Button primary>Change Image</Button>}
                </Form.Field>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{
                zIndex: 5000,
                backgroundColor: "yellow",
            }}
        >
            {/* <Modal.Header>Select Image</Modal.Header> */}
            <Modal.Content
                style={{ height: "80vh", overflow: "hidden", zIndex: 5000 }}
            >
                {/* <Modal.Content scrolling> */}
                <Tab
                    menu={{ fluid: true, vertical: true, tabular: true }}
                    panes={panes}
                    onTabChange={(e, { activeIndex }) => {
                        log.d(activeIndex, "ActiveTAbIndex");
                        setSourceIndex(activeIndex);
                        const pane = getTabInfo(activeIndex);
                        setImageLib({
                            source: pane?.key,
                            pagination: pane.pagination,
                        });
                    }}
                />
            </Modal.Content>
            <Modal.Actions
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                }}
            >
                {" "}
                <Button
                    positive
                    disabled={!isSelected}
                    onClick={() => {
                        // onPhoto(selectedImage);
                        onPhotoSelect(selectedImage);
                        setOpen(false);
                    }}
                >
                    Proceed
                </Button>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <div
                    style={{
                        display: "inline-block",
                        fontSize: "1rem",
                        color: "#999999",
                        userSelect: "none",
                    }}
                >
                    {sourceIndex === 0
                        ? ""
                        : sourceIndex === 1
                        ? "Powered By Unsplash"
                        : "Powered By Tenor"}
                </div>
                {/* {imageLib.pagination && <h5>Pagination</h5>} */}
            </Modal.Actions>
        </Modal>
    );
};

export default ImagePanel;
