import { atom, useRecoilState } from "recoil";

export const DEFAULT_ROLE_LIST = ["Public", "Admin"];

export const enablePermissionsState = atom({
    key: "enable_permission",
    default: false,
});

export const rolesState = atom({
    key: "user_roles",
    default: DEFAULT_ROLE_LIST,
});

export const permissionsPageState = atom({
    key: "permissions_page",
    default: {},
});

// ====================================

export const protectedAccessState= atom({
    key: "protected_access",
    default: {
        enabled: false,
        password: "qureal"
    }
})