const PRIMARY = {
	page: {
		id: "page",
		type: "page",
		sections: [],
	},
	section: {
		id: "section",
		type: "section",
		group: "section",
		pgroup: "free",
		name: "Section",
		extends: {
			// type: "default",
			type: "gstyle",
			name: "section",
		},
		dims: {
			width: "100%",
			height: "100%",
		},
		// widths: [100],
		childs: [],
		cstate: "normal",
		style: {},
		tablet: {},
		mobile: {},
		input: {},
		itablet: {},
		imobile: {},
		itype: {},
	},
	headerSection: {
		id: "headerSection",
		type: "headerSection",
		group: "section",
		pgroup: "free",
		name: "Header Section",
		extends: {
			type: "default",
			name: "section",
		},
		dims: {
			width: "100%",
			height: "100%",
		},
		widths: [100],
		childs: [],
		cstate: "normal",
		style: {
			background: {
				type: "simple",
				params: "white",
				value: "white",
			},
			backgroundFilter: {
				type: "none",
				value: "none",
			},
		},
		tablet: {},
		mobile: {},
		input: {
			name: "Header",
			fixedOnTop: true,
			solid: true,
		},
		itablet: {},
		imobile: { orientation: "h", fixedOnTop: true },
		itype: {
			fixedOnTop: "boolean",
			solid: "boolean",
		},
		exclude: ["shapeDivider"],
	},
	box: {
		id: "box",
		type: "box",
		group: "vlayout",
		pgroup: "free",
		name: "Box",
		extends: {
			type: "gstyle",
			name: "box",
		},
		css: null,
		cstate: "normal",
		dims: {
			width: "100%",
			height: "auto",
		},
		fixStyle: {},
		style: {},
		hover: {},
		mobile: {},
		input: {},
		childs: [],
	},
	buttonGroup: {
		id: "buttonGroup",
		elementGroup: "primary",
		type: "buttonGroup",
		group: "basic",
		pgroup: "free",
		name: "Button Group",
		extends: {
			type: "default",
			name: "buttonGroup",
		},
		css: null,
		cstate: "normal",
		dims: {
			width: "100%",
			height: "auto",
		},
		fixStyle: {},
		style: {},
		hover: {},
		mobile: {},
		input: {},
		childs: [],
	},
	hbox: {
		id: "hbox",
		type: "hbox",
		group: "hlayout",
		name: "Horizontal Box",
		extends: null,
		css: null,
		dims: {
			width: "100%",
			height: "auto",
		},
		fixStyle: {},
		style: {},
		hover: {},
		mobile: {},
		input: {},
		childs: [],
	},
	grid: {
		id: "grid",
		type: "grid",
		group: "vlayout",
		name: "Grid",
	},
	header: {
		id: "header",
		elementGroup: "primary",
		type: "header",
		group: "basic",
		pgroup: "free",
		name: "Header",
		extends: {
			// type: "default",
			type: "gstyle",
			name: "header",
		},
		cstate: "normal",
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	p: {
		id: "p",
		elementGroup: "primary",
		type: "p",
		group: "basic",
		pgroup: "free",
		name: "Text",
		extends: {
			type: "gstyle",
			name: "p",
		},

		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	link: {
		id: "link",
		elementGroup: "primary",
		type: "link",
		group: "basic",
		pgroup: "free",
		name: "Link",
		extends: {
			type: "default",
			name: "link",
		},

		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	richtext: {
		id: "richtext",
		elementGroup: "primary",
		type: "richtext",
		group: "basic",
		pgroup: "free",
		name: "Rich Text",
		extends: {
			type: "default",
			name: "richtext",
		},

		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	htmltext: {
		id: "htmltext",
		elementGroup: "primary",
		type: "htmltext",
		group: "basic",
		pgroup: "free",
		name: "Rich Text",
		extends: {
			type: "default",
			name: "htmltext",
		},

		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	button: {
		id: "button",
		elementGroup: "primary",
		type: "button",
		group: "basic",
		pgroup: "free",
		name: "Button",
		readymate: "button" /* button type readymate collection */,
		extends: {
			type: "gstyle",
			name: "button",
		},

		css: null,
		style: {},
		hover: {},
		tablet: {
			// backgroundColor: "red",
		},
		mobile: {
			// backgroundColor: "orange",
		},
		input: {},
		itype: {
			content: "text",
		},
		itablet: {},
		imobile: {},
	},
	richDialog: {
		id: "richDialog",
		elementGroup: "primary",
		type: "richDialog",
		group: "basic",
		pgroup: "free",
		name: "Rich Dialog",
		readymate: "button" /* button type readymate collection */,
		extends: {
			type: "default",
			name: "richDialog",
		},

		css: null,
		style: {},
		hover: {},
		tablet: {
			// backgroundColor: "red",
		},
		mobile: {
			// backgroundColor: "orange",
		},
		input: {
			html: [
				{
					type: "paragraph",
					children: [
						{
							text: "",
						},
					],
				},
			],
		},
		itype: {
			html: "html",
		},
		itablet: {},
		imobile: {},
	},
	img: {
		id: "img",
		elementGroup: "primary",
		type: "img",
		group: "basic",
		pgroup: "free",
		name: "Image",
		extends: {
			type: "default",
			name: "img",
		},

		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	video: {
		id: "video",
		elementGroup: "primary",
		type: "video",
		group: "basic",
		pgroup: "pro",
		name: "Video",
		extends: {
			type: "default",
			name: "video",
		},

		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	blank: {
		id: "blank",
		elementGroup: "primary",
		type: "blank",
		group: "basic",
		pgroup: "free",
		name: "Space",
		readymate: null /* button type readymate collection */,
		extends: {
			type: "default",
			name: "blank",
		},

		css: null,
		style: {},
		tablet: {},
		mobile: {},
		input: {},
		itype: {},
		itablet: {},
		imobile: {},
	},
	divider: {
		id: "divider",
		elementGroup: "primary",
		type: "divider",
		group: "basic",
		pgroup: "free",
		name: "Divider",
		readymate: null /* button type readymate collection */,
		extends: {
			type: "default",
			name: "divider",
		},

		css: null,
		style: {},
		tablet: {},
		mobile: {},
		input: {},
		itype: {},
		itablet: {},
		imobile: {},
	},
	icon: {
		id: "icon",
		elementGroup: "primary",
		type: "icon",
		group: "basic",
		pgroup: "free",
		name: "Icon",
		extends: {
			type: "default",
			name: "icon",
		},

		css: null,
		style: {
			background: {
				type: "simple",
				params: "#E0E1E2",
				value: "#E0E1E2",
			},
		},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	slidingText: {
		id: "slidingText",
		elementGroup: "primary",
		type: "slidingText",
		group: "basic",
		pgroup: "free",
		name: "Sliding Text",
		extends: {
			// type: "default",
			type: "default",
			name: "slidingText",
		},

		cstate: "normal",
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	shape: {
		id: "shape",
		elementGroup: "primary",
		type: "shape",
		group: "basic",
		pgroup: "free",
		name: "Shape",
		extends: {
			type: "default",
			name: "shape",
		},

		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	list: {
		id: "list",
		elementGroup: "primary",
		type: "list",
		group: "basic",
		pgroup: "free",
		name: "List",
		extends: {
			type: "default",
			name: "list",
		},
		css: null,

		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	menu: {
		id: "menu",
		type: "menu",
		group: "basic",
		pgroup: "free",
		name: "Menu",
		extends: {
			type: "default",
			name: "menu",
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	placeholder: {
		id: "placeholder",
		type: "placeholder",
		group: "basic",
		pgroup: "free",
		name: "Placeholder",
		extends: {
			type: "default",
			name: "placeholder",
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
};

export default PRIMARY;
