import React from "react";
import { QueryClientProvider } from "react-query";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { LOGIN_URL } from "./constants";
import Application from "./editor/Application";
import CreateNew from "./editor/CreateNew";
import CreateNewFromTemplate from "./editor/CreateNewFromTemplate";
import { history } from "./editor/_helper/history";
import { queryClient } from "./editor/_helper/query";
// import { ReactQueryDevtools } from "react-query-devtools";
import Unauthorized from "./pages/Unauthorized";
import PleaseSelectCreation from "./pages/PleaseSelectCreation";

import VerifyAuth from "./VerifyAuth";
import FullScreenCenter from "./editor/components/FullScreenCenter";

// setAuthToken("creative@123");
// saveTokens({
//     access_token: "creative@123",
// });

const EditorApplication = () => {
    return (
        <Router history={history}>
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <RecoilRoot>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            component={() => {
                                // window.location.href = LOGIN_URL;
                                return (
                                    <FullScreenCenter gap={1}>
                                        <h5>Welcome to Qureal</h5>
                                        <p>
                                            You need to login to access this
                                            page
                                        </p>
                                    </FullScreenCenter>
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/"
                            component={PleaseSelectCreation}
                        />
                        <Route
                            exact
                            path="/unauthorized"
                            component={Unauthorized}
                        />
                        <Route
                            exact
                            path="/no_creation"
                            component={PleaseSelectCreation}
                        />
                        <VerifyAuth>
                            <Switch>
                                <Route
                                    exact
                                    path="/new"
                                    component={CreateNew}
                                />
                                <Route
                                    exact
                                    path="/new/from_template/:tid"
                                    component={CreateNewFromTemplate}
                                />
                                <Route path="/:id" component={Application} />
                            </Switch>
                        </VerifyAuth>
                    </Switch>
                </RecoilRoot>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </Router>
    );
};

export default EditorApplication;
