import React from "react";
import { BASE_URL, STATIC_ROOT } from "../../constants";
import { restoreAccessToken } from "./auth";

export function getImageURL(id, key = "system-medium-contain") {
	if (id) {
		// return `${BASE_URL}assets/${id}?key=${key}&access_token=${restoreAccessToken()}`;
		return `${BASE_URL}assets/${id}?key=${key}`;
	} else {
		// return AvatarPhoto;
		return null;
	}
}

export function getAssetURL(fileObj) {
	return `${STATIC_ROOT}${fileObj?.filename_disk}`;
}

export function getFileURL(id) {
	return `${BASE_URL}assets/${id}`;
}

export function parseImgURL(imgSrc) {
	const imgObj = imgSrc.params;

	if (!imgObj) {
		return imgSrc.value;
	}

	const srcType = imgObj?.srcType;

	if (!srcType) {
		return imgSrc.value;
	}
	if (srcType === "lib") {
		if (imgObj.filename_disk !== null) {
			// return getAssetURL(imgObj);
			return getImageURL(imgObj.id);
		} else {
			return getImageURL(imgObj.id);
		}
	} else if (srcType === "pixabay") {
		return imgObj.dev;
		// return imgObj.full;
	}
	return imgSrc.value;
}

export function resolveClipPath(value) {
	if (value) {
		let out = `${value.type}(`;
		if (value.hflip) {
			value.path.forEach((point) => {
				out += `${100 - point[0]}% ${point[1]}%,`;
			});
		} else if (value.vflip) {
			value.path.forEach((point) => {
				out += `${point[0]}% ${100 - point[1]}%,`;
			});
		} else {
			value.path.forEach((point) => {
				out += `${point[0]}% ${point[1]}%,`;
			});
		}
		out = out.slice(0, -1);
		out += ")";
		return out;
	}
	return null;
}
