export const SHAPES = {
    none: null,
    wave:
        "M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z",
    curve: "M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z",
    hill:
        "M1,120,161,.2l97.7,103.6,89.2-53.9,111.5,62.3L575,.2l79.7,74.3L729,19.9,865.6,105,965.1.2l109.7,94.6L1159.3.2,1200,52.3V0H0Z",
    tringle: "M1200 0L0 0 598.97 114.72 1200 0z",
    tilt: "M1200 120L0 16.48 0 0 1200 0 1200 120z",
    arrow: "M649.97 0L550.03 0 599.91 54.12 649.97 0z",
    thorns:
        "M.5,0H1200V112L1188.1,6l-11.9,106L1164.3,6l-11.9,106L1140.6,6l-11.9,106L1116.8,6l-11.9,106L1093,6l-11.9,106L1069.2,6l-11.8,106L1045.5,6l-11.9,106L1021.7,6l-11.9,106L997.9,6,986.1,112,974.2,6,962.3,112,950.4,6,938.5,112,926.6,6,914.8,112,902.9,6,891,112,879.1,6,867.2,112,855.3,6,843.5,112,831.6,6,819.7,112,807.8,6,795.9,112,784.1,6,772.2,112,760.3,6,748.4,112,736.5,6,724.7,112,712.8,6,700.9,112,689,6,677.1,112,665.2,6,653.4,112,641.5,6,629.6,112,617.7,6,605.9,112,594,6,582.1,112,570.2,6,558.3,112,546.5,6,534.6,112,522.7,6,510.8,112,498.9,6,487.1,112,475.2,6,463.3,112,451.4,6,439.6,112,427.7,6,415.8,112,403.9,6,392,112,380.2,6,368.3,112,356.4,6,344.5,112,332.6,6,320.8,112,308.9,6,297,112,285.1,6,273.2,112,261.4,6,249.5,112,237.6,6,225.7,112,213.8,6,202,112,190.1,6,178.2,112,166.3,6,154.4,112,142.6,6,130.7,112,118.8,6,106.9,112,95,6,83.2,112,71.3,6,59.4,112,47.5,6,35.6,112,23.8,6,11.9,112,0,6Z",
    book:
        "M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z",
    denseWave:
        "M0,96L6.5,96C13,96,26,96,39,117.3C51.9,139,65,181,78,170.7C90.8,160,104,96,117,74.7C129.7,53,143,75,156,90.7C168.6,107,182,117,195,112C207.6,107,221,85,234,74.7C246.5,64,259,64,272,64C285.4,64,298,64,311,64C324.3,64,337,64,350,58.7C363.2,53,376,43,389,69.3C402.2,96,415,160,428,170.7C441.1,181,454,139,467,138.7C480,139,493,181,506,197.3C518.9,213,532,203,545,170.7C557.8,139,571,85,584,58.7C596.8,32,610,32,623,53.3C635.7,75,649,117,662,117.3C674.6,117,688,75,701,58.7C713.5,43,726,53,739,90.7C752.4,128,765,192,778,202.7C791.4,213,804,171,817,138.7C830.3,107,843,85,856,69.3C869.2,53,882,43,895,69.3C908.1,96,921,160,934,197.3C947,235,960,245,973,256C985.9,267,999,277,1012,245.3C1024.9,213,1038,139,1051,117.3C1063.8,96,1077,128,1090,138.7C1102.7,149,1116,139,1129,160C1141.6,181,1155,235,1168,240C1180.5,245,1194,203,1206,154.7C1219.5,107,1232,53,1245,48C1258.4,43,1271,85,1284,122.7C1297.3,160,1310,192,1323,208C1336.2,224,1349,224,1362,229.3C1375.1,235,1388,245,1401,218.7C1414.1,192,1427,128,1434,96L1440,64L1440,0L1433.5,0C1427,0,1414,0,1401,0C1388.1,0,1375,0,1362,0C1349.2,0,1336,0,1323,0C1310.3,0,1297,0,1284,0C1271.4,0,1258,0,1245,0C1232.4,0,1219,0,1206,0C1193.5,0,1181,0,1168,0C1154.6,0,1142,0,1129,0C1115.7,0,1103,0,1090,0C1076.8,0,1064,0,1051,0C1037.8,0,1025,0,1012,0C998.9,0,986,0,973,0C960,0,947,0,934,0C921.1,0,908,0,895,0C882.2,0,869,0,856,0C843.2,0,830,0,817,0C804.3,0,791,0,778,0C765.4,0,752,0,739,0C726.5,0,714,0,701,0C687.6,0,675,0,662,0C648.6,0,636,0,623,0C609.7,0,597,0,584,0C570.8,0,558,0,545,0C531.9,0,519,0,506,0C493,0,480,0,467,0C454.1,0,441,0,428,0C415.1,0,402,0,389,0C376.2,0,363,0,350,0C337.3,0,324,0,311,0C298.4,0,285,0,272,0C259.5,0,246,0,234,0C220.5,0,208,0,195,0C181.6,0,169,0,156,0C142.7,0,130,0,117,0C103.8,0,91,0,78,0C64.9,0,52,0,39,0C25.9,0,13,0,6,0L0,0Z",
};
