import { atom, selector } from "recoil";
import LOGO_US_PHARMA from "../media/imgs/logo_US_Pharma.png";
import { EDITOR_CONFIGS } from "../../constants";

export const editorSettingsState = atom({
	key: "editorSettings",
	default: EDITOR_CONFIGS,
	default_bk: {
		//navbar
		// logo: null,
		logo: LOGO_US_PHARMA,
		// logo: "http://api.qureal.com/passets/41556188-6f35-4efb-a68f-3cd9c81e428e.png",
		home: true,
		home_url: "http://app.qureal.com",
		view: true,
		fullscreen: true,
		preview: true,
		save: true,
		export: true,
		publish: true,
		page_template: true,
		creation_template: true,
		// action: null,
		action: {
			text: "Submit",
			icon: "arrow right",
			color: "royalblue",
			confirm: {
				title: "Sumbit to designers",
				description: "Once submitted, you can't edit it",
				btnPositive: "Yes",
				btnNegative: "Cancel",
			},
			field: "submitted",
			value: true,
		},
		//tabs
		seo: true,
		graphics: true,
	},
});

export const editorTypeState = atom({
	key: "editorType",
	default: {
		type: null,
	},
});

export const editorPanelState = atom({
	key: "editorPanel",
	default: "components",
});

export const editorPanelFullState = atom({
	key: "editorPanelFull",
	default: {
		id: "components",
		name: "Components",
		icon: "th large",
	},
});

export const editorLayoutState = atom({
	key: "editorLayout",
	default: "left",
});

export const editorViewState = atom({
	key: "editorView",
	default: 2040,
});

export const deviceFrameState = atom({
	key: "deviceFrame",
	default: false,
});

//Editor Modes
// full - edit everyting
// content - edit only data/content
// design- edit only design
// custom - coming soon....
export const editorModeState = atom({
	key: "editorMode",
	default: "full",
});

export const imageLibConfigState = atom({
	key: "imageLib",
	default: {
		folder: null,
	},
});

export const editorConfigState = selector({
	key: "editorConfig",
	get: ({ get }) => {
		const editorLayout = get(editorLayoutState);
		const editorView = get(editorViewState);
		const deviceFrame = get(deviceFrameState);
		const editorMode = get(editorModeState);
		const editorSettings = get(editorSettingsState);

		return {
			editorLayout: editorLayout,
			editorView: editorView,
			deviceFrame: deviceFrame,
			editorMode: editorMode,
			editorSettings: editorSettings,
		};
	},
});

export const premiumDialogState = atom({
	key: "premiumDialogState",
	default: {
		open: false,
		title: "Hello World",
	},
});

export const authState = atom({
	key: "authState",
	default: {
		isAuth: true,
		user: null,
	},
});

// ========================================================

export const linkableState = atom({
	key: "linkableState",
	default: false,
});

export const linksState = atom({
	key: "linksState",
	default: {},
})