import { TOP_HEADER_ID } from "../../data/defaults";
import { extractData, extractDataMinimal } from "../../data/extractor";
import { dataPageState } from "../../db/dataDb";
import { elementState } from "../../db/elementDb";
import { pageState } from "../../db/siteConfig";

export const encodeWebsiteData = async (snapshot, config) => {
    const pages = [];
    const pagelist = await snapshot.getPromise(pageState);
    await Promise.all(
        pagelist.map(async (page) => {
            const telementArray = {};
            // const cid = "root";
            const cid = page.id;
            const data = await snapshot.getPromise(elementState(page.id));
            await extractData(cid, data.pid, data, snapshot, telementArray);
            pages.push({
                // pid: page.pid,
                id: page.id,
                name: page.name,
                page_no: page.page_no,
                info: page,
                content: await snapshot.getPromise(dataPageState(page.id)),
                html: telementArray,
            });
        })
    );

    const topHeaderOut = {};
    const topHeaderData = await snapshot.getPromise(
        elementState(TOP_HEADER_ID)
    );
    await extractData(
        TOP_HEADER_ID,
        null,
        topHeaderData,
        snapshot,
        topHeaderOut
    );

    const data = {
        page_info: pagelist,
        pages: pages,
        topHeader: topHeaderOut,
    };

    return data;
};

export const encodeWebsiteDataMinimal = async (snapshot, config) => {
    const pages = [];
    const pagelist = await snapshot.getPromise(pageState);
    await Promise.all(
        pagelist.map(async (page) => {
            const telementArray = {};
            // const cid = "root";
            const cid = page.id;
            const data = await snapshot.getPromise(elementState(page.id));
            await extractDataMinimal(
                cid,
                data.pid,
                data,
                snapshot,
                telementArray
            );
            pages.push({
                // pid: page.pid,
                id: page.id,
                name: page.name,
                page_no: page.page_no,
                info: page,
                html: telementArray,
            });
        })
    );

    const topHeaderOut = {};
    const topHeaderData = await snapshot.getPromise(
        elementState(TOP_HEADER_ID)
    );
    await extractData(
        TOP_HEADER_ID,
        null,
        topHeaderData,
        snapshot,
        topHeaderOut
    );

    const data = {
        page_info: pagelist,
        pages: pages,
        topHeader: topHeaderOut,
    };

    return data;
};
