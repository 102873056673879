const CHARTS_COMMON = {
    pie: {
        style: {},
        input: {
            title: "Pie Chart",
            height: 250,
            graphDataSingle: {},
        },
    },
    lineChart: {
        style: {
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 50,
                    bottom: 50,
                    left: 60,
                    right: 140,
                },
                value: "50px 130px 50px 60px",
            },
        },
        mobile: {
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 30,
                    bottom: 90,
                    left: 60,
                    right: 30,
                },
                value: "50px 30px 90px 60px",
            },
        },
        input: {
            title: "Line Chart",
            height: 250,
            graphData: {},
            legends: {
                anchor: "bottom-right",
                direction: "column",
                translateX: 120,
                translateY: 0,
            },
        },
        imobile: {
            legends: {
                anchor: "bottom-right",
                direction: "row",
                translateX: 0,
                translateY: 70,
            },
        },
    },
    barChart: {
        style: {
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 50,
                    bottom: 50,
                    left: 60,
                    right: 130,
                },
                value: "50px 130px 50px 60px",
            },
        },
        mobile: {
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 50,
                    bottom: 90,
                    left: 60,
                    right: 30,
                },
                value: "50px 30px 90px 60px",
            },
        },
        input: {
            title: "Bar Chart",
            height: 250,
            graphData: {},
            groupMode: "stacked",
            orientation: "v",
            reverseDirection: false,
            enableGridX: true,
            enableGridY: true,
            legends: {
                anchor: "bottom-right",
                direction: "column",
                translateX: 130,
                translateY: 0,
            },
        },
        imobile: {
            legends: {
                anchor: "bottom-right",
                direction: "row",
                translateX: 0,
                translateY: 70,
            },
        },
    },
};

export default CHARTS_COMMON;
