/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import { useAtom } from "jotai";
import React from "react";
import { baseURLAtom } from "../../../db";
import { decodeStyle, parseImgURL, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";
// import { parseImageSrc } from "../../../../dynamicdata/parser";
import { parseImageSrc } from "../../../junctionAbs";
import classNames from "classnames";

function Img({ id, db, gs, dydb, ctx }) {
	const [baseURL, _] = useAtom(baseURLAtom);
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	log.d(input, "Img Input");
	log.d(baseURL, "baseURL in Img");

	// let imgSrc = input.imageSource.value;

	// imgSrc = parseImgURL(input.imageSource, baseURL);
	const imgSrc = parseImageSrc(dydb, input.imageSource);

	const mask = input.mask;

	return (
		<ElementWrapper id={id}>
			{mask?.params && (
				<svg
					viewBox={`0 0 ${mask?.params?.w} ${mask?.params?.h}`}
					style={{
						position: "fixed",
					}}
					height={mask?.params?.w}
					width={mask?.params?.h}
					preserveAspectRatio="xMidYMid meet"
				>
					<defs>
						<clipPath
							id={`mask_for_${id}`}
							clipPathUnits="objectBoundingBox"
							transform="scale(.01)"
						>
							<path d={mask?.params?.d} />
						</clipPath>
					</defs>
				</svg>
			)}

			<img
				className={classNames(input.hoverAnimation, {
					"disable_slide": input.hoverAnimation !== "qureal-none",
				})}
				// src={input.imageSource.value}
				src={imgSrc}
				style={{}}
				css={{
					...decodeStyle(ps.fixStyle, gs),
					...decodeStyle(ps.style, gs),
					clipPath: `url(#mask_for_${id})`,
					"&:hover": decodeStyle(ps.hover, gs),
					objectFit: input.imageFit,
					// width: input.imageSize.value,
					width: "100%",
					// objectFit: "contain",
					alignSelf: input.alignImage,
					//for mask
					// height: mask ? "100%" : "auto",
					height: "100%",
					pointerEvents: "none",
					transform: `${input.flipHorizontal ? "rotateY(180deg)" : ""} ${input.flipVertical ? "rotateX(180deg)" : ""}`
				}}
				alt="Img"
			// crossOrigin="anonymous"
			></img>
		</ElementWrapper>
	);
}

export default Img;
