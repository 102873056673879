export const THEME_COLORS = {
	// background color 1
	primaryColor: 'white',
	// background color 2
	primaryColor2: 'lightpink',
	// all main elements color
	secondryColor: 'royalblue',
	// attension seeking
	accentColor: 'cyan',
	// almost all text
	neutralColor: 'black',
	// all text less focused
	neutralColor2: 'grey'
}

export const THEME_FONTSET = {
	primaryFont: 'Lato',
	secondryFont: 'Raleway'
}

export const GSTYLE = {
	section: {
		extends: {
			type: 'default',
			name: 'section'
		},
	},
	box: {
		extends: {
			type: 'default',
			name: 'box'
		}
	},
	header: {
		extends: {
			type: 'default',
			name: 'header'
		},
		css: null,
		style: {},
		mobile: {},
		input: {}
	},
	p: {
		extends: {
			type: 'default',
			name: 'p'
		},
		css: null,
		style: {
			boxSizing: 'border-box',
			letterSpacing: 'normal'
		},
		mobile: {},
		input: {}
	},
	text: {
		extends: {
			type: 'default',
			name: 'text'
		},
		style: {},
		input: {},
		mobile: {}
	},
	button: {
		extends: {
			type: 'default',
			name: 'button'
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {}
	}
}


export const GSTYLE_DOCUMENT = {
	section: {
		extends: {
			type: 'default',
			name: 'section'
		},
		style: {
			background: {
				type: "simple",
				params: "transparent",
				value: "transparent",
			},
		}
	},
	box: {
		extends: {
			type: 'default',
			name: 'box'
		},
		style: {
			padding: {
				type: "single",
				params: 0,
				value: "0px",
			},
		}
	},
	header: {
		extends: {
			type: 'default',
			name: 'header'
		},
		css: null,
		style: {},
		mobile: {},
		input: {}
	},
	p: {
		extends: {
			type: 'default',
			name: 'p'
		},
		css: null,
		style: {
			boxSizing: 'border-box',
			letterSpacing: 'normal'
		},
		mobile: {},
		input: {
			fontSize: 16,
		}
	},
	richtext: {
		extends: {
			type: 'default',
			name: 'richtext'
		},
		css: null,
		style: {},
		mobile: {},
		input: {
			// fontSize: 16,
		}
	},
	button: {
		extends: {
			type: 'default',
			name: 'button'
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {}
	}
}
