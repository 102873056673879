import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { secureAxios } from '../_helper/auth'

const aiAxios = axios.create({
	baseURL: 'https://botapi.qureal.com/',
	// baseURL: 'http://localhost:8000/',
	headers: {
		'Content-Type': 'application/json'
	}
})

// type, description, tone
export const useAIWrite = (onSuccess, onDone = () => { }) => {
	return useMutation(
		data => {
			// return secureAxios.post(`/qe_ai_text/write`, data).then((res) => {
			return aiAxios.post(`/text/write`, data).then(res => {
				return res
			})
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result?.data || {})
			},
			onSettled: data => {
				onDone()
			}
		}
	)
}

// text, tone
export const useAIRewrite = (onSuccess, onDone = () => { }) => {
	return useMutation(
		data => {
			// return secureAxios.post(`/files`, data).then((res) => res.data);
			return aiAxios.post(`/text/rewrite`, data).then(res => {
				return res
			})
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result?.data || {})
			},
			onSettled: data => {
				onDone()
			}
		}
	)
}

export const useAIJSONWriter = onSuccess => {
	return useMutation(
		data => {
			return aiAxios.post('text/generate_text', data)
		},
		{
			onSuccess: onSuccess
		}
	)
}

export const useAIJSONRewriter = (onSuccess, onDone = () => { }) => {
	return useMutation(
		data => {
			return aiAxios.post('text/rewrite_json', data)
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result?.data || {})
			},
			onSettled: data => {
				onDone()
			}
		}
	)
}

export const useAILabeler = (onSuccess, onDone = () => { }) => {
	return useMutation(
		data => {
			return aiAxios.post('text/autolabel', data)
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result?.data || {})
			},
			onSettled: data => {
				onDone()
			}
		}
	)
}


export const usePresetValuesGenerator = (onSuccess, onDone = () => { }) => {
	return useMutation(
		data => {
			return aiAxios.post("gen/generate_presets_in_editor", data)
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result?.data || {});
			},
			onSettled: data => {
				onDone();
			}
		}
	)
}