import React from "react";
import log from "cslog";
import { cultivateData } from "../../data/extractor";
import {} from "../../db/bookConfig";
import { pageState } from "../../db/siteConfig";
import { dataPageState } from "../../db/dataDb";
import { TOP_HEADER_ID } from "./defaults";

export const decodeWebsiteData = async (data, set) => {
    const pages = [];
    await Promise.all(
        data.data.pages.map(async (page) => {
            const pageInfo = page.info;
            pages.push({ ...pageInfo, page_id: page.id });
            const pageHtml = page.html;
            // log.d(pageHtml, "page HTML");

            const pageContent = page.content || {};
            await set(dataPageState(page.id), pageContent);

            await cultivateData(
                pageInfo.id,
                null,
                // pageHtml.root,
                pageHtml[pageInfo.id],
                pageHtml
            );
        })
    );
    await set(pageState, pages);

    // await set(bookPageArrayState, bookPageArrayOriginal);

    // const topHeader = bookData.others?.topHeader;
    const topHeader = data.data?.topHeader;

    if (topHeader) {
        await cultivateData(
            TOP_HEADER_ID,
            null,
            topHeader[TOP_HEADER_ID],
            topHeader
        );
    }
};
