import React from "react";

function FullScreenCenter({
    children,
    gap = 10,
    height = "100vh",
    logo = true,
}) {
    return (
        <div
            style={{
                width: "100%",
                height: height,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: gap,
            }}
        >
            {logo && (
                <img
                    // src={process.env.PUBLIC_URL + "/logo.svg"}
                    src="https://api.qureal.com/assets/c90e4024-5d6a-4df0-b713-9362a6083eb9"
                    alt="Qureal Logo"
                    style={{
                        width: "70px",
                        // opacity: 0.2,
                    }}
                />
            )}
            {children}
        </div>
    );
}

export default FullScreenCenter;
