export const withResetStylingOnEnter = (editor) => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            // Remove all formatting and insert a new paragraph
            editor.insertNode({ type: "paragraph", children: [{ text: "" }] });
            return;
        }

        // Fall back to the original behavior
        insertBreak();
    };

    return editor;
};
