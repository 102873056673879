import PRIMARY_COMMON from "../common/primary";
// import { combineObjects } from "../../../parser/allparser";
import { combineElements } from "../../../parser/allparser";

const RESPONSIVE = {
    section: {
        dims: {
            width: "100%",
            height: "100%",
        },
        cstate: "normal",
        style: {
            background: {
                type: "simple",
                params: "white",
                value: "white",
            },
            // height: {
            minHeight: {
                type: "auto",
                params: "auto",
                value: "auto",
            },
            backgroundPosition: "bottom right",
            backgroundAttachment: "scroll",
            backgroundPanning: "pan_no",
            transition: "1.1s",
            // gap: 0,
            padding: {
                type: "single",
                params: 0,
                value: "0px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            backgroundFilter: {
                type: "none",
                value: "none",
            },
            // clipPath: "polygon(100% 0, 100% 91%, 69% 100%, 43% 91%, 21% 100%, 1% 93%, 0 0)",
        },
        tablet: {},
        mobile: {},
        input: {
            isInner: false,
            // global, full, custom
            sectionWidth: {
                type: "global",
            },
            // fullWidth: true,
            // fullWidthContent: false,
            sticky: {
                type: "none",
            },
            solid: true,
            width: 980,
            gapBetweenChilds: 0,
            name: "Landing",
            zIndex: 1,
            orientation: "h",
            // reverseOrder: false,
            widths: [100],
            alignChilds: "center",
            shapeDivider: {
                type: "none", //none
                path: "none",
                // "M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z",
                // color: "black",
                color: {
                    type: "config",
                    value: "neutralColor",
                },
                position: "bottom",
                height: 70,
            },
        },
        itablet: {
            orientation: "h",
        },
        imobile: {
            // width: 95,
            orientation: "v",
            gapBetweenChilds: 0,
        },
        itype: {
            // width: "sectionWidth",
            width: "standardRange",
            fullWidth: "boolean",
            fullWidthContent: "boolean",
            name: "shorttext",
            zIndex: "zIndex",
            widths: "sectionLayout",
            alignChilds: "optionRowIcon",
            orientation: "optionRowIcon",
            shapeDivider: "shapeClip",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    box: {
        css: null,
        cstate: "normal",
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            backgroundAttachment: "scroll",
            backgroundPanning: "pan_no",
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: "0px",
            },
            // border: {
            //     type: "none",
            //     params: {
            //         width: 1,
            //         color: "black",
            //     },
            //     value: "none",
            // },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            backgroundFilter: {
                type: "none",
                value: "none",
            },
        },
        style__hover: {},
        states: ["normal", "hover"],
        mobile: {},
        input: {
            alignChilds: "stretch",
            // sticky: {
            //     type: "none",
            // },
            action: {
                type: "none",
            },
            hoverAnimation: "qureal-none",
            orientation: "v",
            // zIndex: 1,
        },
        itype: {
            alignChilds: "optionRowIcon",
            hoverAnimation: "selectOption",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    buttonGroup: {
        css: null,
        cstate: "normal",
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },

            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            box: null,
            orientation: "h",
            alignItems: "flex-start",
            textAlign: "right",
            // sticky: {
            //     type: "none",
            // },
        },
        itype: {
            alignChilds: "optionRowIcon",
            hoverAnimation: "selectOption",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    header: {
        style: {
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    p: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    button: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 9,
                    bottom: 9,
                    left: 18,
                    right: 18,
                },
                value: "9px 18px 9px 18px",
            },
        },
        input: {
            buttonAlign: "flex-start",
        },
        itype: {
            buttonAlign: "optionRowIcon",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    link: {
        style: {
            margin: {
                type: "single",
                params: 5,
                value: "5px",
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 5,
                    bottom: 5,
                    left: 10,
                    right: 10,
                },
                value: "5px 10px 5px 10px",
            },
        },
        input: {
            buttonAlign: "flex-start",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    richtext: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    htmltext: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    richDialog: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 9,
                    bottom: 9,
                    left: 18,
                    right: 18,
                },
                value: "9px 18px 9px 18px",
            },
        },
        input: {
            buttonAlign: "flex-start",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    img: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        input: {
            imageDescription: "",
            imageSize: {
                type: "full",
                params: 0,
                value: "100%",
            },
            lightbox: true,
            imageHeight: {
                type: "auto",
                value: "auto",
            },
            alignImage: "center",
        },
        itype: {
            imageSize: "imgSize",
            alignImage: "optionRowIcon",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    video: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        input: {
            videoSize: {
                type: "full",
                params: 0,
                value: "100%",
            },
            alignVideo: "center",
        },
        itype: {
            videoSize: "imgSize",
            alignVideo: "optionRowIcon",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    icon: {
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
        },
        input: {
            iconGap: 2,
            alignImage: "center",
        },
        itype: {
            iconGap: "iconGap",
            alignImage: "optionRowIcon",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    shape: {},
    slidingText: {
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: "0px",
            // },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 9,
                    bottom: 9,
                    left: 18,
                    right: 18,
                },
                value: "9px 18px 9px 18px",
            },
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    divider: {
        input: {
            dividerWidth: 100,
            alignment: "center",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    blank: {
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {},
        mobile: {},
        input: {
            height: 20,
        },
        itype: {
            height: "height",
        },
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    list: {
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
    placeholder: {
        css: null,
        cstate: "normal",
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            backgroundAttachment: "scroll",
            backgroundPanning: "pan_no",
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderStyle: "none",
            borderColor: "black",
            borderWidth: {
                type: "single",
                params: 5,
                value: 5,
            },
            boxShadow: {
                type: "none",
                params: {},
                value: "none",
            },
            backgroundFilter: {
                type: "none",
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {},
        classes: {
            q_hide_on_mobile: false,
            q_hide_on_tablet: false,
            q_hide_on_desktop: false,
        },
    },
};

const PRIMARY_RESPONSIVE = combineElements(PRIMARY_COMMON, RESPONSIVE);
export default PRIMARY_RESPONSIVE;
