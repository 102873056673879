import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { nanoid } from "nanoid";
import { elementState, addElement } from "../../db/elementDb";

export const createIt = async (id, pid, data, snapshot) => {
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    if ("child" in data) {
        const cid = nanoid(10);
        const cdata = await snapshot.getPromise(elementState(data.child));
        createIt(cid, id, cdata, snapshot);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        data.childs.map(async (child, index) => {
            const childId = nanoid(10);
            data.childs[index] = childId;
            let childData = await snapshot.getPromise(elementState(child));
            childData = { ...childData, id: childId };
            createIt(childId, id, childData, snapshot);
        });
    } else if ("sections" in data) {
        data.sections.map(async (child, index) => {
            const sectionId = nanoid(10);
            data.sections[index] = sectionId;
            let sectionData = await snapshot.getPromise(elementState(child));
            sectionData = { ...sectionData, id: sectionId };
            createIt(sectionId, id, sectionData, snapshot);
        });
    }
    addElement(id, data);
};
