import React from "react";
import styles from "./styles/MagicButton.module.css"
import classNames from "classnames";

const MagicButton = ({ text, onClick, disabled, loading, style = {} }) => {
	return (
		<button className={classNames(styles.button, { [styles.loading]: loading })}
			style={style}
			disabled={disabled}
			onClick={onClick}
		>
			{text}
		</button>
	);
};

export default MagicButton;