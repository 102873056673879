/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function Video({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    const youtubeVideoID = input.youtubeVideoURL.value
        ? input.youtubeVideoURL.value
        : "rUWxSEwctFU";

    const is_short = input.youtubeVideoURL?.params?.includes("shorts");

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    // width: input.videoSize.value,
                    width: "100%",
                    // maxWidth: "100%",
                    alignSelf: input.alignVideo,
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "0px",
                        // paddingBottom: "56.25%",
                        paddingBottom: is_short ? "177%" : "56.25%",
                    }}
                >
                    <iframe
                        title="HelloVideo"
                        src={`//www.youtube.com/embed/${youtubeVideoID}?autoplay=${
                            input.autoplay ? 1 : 0
                        }&modestbranding=1&fs=1&loop=${
                            input.playInLoop ? 1 : 0
                        }${
                            input.playInLoop
                                ? "&playlist=" + youtubeVideoID
                                : ""
                        }&controls=${input.showControls ? 1 : 0}`}
                        frameborder="0"
                        allowfullscreen
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    ></iframe>
                </div>
            </div>
        </ElementWrapper>
    );
}

export default Video;

// Use this for vast experiance
// https://github.com/mediaelement/mediaelement/
