import React, { Fragment } from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import Editor from "react-simple-code-editor";

function CodeEditor({ value, setValue, lang = "json" }) {
    const highlight = (code) => (
        <Highlight {...defaultProps} theme={theme} code={code} language={lang}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Fragment>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </Fragment>
            )}
        </Highlight>
    );

    return (
        <Editor
            value={value}
            onValueChange={(code) => setValue(code)}
            highlight={highlight}
            padding="10px"
            style={{
                flex: 1,
                boxSizing: "border-box",
                fontFamily: '"Dank Mono", "Fira Code", monospace',
                fontSize: 16,
                lineHeight: 1.5,
                border: "1px solid #ccc5",
                ...theme.plain,
            }}
        />
    );
}

export default CodeEditor;
