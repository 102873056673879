import React, { Fragment, useMemo, useState } from "react";
import { FieldDropdown, ItemDropdown } from "./db";
import log from "cslog";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import {
    ControlledInput,
    booleanOption,
    selectOption,
    textOption,
} from "../../customize/SimpleOptions";

export function OnlyValueField({ label, field, value, setValue }) {
    const input_type = useMemo(() => {
        if (field) {
            if (field?.interface?.type?.startsWith("relationM2M")) {
                return field.interface.type;
            }
            switch (field.value_type) {
                case "boolean":
                    return "boolean";
                case "float":
                    return "number";
                default:
                    return "text";
            }
        }
        return "text";
    }, [field]);

    return (
        <>
            {input_type === "text" && (
                <ControlledInput
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            )}
            {input_type === "number" && (
                <Form.Input
                    type="number"
                    value={value}
                    onChange={(eve) => setValue(parseInt(eve.target.value))}
                />
            )}
            {input_type === "boolean" && (
                <Form.Checkbox
                    label={label}
                    checked={value}
                    onChange={() => setValue(!value)}
                />
            )}
            {input_type === "relationM2M" && (
                <ItemDropdown
                    table={{
                        id: field?.interface?.foreign_key_table,
                        pk: field?.interface?.foreign_key_column,
                        display_template: field?.interface?.template
                            ? `{{${extractFieldFromTemplate(
                                  field?.interface?.template
                              )}}}`
                            : "{{name}}",
                    }}
                    label={false}
                    value={value?.value}
                    // setValue={(newVal) =>
                    //     setValue({ value: newVal, label: newVal })
                    // }
                    setFullValue={(newVal) =>
                        setValue({
                            value: newVal.value,
                            label: newVal.text,
                        })
                    }
                />
            )}
        </>
    );
}

export function ValueField({ label, table, page, filter, field, setValue }) {
    const value = filter.value || {};

    log.d(field, "Now Field");

    const input_type = useMemo(() => {
        if (field) {
            if (field?.interface?.type?.startsWith("relationM2M")) {
                return field.interface.type;
            }
            switch (field.value_type) {
                case "boolean":
                    return "boolean";
                case "float":
                    return "number";
                default:
                    return "text";
            }
        }
        return "text";
    }, [field]);

    if (["_null", "_nnull", "_empty", "_nempty"].includes(filter.op)) {
        return <></>;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                backgroundColor: "#eff5f7",
                // padding: "0px 10px",
                marginBottom: "10px",
            }}
        >
            {selectOption(
                false,
                value?.type,
                (newVal) =>
                    setValue({
                        ...value,
                        type: newVal,
                        value: null,
                    }),
                [
                    ["Fixed Value", "fixed"],
                    ["From URL Path", "url_path"],
                    ["From URL Param", "url_param"],
                    ["From Input", "input"],
                ]
            )}
            {value.type === "fixed" && (
                <Fragment>
                    {input_type === "text" &&
                        textOption(
                            false,
                            value?.value,
                            (newVal) => setValue({ ...value, value: newVal }),
                            "text",
                            "Fixed Value"
                        )}
                    {input_type === "boolean" &&
                        booleanOption("Toggle", value.value, (newVal) =>
                            setValue({ ...value, value: newVal })
                        )}
                    {input_type === "relationM2M" && (
                        <ItemDropdown
                            table={{
                                id: field?.interface?.foreign_key_table,
                                pk: field?.interface?.foreign_key_column,
                                display_template: field?.interface?.template
                                    ? `{{${extractFieldFromTemplate(
                                          field?.interface?.template
                                      )}}}`
                                    : "{{name}}",
                            }}
                            label={false}
                            value={value?.value}
                            setValue={(newVal) =>
                                setValue({ ...value, value: newVal })
                            }
                        />
                    )}
                </Fragment>
            )}
            {value.type === "url_path" && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {selectOption(
                        // "URL Path Name",
                        false,
                        value.path,
                        (newVal) =>
                            setValue({
                                ...value,
                                path: newVal,
                            }),
                        page?.paths?.length > 0
                            ? page.paths.map((path) => {
                                  return [path, path];
                              })
                            : [],
                        "URL Path Name"
                    )}
                    <div
                        style={{
                            marginTop: "-10px",
                        }}
                    >
                        {textOption(
                            false,
                            value.placeholder,
                            (newVal) =>
                                setValue({ ...value, placeholder: newVal }),
                            "text",
                            "Placeholder"
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

const OPERATOR_OPTIONS = [
    ["Equal", "_eq"],
    ["Not Equal", "_neq"],
    ["Is null", "_null"],
    ["Isn't null", "_nnull"],
    ["Starts With", "_starts_with"],
    ["Contains", "_contains"],
    ["Is empty", "_empty"],
    ["Isn't empty", "_nempty"],
    ["Less than", "_lt"],
    ["Greater than", "_gt"],
].map(([text, value, defVal]) => {
    return {
        key: value,
        text: text,
        value: value,
    };
});

const OP_DEFAULT_VALUES = {
    _null: true,
    _nnull: true,
    _empty: true,
    _nempty: true,
};

export function OperatorField({
    label,
    value,
    setValue,
    field,
    filter,
    setFilter,
}) {
    return (
        <Form.Field
        // width={2}
        >
            {label && <label>{label}</label>}
            <Dropdown
                placeholder="Operator"
                selection
                // inline
                // compact
                options={OPERATOR_OPTIONS}
                value={value}
                onChange={(e, { value }) => {
                    // setValue(value);
                    setFilter({
                        ...filter,
                        op: value,
                        value: {
                            type: "fixed",
                            value: OP_DEFAULT_VALUES[value] || "",
                        },
                    });
                }}
            />
        </Form.Field>
    );
}

function Filter({
    filter,
    setFilter,
    addFilter,
    removeFilter,
    allowDelete,
    allowAdd,
    table,
    page,
}) {
    // const [filter, setFilter] = useState({});
    log.d(table, "Table in Filter");
    log.d(filter, "Filter");

    const selected_field = useMemo(() => {
        return table?.fields.find((f) => f.name === filter.field);
    }, [table, filter.field]);

    log.d(selected_field, "Selected field iin filtterer");
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "flex-start",
            }}
        >
            <FieldDropdown
                label={false}
                table={table}
                value={filter.field}
                setValue={(newVal) =>
                    setFilter({
                        ...filter,
                        field: newVal,
                    })
                }
                filter={filter}
                setFilter={setFilter}
            />
            <OperatorField
                label={false}
                value={filter.op}
                setValue={(newVal) =>
                    setFilter({
                        ...filter,
                        op: newVal,
                    })
                }
                filter={filter}
                setFilter={setFilter}
            />
            <ValueField
                label={false}
                filter={filter}
                value={filter.value}
                setValue={(newVal) => setFilter({ ...filter, value: newVal })}
                table={table}
                page={page}
                field={selected_field}
            />
            {allowDelete && (
                <Button icon basic color="red" onClick={removeFilter}>
                    <Icon name="times" />
                </Button>
            )}
            {allowAdd && (
                <Button icon basic color="blue" onClick={addFilter}>
                    <Icon name="add" />
                </Button>
            )}
        </div>
    );
}

export default Filter;

function extractFieldFromTemplate(str) {
    if (str) {
        const startIndex = str.indexOf(".") + 1;
        const endIndex = str.indexOf("}}");

        if (startIndex === -1 || endIndex === -1) {
            return "";
        }

        return str.substring(startIndex, endIndex);
    }
    return str;
}
