import React from "react";
import { useEditor } from "slate-react";
import { Button, Icon } from "../components";
import { insertLink } from "../utils/link";

const InsertImageBtn = () => {
    const editor = useEditor();
    return (
        <Button
            onMouseDown={(event) => {
                event.preventDefault();
                const url = window.prompt("Enter the URL:");
                if (!url) return;
                insertLink(editor, url);
            }}
        >
            <Icon>{"fas fa-link"}</Icon>
        </Button>
    );
};

export default InsertImageBtn;
