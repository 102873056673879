import log from "cslog";
import { useCallback, useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";

// USE THIS
// https://github.com/igordanchenko/react-photo-album
function GalleryView({
    data,
    makeThumb,
    makeImgObj,
    selectedImage,
    setSelectedImage,
    multiple = false,
}) {
    const [items, setItems] = useState(null);
    // const [selectedId, setSelectedId] = useState(null);

    const selectedPhotos = multiple
        ? Array.isArray(selectedImage)
            ? selectedImage
            : [selectedImage]
        : selectedImage;

    const onPhotoClick = (photo) => {
        if (multiple) {
            const removeIndex = selectedPhotos.findIndex(
                (item) => item.id === photo.id
            );

            if (removeIndex !== -1) {
                const newSImgs = Array.from(selectedPhotos);
                newSImgs.splice(removeIndex, 1);
                setSelectedImage(newSImgs);
            } else {
                const imgObj = makeImgObj(photo);
                setSelectedImage([...selectedPhotos, imgObj]);
            }
        } else {
            setSelectedImage(makeImgObj(photo));
        }
    };

    useEffect(() => {
        if (!items) {
            const out = [];
            data.forEach((row, index) => {
                const one = makeThumb(row, index);
                if (one.width && one.height) {
                    out.push(one);
                }
            });
            setItems(out);
        }
    }, [data, items]);

    useEffect(() => {
        const out = [];
        data.forEach((row, index) => {
            const one = makeThumb(row, index);
            if (one.width && one.height) {
                out.push(one);
            }
        });
        setItems(out);
    }, [data]);

    const imgStyle = {
        outline: "1px solid #ddd",
        transition:
            "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
    };
    const selectedImgStyle = {
        outline: "5px dashed royalblue",
        transform: "translateZ(0px) scale3d(0.97, 0.97, 1)",
        transition:
            "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
    };

    const renderPhoto = useCallback(
        ({ imageProps: { alt, style, ...rest }, photo }) => {
            // const isSelected = selectedImage?.id === photo.id;
            let isSelected = false;
            if (multiple) {
                isSelected =
                    selectedPhotos.findIndex((item) => item.id === photo.id) !==
                    -1;
            } else {
                isSelected = selectedPhotos?.id === photo.id;
            }
            return (
                <img
                    alt={alt}
                    style={{
                        ...style,
                        // borderRadius: 5 > 2 ? "4px" : 0,
                        // maxWidth: "50%",
                        // background: "yellow",
                        boxShadow:
                            5 > 0
                                ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                                : "none",

                        ...(isSelected
                            ? { ...imgStyle, ...selectedImgStyle }
                            : { ...imgStyle }),
                    }}
                    {...rest}
                    className="checkerboard"
                />
            );
        },
        [selectedPhotos]
    );

    log.d(data, "Raw data in GV");
    log.d(items, "Photos in GV");

    return (
        <div
            style={{
                padding: "2px",
                paddingRight: "10px",
            }}
        >
            <PhotoAlbum
                layout="rows"
                photos={items}
                // columns={columns}
                spacing={15}
                padding={0}
                targetRowHeight={120}
                renderPhoto={renderPhoto}
                onClick={(e, photo, index) => {
                    const selectedPhoto = data[photo.dindex];
                    onPhotoClick(selectedPhoto);
                }}
            />
        </div>
    );
}

export default GalleryView;
