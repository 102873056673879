import { useRecoilCallback, useRecoilValue } from "recoil";
import { elementState, selectedElementState } from "../../../db/elementDb";
import { useEffect, useState } from "react";
import { encodeComponent, encodeComponent2 } from "./encoder";

async function analyseOne(id, data, snapshot, out = {}) {
    if ("childs" in data) {
        const temp = [];
        await Promise.all(
            data.childs.map(async (child, index) => {
                const child_data = await snapshot.getPromise(
                    elementState(child)
                );
                temp.push(await analyseOne(child, child_data, snapshot));
            })
        );
        out[data.type] = temp;
        return out;
    }
    out[data.type] = id;
    return out;
}

export async function analyseComponent(component_id, snapshot) {
    const data = await snapshot.getPromise(elementState(component_id));
    if (data.type === "section") {
        return await analyseOne(component_id, data, snapshot);
    }
    return {};
}

export function useAnalyseCurrentComponent() {
    const current_component = useRecoilValue(selectedElementState);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [data, setData] = useState({});

    const startComponentAnalysis = useRecoilCallback(
        ({ snapshot }) =>
            async (id) => {
                const response = await analyseComponent(id, snapshot);
                return response;
            }
    );

    useEffect(() => {
        const fetchData = async () => {
            const response = await startComponentAnalysis(current_component);
            const coded = encodeComponent(response);
            setData({
                coded: coded,
                data: response,
            });
            setLoading(false);
            setSuccess(true);
        };

        setLoading(true);
        setSuccess(false);
        fetchData();
    }, [current_component]);

    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        data: data,
    };
}
