import React, { Fragment, useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { Button, Icon } from "../components";
import { getBlock, isBlockActive, toggleBlock } from "../utils/block";
import log from "cslog";

const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
            )}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

export default BlockButton;

export const FontButton = ({ format, icon }) => {
    const editor = useSlate();
    const { selection } = editor;
    const [fs, setFs] = useState(null);

    useEffect(() => {
        log.p("selecion changed");
        setFs(getBlock(editor, format, "fontSize") || 16);
    }, [selection]);

    return (
        <Fragment>
            <Button
                onMouseDown={(event) => {
                    event.preventDefault();
                    const cfs = getBlock(editor, format, "fontSize") || 16;
                    toggleBlock(editor, format, cfs + 1);
                    setFs(cfs + 1);
                }}
            >
                <Icon>{"fas fa-plus"}</Icon>
            </Button>
            <span>{fs}</span>
            <Button
                onMouseDown={(event) => {
                    event.preventDefault();
                    const cfs = getBlock(editor, format, "fontSize") || 16;
                    toggleBlock(editor, format, cfs - 1);
                    setFs(cfs - 1);
                }}
            >
                <Icon>{"fas fa-minus"}</Icon>
            </Button>
        </Fragment>
    );
};
