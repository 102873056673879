import React from "react";
import { Button } from "semantic-ui-react";
import { useSmartCrop } from "../actions/aiflask";
import log from "cslog";
import { getAssetURL } from "../_helper/image";
import MagicButton from "../commons/MagicButton";

export function SmartCropper({ fileobj, onSuccess }) {
	const mutation = useSmartCrop((newPhoto) => {
		log.d(newPhoto, "New Photo smart cropped");
		if (newPhoto?.data?.success) {
			const photo = newPhoto?.data?.file;
			onSuccess({
				srcType: "lib",
				id: photo.id,
				width: photo.width,
				height: photo.height,
				title: photo.title,
				filename_disk: photo.filename_disk,
				filename_download: photo.filename_download,
				storage: photo.storage,
				type: photo.type,
				filesize: photo.filesize,
				full: getAssetURL(photo),
			});
		}
	});

	log.d(fileobj, "File Obj");


	return <MagicButton
		text="Smart Crop"
		style={{
			marginTop: "10px",
		}}
		onClick={() => {
			mutation.mutate({
				filename: fileobj?.filename_disk,
			});
		}}
		disabled={!fileobj?.filename_download}
		loading={mutation.isLoading}
	/>

	return (
		<Button
			onClick={() => {
				mutation.mutate({
					filename: fileobj?.filename_disk,
				});
			}}
			disabled={!fileobj?.filename_download}
			loading={mutation.isLoading}
		>
			Smart Crop
		</Button>
	);
}
