import log from 'cslog'
import { useAIRewrite, useAIWrite } from '../../../actions/ai'
import { useEffect, useState } from 'react'
import { useExtractContent } from './content_extractor'

export const useWriterAction = (content, setContent, onStart, onEnd) => {
  const [isLoading, setLoading] = useState(false)
  const writeMutation = useAIWrite(res => {
    log.d(res, 'AI Write Response')
    if (res.success) {
      const result = res.data
      setContent(result)
    }
    onEnd()
  })
  const rewriteMutation = useAIRewrite(res => {
    if (res?.success) {
      setContent(res?.data)
    } else {
      log.d(res, 'Rewrite Response error')
    }
    onEnd()
  })

  useEffect(() => {
    setLoading(writeMutation.isLoading || rewriteMutation.isLoading)
  }, [writeMutation.isLoading, rewriteMutation.isLoading])

  const execute = action => {
    onStart()
    switch (action.subtype) {
      case 'write':
        writeMutation.mutate({
          type: 'heading',
          description: action?.params?.prompt,
          tone: null
        })
        break
      case 'rewrite':
        // rewriteMutation.mutate({
        //     text: content,
        //     tone: "expand",
        // });
        alert(`Taking write action: ${JSON.stringify(action)}`)
        break
      case 'rewrite_tone':
        rewriteMutation.mutate({
          text: content,
          tone: action?.params?.tone
        })
        break
      default:
        alert(`Taking write default action: ${JSON.stringify(action)}`)
        break
    }
  }

  return {
    execute,
    isLoading
  }
}

export const useActions = (
  data,
  setData,
  onStart = () => {},
  onEnd = () => {}
) => {
  const content = data?.input?.content
  const setContent = newContent =>
    setData({ ...data, input: { ...data.input, content: newContent } })

  const writerAction = useWriterAction(content, setContent, onStart, onEnd)

  const contentExtractorAction = useExtractContent()

  const execute = async action => {
    switch (action.type) {
      case 'write':
        log.p('Case is writer')
        writerAction.execute(action)
        break
      case 'change_all_content':
        log.p('Changing all content')
        log.d(action, 'Action')
        const prompt = action.params?.prompt
        await contentExtractorAction.apply_ai(prompt)
        break
      case 'auto_label':
        log.p('Auto labeling')
        await contentExtractorAction.auto_label()
        break
      default:
        break
    }
  }

  return {
    execute,
    isLoading: writerAction.isLoading || contentExtractorAction.isLoading
  }
}
