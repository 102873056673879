import React, { useEffect, useRef, useState } from 'react'
import { atomFamily, useRecoilCallback } from 'recoil'
import { elementState } from '../../../db/elementDb'
import log from 'cslog'
import { useAIJSONRewriter, useAILabeler } from '../../../actions/ai'
import produce from 'immer'

async function extractContent (id, snapshot, out, y = 0) {
  const data = await snapshot.getPromise(elementState(id))
  log.d(data, `eC ${id}`)
  if ('childs' in data) {
    if (Array.isArray(data.childs)) {
      await Promise.all(
        data.childs.map(async (child, index) => {
          await extractContent(child, snapshot, out)
        })
      )
    } else {
      await Promise.all(
        Object.keys(data.childs).map(async (child, index) => {
          const translate = data.childs[child]?.translate
          await extractContent(child, snapshot, out, translate?.[1])
        })
      )
    }
    // out[id] = data.type;
    return
  }
  // out[id] = data.type;
  if (['header', 'p', 'text'].includes(data.type)) {
    // out[id] = data?.input?.content;
    out[id] = {
      y: y,
      content: data?.input?.content,
      label: data?.ai?.label,
      length: data?.input?.content?.length,
      type: data?.type
    }
  }
  return
}

export function useExtractContent () {
  const [isLoading, setLoading] = useState(false)
  //   let input = {}
  const inputRef = useRef({})

  const mutation = useAIJSONRewriter(res => {
    log.d(res, 'Success response')
    let new_content = res?.data?.output
    if ('content' in Object.keys(res.data)) {
      new_content = res?.data?.content
    }
    fillNewContent(new_content)
  })
  const autoLabelMutation = useAILabeler(res => {
    log.d(res, 'Auto label response')
    log.d(inputRef.current, 'input after response in autolabel')
    let new_content = res?.data?.output
    if ('content' in Object.keys(res.data)) {
      new_content = res?.data?.content
    }
    //fill labels
    fillLabels(new_content)
  })

  const extractContentSorted = async (id, snapshot) => {
    const out = {}
    await extractContent(id, snapshot, out)
    const sorted_out = Object.keys(out).map(key => ({
      key: key,
      ...out[key]
    }))
    sorted_out.sort((a, b) => {
      if (a.y < b.y) return -1
      if (a.y > b.y) return 1
      return 0
    })
    return sorted_out
  }

  const doFill = useRecoilCallback(
    ({ snapshot, set }) =>
      async (key, new_content) => {
        log.d(new_content, `Filling ${key}`)
        const data = await snapshot.getPromise(elementState(key))
        const new_data = produce(data, draft => {
          draft['input']['content'] = new_content
        })
        await set(elementState(key), new_data)
      }
  )

  const doFillLabel = useRecoilCallback(
    ({ snapshot, set }) =>
      async (key, new_content) => {
        log.d(new_content, `Filling ${key}`)
        const data = await snapshot.getPromise(elementState(key))
        const new_data = produce(data, draft => {
          //   draft['ai']['label'] = new_content
          if (!draft.ai) {
            draft.ai = {}
          }
          draft.ai.label = new_content
        })
        log.d(new_data, 'new_data now')
        await set(elementState(key), new_data)
      }
  )

  function fillLabels (new_content) {
    log.d(new_content, 'New content for labels')
    log.d(inputRef.current.keys, 'keys to put')
    inputRef.current.keys.forEach((key, index) => {
      doFillLabel(key, new_content[index]?.label)
    })
  }

  function fillNewContent (new_content) {
    log.d(new_content, 'New content for content')
    log.d(inputRef.current.keys, 'keys to put')
    inputRef.current.keys.forEach((key, index) => {
      doFill(key, new_content[index]?.text)
    })
  }

  //   const extract = useRecoilCallback(({ snapshot }) => async id => {
  const extract = useRecoilCallback(
    ({ snapshot }) =>
      async (id, enable_content = false) => {
        const out = {}
        await extractContent(id, snapshot, out)
        // const sorted_out = Object.values(out);
        const sorted_out = Object.keys(out).map(key => ({
          key: key,
          ...out[key]
        }))
        sorted_out.sort((a, b) => {
          if (a.y < b.y) return -1
          if (a.y > b.y) return 1
          return 0
        })
        log.d(sorted_out, 'Sorted out')

        const out_keys = []
        const out_content = []
        sorted_out.forEach(el => {
          out_keys.push(el.key)
          out_content.push({
            // text: el.content,
            // text: '',
            text: enable_content ? el.content : '',
            ...(el.label ? { label: el.label } : {}),
            length: el.length,
            type: el.type
          })
        })
        // log.d(out_content, "OUT content");
        return {
          keys: out_keys,
          content: out_content
        }
      }
  )

  const extractTextOnly = useRecoilCallback(({ snapshot }) => async id => {
    const sorted_out = await extractContentSorted(id, snapshot)

    const out_keys = []
    const out_content = []
    sorted_out.forEach(el => {
      out_keys.push(el.key)
      out_content.push({
        text: el.content,
        ...(el.label ? { label: el.label } : {}),
        length: el.length,
        type: el.type
      })
    })
    return {
      keys: out_keys,
      content: out_content
    }
  })

  const auto_label = async () => {
    const out_input = await extract('frontSide', true)
    // input = out_input
    inputRef.current = out_input
    autoLabelMutation.mutate({
      creation_type: 'business card',
      content: out_input.content
    })
    log.d(out_input, 'Out input')
    log.d(inputRef.current, 'input')
  }

  const apply_ai = async prompt => {
    const out_input = await extract('frontSide')
    // input = out_input
    inputRef.current = out_input
    mutation.mutate({
      prompt: prompt,
      content: out_input.content
    })
    log.d(out_input, 'Out input')
  }

  useEffect(() => {
    setLoading(mutation.isLoading || autoLabelMutation.isLoading)
  }, [mutation.isLoading, autoLabelMutation.isLoading])

  return { extract, apply_ai, auto_label, isLoading }
}
