/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

// Create a function that gives css for perticular element type
// and then create a css file with that css
function RichDialog({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    let text = "click";
    const btnData = input.buttonType;
    if (!btnData) {
        text = input.content;
    }

    const icon = btnData?.type !== "text" && (
        <i
            className={btnData.params.icon.value}
            style={{
                zoom: 0.9,
                marginRight:
                    btnData?.params?.text && !btnData?.params?.icon_right
                        ? "1rem"
                        : "auto",
                marginLeft:
                    btnData?.params?.text && btnData?.params?.icon_right
                        ? "1rem"
                        : "auto",
            }}
        ></i>
    );

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
            >
                <button
                    className={input.hoverAnimation}
                    style={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        alignSelf: "stretch",
                        textTransform: btnData?.params?.uppercase
                            ? "uppercase"
                            : "none",
                        gap: "1.5rem",
                        fontWeight: "bold",
                        fontSize: input.size || "12px",
                        cursor: "pointer",
                        margin: "10px",
                    }}
                >
                    {btnData?.params?.icon &&
                        !btnData.params.icon_right &&
                        icon}
                    {btnData?.params?.text && btnData.params.text}
                    {btnData?.params?.icon && btnData.params.icon_right && icon}
                </button>
            </div>
        </ElementWrapper>
    );
}

export default RichDialog;
