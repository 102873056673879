import log from "cslog";

export function encodeComponent(data) {
    log.d(data, "Now data");
    if (Array.isArray(data)) {
        let temp = data.reduce((result, item) => {
            const item_result = encodeComponent(item);
            log.d(item_result, "item_result");
            return `${result},${item_result}`;
        }, "");
        log.d(temp, "temp in encoder");
        return temp;
    } else if (data instanceof Object) {
        const key = Object.keys(data)?.[0];
        const val = data[key];
        const result_val = encodeComponent(val);
        if (result_val) {
            return `${key}>${result_val}`;
        }
        return key;
    }
    // return data;
    return null;
}

export function encodeComponent2(id, data, out = "") {
    const val = data[id];

    if (Array.isArray(val)) {
        const temp = val.reduce((result, item) => {
            const key = Object.keys(item)?.[0];
            const item_res = encodeComponent2(key, item, out);
            log.d(key, "key");
            log.d(item_res, "item res");
            return `${result}_${item_res}`;
        }, "");
        log.d(temp, "temp in encoder");
        out = `${out}#${id}=>${temp}`;
    } else {
        out = `${out}${id}`;
    }
    return out;
}
