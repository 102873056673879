import { useQuery } from "react-query";
import { secureAxios } from "../_helper/auth";

export const useMe = (enabled) => {
    return useQuery(
        "me",
        () => secureAxios.get("users/me").then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};
