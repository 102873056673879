import CHARTS_COMMON from "../common/charts";
import { combineElements } from "../../../parser/allparser";

const RESPONSIVE = {
    pie: {
        style: {},
        input: {},
    },
    lineChart: {
        style: {},
        input: {},
    },
    barChart: {
        style: {},
        input: {},
    },
};

const CHARTS_RESPONSIVE = combineElements(CHARTS_COMMON, RESPONSIVE);

export default CHARTS_RESPONSIVE;
