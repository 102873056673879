import React from "react";
import log from "cslog";
import {
    createItAbsolute,
    cultivateData,
    cultivateDataUniquely,
} from "../../data/extractor";
import { bookPageArrayState, currentBookPageState } from "../../db/bookConfig";
import { dataPageState } from "../../db/dataDb";
import { elementState } from "../../db/elementDb";

export const decodeMagazinePage = async (pageData, snapshot, set) => {
    log.p("Decoding template");
    const currentPage = await snapshot.getPromise(currentBookPageState);

    log.d(currentPage, "Current Page");
    log.d(
        await snapshot.getPromise(elementState(currentPage.pid)),
        "current page data"
    );

    const cid = currentPage.pid;
    const page_id = pageData.pid;
    const html = pageData.html;

    log.d(html, "Page html");

    // await set(elementState(cid), html[page_id]);

    // await cultivateData(cid, null, html[page_id], html);

    //--------------------
    const new_page_data = {
        ...html[page_id],
    };
    const new_data = await createItAbsolute(
        cid,
        null,
        new_page_data,
        html,
        true
    );
    await set(elementState(new_data[0]), new_data[1]);
};

export const decodeMagazineData = async (data, set) => {
    log.info("Now decoding pages");
    // const bookPageArrayOriginal = JSON.parse(bookData.page_array);
    const bookPageArrayOriginal = data.data.page_array;

    const pagelist = [];
    // const pages = JSON.parse(bookData.pages);
    const pages = data.data.pages;
    log.d(pages, "PAGES");
    await Promise.all(
        pages.map(async (page) => {
            const fi = bookPageArrayOriginal.findIndex(
                (item) => item.pid === page.pid
            );
            const fiItem = bookPageArrayOriginal[fi];
            let pname = fiItem.name ? fiItem.name : "Edit Name";
            // if (fi < 3) {
            //     switch (fi) {
            //         case 0:
            //             pname = "Global Page";
            //             break;
            //         case 1:
            //             pname = "Cover Page";
            //             break;
            //         case 2:
            //             pname = "Back Cover";
            //             break;
            //         default:
            //             break;
            //     }
            // }
            bookPageArrayOriginal[fi] = {
                ...bookPageArrayOriginal[fi],
                id: page.id,
                name: pname,
            };

            // Dynamic data
            const pageContent = page.content || {};
            await set(dataPageState(page.pid), pageContent);

            const pageHtml = page.html;
            log.d(pageHtml, "PageHTML");
            await cultivateData(page.pid, null, pageHtml[page.pid], pageHtml);
        })
    );

    await set(bookPageArrayState, bookPageArrayOriginal);
};
