function ElementWrapper({ children }) {
    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                // overflow: "hidden",
                // clipDepth: 3,
                // clipParent: ".grid_element_wrapper",
                // border: "2px dotted blue",
            }}
        >
            {children}
        </div>
    );
}

export default ElementWrapper;
