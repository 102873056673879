import { useRecoilState } from "recoil";
import { exportHomeState } from "../../db/screenshot";
import log from "cslog";
import { toJpeg, toPng } from "html-to-image";
import { createRef, useEffect } from "react";

export function useExport(title, dims) {
	const boxRef = createRef(null);
	const [exhome, setExHome] = useRecoilState(exportHomeState);

	useEffect(() => {
		if (exhome?.download) {
			log.warn("Taking Screenshot");

			const export_settings = exhome.export_settings;
			let iFunc = toPng;
			if (export_settings.format === "JPG") {
				iFunc = toJpeg;
			}

			const filename = `${title}.${export_settings.format === "PNG"
				? "png"
				: export_settings.format === "JPEG"
					? "jpeg"
					: ""
				}`;

			// const w = 500;
			// const h = (w / dims.width) * dims.height;
			const w = dims.width;
			const h = dims.height;

			const scale = export_settings.scale;
			const quality = export_settings === "JPEG" ? export_settings.quality * 0.01 : 1.0

			iFunc(boxRef.current, {
				cacheBust: true,
				quality: quality,
				canvasWidth: w * scale,
				canvasHeight: parseInt(h) * scale,
			})
				.then((dataUrl) => {
					log.warn("Screenshot taken");
					const link = document.createElement("a");
					link.download = filename;
					link.href = dataUrl;
					link.click();
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setExHome({ ...exhome, download: false });
				}
				);
		}
	}, [exhome.open, exhome?.download])

	return { boxRef, exhome }
}