import React from "react";
import AutoFit from "./AutoFit";
import AutoFitDocument from "./AutoFitDocument";
import styles from "./styles/AutoFitDocument.module.css";
import { useExport } from "../junctionAbs";


function DocumentDevice({ children, layout, creation_id, vi, ctx, is_preview, page_content }) {
	const page_dims = layout?.pageSize?.value;
	const { boxRef } = useExport("document_demo", page_dims);


	const protected_access = {};



	return <AutoFitDocument
		layout={layout}
		page_content={page_content}
		is_preview={is_preview}
		gap={is_preview ? 0 : 2}
		protected_access={protected_access}
		creation_id={creation_id}
		vi={vi}
		ctx={ctx}
	>


		<div
			ref={boxRef}
			style={{
				width: page_dims.width,
				// height: page_dims.height,

				minHeight: page_dims.height,
				height: "auto",
				overflow: "hidden",
				// margin: "15px"
			}}>
			{children}
		</div>

	</AutoFitDocument>
}

export default DocumentDevice;