import React from "react";
import { useEditor } from "slate-react";
import { Button, Icon } from "../components";
import { insertImage } from "../utils/image";
import { insertParagraph } from "../utils/paragraph";
import ImagePanel from "../../ImagePanel";

const InsertImageBtn = () => {
    const editor = useEditor();

    return (
        <ImagePanel
            button={
                <Button>
                    <Icon>fas fa-image</Icon>
                </Button>
            }
            onPhoto={(photo) => {
                // setImage(photo.full);
                insertImage(editor, photo.full);
                insertParagraph(editor, "");
            }}
        />
    );
    // return (
    //     <Button
    //         onMouseDown={(event) => {
    //             event.preventDefault();
    //             const url = window.prompt("Enter the URL of the image:");
    //             if (!url) return;
    //             insertImage(editor, url);
    //         }}
    //     >
    //         <Icon>fas fa-image</Icon>
    //     </Button>
    // );
};

// const InsertImageBtn = ({ triggerPrompt, proceed }) => {
//     const editor = useEditor();

//     proceed = (url) => {
//         if (!url) return;
//         insertImage(editor, url);
//     };
//     return (
//         <Button
//             onMouseDown={(event) => {
//                 event.preventDefault();
//                 // const url = window.prompt("Enter the URL of the image:");
//                 // if (!url) return;
//                 // insertImage(editor, url);
//                 triggerPrompt();
//             }}
//         >
//             <Icon>image</Icon>
//         </Button>
//     );
// };

export default InsertImageBtn;
