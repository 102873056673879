import React, { useEffect, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import log from "cslog";
import styles from "./styles/ImageUploader.module.css";
import { formatBytes } from "../utils";
import imageCompression from "browser-image-compression";
import produce from "immer";

const COMPRESSION_OPTIONS = {
    maxSizeMB: 1,
    maxWidthOrHeight: 600,
    useWebWorker: true,
};

function ImageUploader({ open, setOpen, photos, uploadPhotos }) {
    const [files, setFiles] = useState([]);
    const [result, setResult] = useState([]);
    log.d(photos, "Files To Upload");
    log.d(result, "Result");

    useEffect(() => {
        setFiles(Array.from(photos));
        setResult(Array.from(photos));
    }, [photos]);
    return (
        <Modal
            size="large"
            open={open}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
        >
            <Modal.Content style={{ maxHeight: "80vh", overflowY: "auto" }}>
                <h4>Prepare images to upload</h4>
                {files?.length > 0 && (
                    <div className={styles.list}>
                        {files.map((photo, index) =>
                            photo ? (
                                <PreviewImage
                                    file={photo}
                                    out={result[index]}
                                    setOut={(new_out) =>
                                        setResult((old_result) =>
                                            produce(old_result, (draft) => {
                                                draft[index] = new_out;
                                            })
                                        )
                                    }
                                    removeFile={() => {
                                        setFiles(
                                            produce(files, (draft) => {
                                                draft.splice(index, 1);
                                            })
                                        );
                                        setResult(
                                            produce(result, (draft) => {
                                                draft.splice(index, 1);
                                            })
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )
                        )}
                    </div>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    primary
                    icon
                    labelPosition="right"
                    onClick={() => {
                        setOpen(false);
                        uploadPhotos(result);
                    }}
                    disabled={result?.length <= 0}
                >
                    Upload All
                    <Icon name="arrow up" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ImageUploader;

function PreviewImage({ file, out, setOut, removeFile }) {
    const [info, setInfo] = useState({});
    const [src, setSrc] = useState(null);
    const [compressed, setCompressed] = useState(null);
    const [loading, setLoading] = useState(null); //null means no, text = loading text

    useEffect(() => {
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setSrc(reader.result);
            // setOut(reader.result);
            setOut(file);
            setInfo({
                ...info,
                size: file.size,
            });
        };

        const compressImage = async () => {
            try {
                const compressedFile = await imageCompression(
                    file,
                    COMPRESSION_OPTIONS
                );

                // setCompressed(URL.createObjectURL(compressedFile));
                setCompressed(compressedFile);
                // setOut(URL.createObjectURL(compressedFile));
                setOut(compressedFile);
                setInfo({
                    ...info,
                    original_size: file.size,
                    compressed_size: compressedFile.size,
                    is_compressed: true,
                });
            } catch (error) {
                console.log(error);
            }
            setLoading(null);
        };

        setLoading("Compressing...");
        compressImage();
    }, [file]);

    return (
        <div className={styles.item}>
            {/* <img src={src} className={styles.preview_og} alt="preview" /> */}
            <img
                // src={out}
                src={URL.createObjectURL(out)}
                className={styles.preview_og}
                alt="preview compressed"
            />
            <div className={styles.right_box}>
                <div className={styles.info_wrapper}>
                    {info.original_size && (
                        <p>Original Size: {formatBytes(info.original_size)}</p>
                    )}
                    {info.is_compressed && info.compressed_size && (
                        <p className={styles.good}>
                            Compressed Size: {formatBytes(info.compressed_size)}
                        </p>
                    )}
                    {loading && <p>{loading}</p>}
                </div>
                <div className={styles.actions_wrapper}>
                    {/* <Button icon>
                        <Icon name="crop" />
                    </Button> */}
                    <Button
                        onClick={() => {
                            if (info.is_compressed) {
                                // setOut(src);
                                setOut(file);
                                setInfo({
                                    ...info,
                                    is_compressed: false,
                                });
                            } else {
                                setOut(compressed);
                                setInfo({
                                    ...info,
                                    is_compressed: true,
                                });
                            }
                        }}
                    >
                        {info.is_compressed ? "Uncompress" : "Compress"}
                    </Button>
                    {/* <Button>Edit</Button> */}
                    <Button icon onClick={removeFile}>
                        <Icon name="times" />
                    </Button>
                </div>
            </div>
        </div>
    );
}
