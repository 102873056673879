/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import isHotkey from "is-hotkey";
import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import Leaf from "./Leaf";
import withImages from "./plugins/withImages";
import withLinks from "./plugins/withLinks";
import SlateElement from "./SlateElement";
import { SlateToolbarAttached } from "./SlateToolbar";
import "./styles/slate_design.css";
import styles from "./styles/SlateEditor.module.css";
import { toggleMark } from "./utils/mark";
import { withResetStylingOnEnter } from './plugins/withResetStyling'

const HOTKEYS = {
	"mod+b": "bold",
	"mod+i": "italic",
	"mod+u": "underline",
	"mod+`": "code",
	"mod+s": "bold",
};

const withQ = editor => {
	const {
		insertData,
		insertText,
		isInline,
		isElementReadOnly,
		isSelectable,
		isVoid
	} = editor

	editor.isInline = element =>
		['q_var'].includes(element.type) || isInline(element)

	editor.isElementReadOnly = element =>
		element.type === 'q_var' || isElementReadOnly(element)

	editor.isVoid = element => {
		return element.type === 'q_var' ? true : isVoid(element)
	}

	editor.isSelectable = element =>
		element.type !== 'q_var' && isSelectable(element)

	return editor
}

const SlateEditor = ({ data, setData }) => {
	// const [value, setValue] = useState(initialValue);
	// const editor = useMemo(
	// 	() => withLinks(withImages(withHistory(withReact(createEditor())))),
	// 	[]
	// );
	const editor = useMemo(
		() =>
			withQ(
				withLinks(
					withResetStylingOnEnter(
						withImages(withHistory(withReact(createEditor())))
					)
				)
			),
		[]
	)

	const renderElement = useCallback(
		(props) => <SlateElement {...props} />,
		[]
	);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	return (
		<div
			className={styles.wrapper}
			css={css`
                .qslate p,
                .qslate li,
                .qslate blockquote {
                    font-size: ${16}px;
                }
            `}
		>
			<Slate
				editor={editor}
				// value={value}
				// onChange={(value) => setValue(value)}
				value={data}
				onChange={(value) => setData(value)}
			>
				<SlateToolbarAttached />
				<Editable
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder='Click here to write...'

					spellCheck
					autoFocus
					onKeyDown={event => {
						for (const hotkey in HOTKEYS) {
							if (isHotkey(hotkey, event)) {
								event.preventDefault()
								const mark = HOTKEYS[hotkey]
								toggleMark(editor, mark)
							}
						}
						if (isHotkey('mod+enter', event)) {
							// insert line break here without removing styles
						}
						// manageLeftRightNav(event)
					}}
					style={{
						marginTop: "50px",
						paddingBottom: "100px",
					}}
					className='qslate'
				// onFocus={() => {
				// 	setSelected(true)
				// 	setFocused(true)
				// 	log.p('Now focused')
				// }}
				// onBlur={e => {
				// }}
				/>

			</Slate>
		</div>
	);
};

const initialValue = [
	{
		type: "heading-two",
		children: [
			{
				text: "Hello Slate Demo",
			},
		],
	},
	{
		type: "paragraph",
		children: [
			{
				text: "In addition to nodes that contain editable text, you can also create other types of nodes, like images or videos.",
			},
		],
	},
	{
		type: "image",
		url: "https://source.unsplash.com/kFrdX5IeQzI",
		children: [{ text: "" }],
	},
	{
		type: "paragraph",
		children: [
			{
				text: "This example shows images in action. It features two ways to add images. You can either add an image via the toolbar icon above, or if you want in on a little secret, copy an image URL to your keyboard and paste it anywhere in the editor!",
			},
		],
	},
];

export default SlateEditor;
