import log from "cslog";
import { nanoid } from "nanoid";
import { GSTYLE, THEME_COLORS, THEME_FONTSET } from "../../data/gstyle";
// import { ELEMENTS } from "../absolute/data/elements";
// import { ELEMENTS } from "../responsive/data/elements";
import { ELEMENTS } from "../../data/elements/responsive/elements";
// import { DEFAULT_HEADER_SECTION, DEFAULT_NAV_MENU } from "./defaults";
import {
    DEFAULT_NAV_MENU,
    DEFAULT_HEADER_SECTION,
    PAGE_BEFORE_ID,
    PAGE_AFTER_ID,
} from "../../data/defaults";

export function addPage(name, slug, section_style = {}, header_style = {}) {
    //Create initial page
    const out = {};

    //Craete initial section
    const lay_id = nanoid(10);
    const box_id = nanoid(10);

    //==========
    const header_id = nanoid(10);
    const header_data = {
        ...ELEMENTS["header"],
        id: header_id,
        pid: box_id,
        style: {
            ...ELEMENTS["header"].style,
            ...header_style,
        },
        input: {
            ...ELEMENTS["header"].input,
            content: name,
        },
    };
    // addElement(header_id, header_data);
    out[header_id] = header_data;

    const box_data = {
        ...ELEMENTS["box"],
        id: box_id,
        pid: lay_id,
        childs: [header_id],
    };
    // addElement(box_id, box_data);
    out[box_id] = box_data;

    const lay_data = {
        ...ELEMENTS["section"],
        id: lay_id,
        childs: [box_id],
        widths: [100],
        style: {
            ...ELEMENTS["section"].style,
            ...section_style,
        },
    };
    // addElement(lay_id, lay_data);
    out[lay_id] = lay_data;

    const page_data = { ...ELEMENTS["page"], id: slug, sections: [lay_id] };
    // addElement(slug, page_data);
    out[slug] = page_data;

    return out;
}

function addBox(box_id, section_id) {
    const out = {};

    //==========
    const header_id = nanoid(10);
    const header_data = {
        ...ELEMENTS["header"],
        id: header_id,
        pid: box_id,
    };
    out[header_id] = header_data;
    //==========

    const box_data = {
        ...ELEMENTS["box"],
        id: box_id,
        pid: section_id,
        childs: [header_id],
    };

    out[box_id] = box_data;
    return out;
}

const getNewWebsite = () => {
    log.p("Generating website data");
    const data = {};

    const name = "Unnamed Website";
    data["title"] = name;
    data["slug"] = `website_${nanoid(5)}`;
    data["type"] = "website";

    const info = {
        id: 1,
        type: "website",
        layout: "responsive",
        pagination: {
            type: "named",
            fixed: ["home", "404"],
            floatingIndex: true,
            allowAdd: true,
            pageMultiple: 1,
        },
        devices: ["desktop", "tab", "mobile"],
        homePage: "home",
    };
    data["info"] = info;

    const gconfig = {
        colors: THEME_COLORS,
        fontSets: THEME_FONTSET,
    };

    const gstyles = {};
    Object.keys(GSTYLE).map((key) => {
        const style_data = { id: key, ...GSTYLE[key] };
        gstyles[key] = style_data;
    });

    const globalConfig = {
        defaultFontFamily: `Arial, Helvetica, sans-serif`,
        pageBackground: `#f9f6ff`,
    };

    data["gconfig"] = {
        gconfig: gconfig,
        gstyles: gstyles,
        globalConfig: globalConfig,
    };

    //Create initial page
    const id = "home";
    let out_home = {};
    //Craete initial section

    const section_id = nanoid(10);

    const box1_id = nanoid(10);
    const box2_id = nanoid(10);

    //==========
    const header_id = nanoid(10);
    const header_data = {
        ...ELEMENTS["header"],
        id: header_id,
        pid: box1_id,
    };
    // addElement(header_id, header_data);
    out_home[header_id] = header_data;
    //==========

    const box_data = {
        ...ELEMENTS["box"],
        id: box1_id,
        pid: section_id,
        childs: [header_id],
    };

    // addElement(box1_id, box_data);
    out_home[box1_id] = box_data;

    const box2_data = { ...ELEMENTS["box"], id: box2_id, pid: section_id };
    // addElement(box2_id, box2_data);
    out_home[box2_id] = box2_data;

    const section_data = {
        ...ELEMENTS["section"],
        id: section_id,
        pid: id,
        childs: [box1_id],
        widths: [100],
    };
    // addElement(section_id, section_data);
    out_home[section_id] = section_data;
    out_home = {
        ...out_home,
        // ...nav_out,
    };

    const page_data = {
        ...ELEMENTS["page"],
        id: id,
        // sections: [menu_section_id, section_id],
        // sections: [menu_section_id, section_id],
        sections: [section_id],
    };

    out_home[id] = page_data;

    //Nav Menu==============================================================
    //======================================================================
    let nav_out = {};
    const nav_id = DEFAULT_NAV_MENU;
    const menu_box_id = nanoid(10);
    const menu_section_id = DEFAULT_HEADER_SECTION;

    // Drawer======================
    const drawer_box = addBox("qdrawer", null);
    //=============================

    const nav_data = {
        ...ELEMENTS["navMenu"],
        id: nav_id,
        fixChild: "qdrawer",
    };
    nav_out[nav_id] = nav_data;
    nav_out = {
        ...nav_out,
        ...drawer_box,
    };

    let menu_box_data = {
        ...ELEMENTS["box"],
        id: menu_box_id,
        pid: menu_section_id,
        childs: [nav_id],
    };
    menu_box_data = {
        ...menu_box_data,
        style: {
            ...menu_box_data.style,
            padding: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        input: {
            ...menu_box_data.input,
            alignChilds: "center",
        },
    };

    nav_out[menu_box_id] = menu_box_data;

    const menu_logo_box_id = nanoid(10);
    let menu_logo_box = {
        ...ELEMENTS["box"],
        id: menu_logo_box_id,
        pid: menu_section_id,
        style: {
            ...ELEMENTS["box"].style,
            padding: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        input: {
            ...ELEMENTS["box"].input,
            alignChilds: "center",
        },
    };
    // addElement(menu_logo_box_id, menu_logo_box);
    nav_out[menu_logo_box_id] = menu_logo_box;

    const menu_section_data = {
        // ...ELEMENTS["headerSection"],
        ...ELEMENTS["section"],
        id: menu_section_id,
        childs: [menu_logo_box_id, menu_box_id],
        input: {
            // ...ELEMENTS["headerSection"].input,
            ...ELEMENTS["section"].input,
            zIndex: 3,
            widths: [40, 60],
            sticky: {
                type: "top",
                offset: 0,
            },
        },
        imobile: {
            // ...ELEMENTS["headerSection"].imobile,
            ...ELEMENTS["section"].imobile,
            orientation: "h",
            // widths: [70, 30],
        },
        style: {
            background: {
                type: "config",
                value: "secondryColor",
            },
            boxShadow: {
                type: "solid",
                params: {
                    hlength: 0,
                    vlength: 6,
                    blur_radius: 14,
                    spread_radius: -10,
                    color: "rgba(0, 0, 0, 0.48)",
                },
                value: " 0px 6px 14px -10px rgba(0, 0, 0, 0.48)",
            },
        },
    };
    // addElement(menu_section_id, menu_section_data);
    nav_out[menu_section_id] = menu_section_data;

    const out_page_before = {
        ...nav_out,
    };
    const page_before_data = {
        ...ELEMENTS["page"],
        id: PAGE_BEFORE_ID,
        sections: [menu_section_id],
    };
    out_page_before[PAGE_BEFORE_ID] = page_before_data;
    //=============================================================
    //=============================================================

    const page_data_404 = addPage("404", "404");
    const page_data_about = addPage("About Us", "about_us");

    const out_page_after = addPage(
        "Footer",
        PAGE_AFTER_ID,
        {
            background: {
                type: "config",
                value: "neutralColor",
            },
        },
        {
            color: {
                type: "config",
                value: "primaryColor",
            },
        }
    );

    const page_info = [
        { name: "Page Before", id: PAGE_BEFORE_ID },
        { name: "Page After", id: PAGE_AFTER_ID },
        { name: "Home", id: "home" },
        { name: "About Us", id: "about_us" },
        { name: "404", id: "404" },
    ];
    data["data"] = {
        page_info: page_info,
        pages: [
            {
                id: PAGE_BEFORE_ID,
                name: "Page Before",
                info: { id: PAGE_BEFORE_ID, name: "Page Before" },
                visibility: "PUBLIC",
                html: out_page_before,
            },
            {
                id: PAGE_AFTER_ID,
                name: "Page After",
                info: { id: PAGE_AFTER_ID, name: "Page After" },
                visibility: "PUBLIC",
                html: out_page_after,
            },
            {
                id: "home",
                name: "Home",
                info: { id: "home", name: "Home" },
                visibility: "PUBLIC",
                html: out_home,
            },
            {
                id: "about_us",
                name: "About Us",
                info: { id: "about_us", name: "About Us" },
                visibility: "PUBLIC",
                html: page_data_about,
            },
            {
                id: "404",
                name: "404",
                info: { id: "404", name: "404" },
                visibility: "PUBLIC",
                html: page_data_404,
            },
        ],
        topHeader: nav_out,
    };

    data["others"] = {};

    const seo = {
        title: "",
        description: "",
        favicon: process.env.PUBLIC_URL + "img_pp.png",
        previewImage: {
            params: null,
            value: "/img_pp.png",
        },
        ogTitle: "",
        ogImage:
            "https://images.unsplash.com/photo-1565118531796-763e5082d113?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTIyMTV8MHwxfHNlYXJjaHw1fHx8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080",
    };
    data["seo"] = seo;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewWebsite;
