/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
// import { parseStyle, decodeStyle } from "../parser/styleparser";
import classNames from "classnames";
import ContentEditable from "react-contenteditable";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import log from "cslog";
// import { getContent } from "../../../../dynamicdata/parser";
import { getContent } from "../../../junctionAbs";

const isString = (value) => {
	return typeof value === "string" || value instanceof String;
};

function Header({ id, db, gs, dydb, ctx }) {
	const data = db[id];

	log.d(gs, "GS in Header rendering");

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	// let content = input?.content;
	const content = getContent(dydb, input?.content);

	// if (!isString(content)) {
	//     content = getContent(dydb, content);
	// }

	return (
		<ElementWrapper id={id}>
			<ContentEditable
				className={classNames(ps.classes)}
				html={content ? content : ""}
				disabled={true}
				tagName={input.headingTag.type}
				// className="qh1"
				css={{
					height: "100%",
					...decodeStyle(ps.fixStyle, gs),
					...decodeStyle(ps.style, gs),
					textAlign: input.textAlign,
					textTransform: input.uppercase ? "uppercase" : "none",
					fontWeight: input.bold ? "bold" : "normal",
					fontSize:
						input.headingTag.type === "div"
							? input.headingTag.value
							: "auto",
					letterSpacing: input.letterSpacing?.value || "normal",
					// lineHeight:
					//     input.headingTag.type === "div"
					//         ? input.headingTag.value
					//         : "auto",
					outline: "none",
				}}
			/>
		</ElementWrapper>
	);
}

export default Header;
