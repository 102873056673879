import log from "cslog";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { useFolders, usePhotos } from "../actions/assets";
import {
	useCreateFolder,
	useUploadPhoto,
	useDeleteFile,
	useArchiveFile,
} from "../actions/mutations/assets";
import { imageLibConfigState } from "../db/editorConfig";
import { selectedImageState } from "../db/elementDb";
import { getAssetURL } from "../_helper/image";
import CameraDialog from "./CameraDialog";
import GalleryView from "./GalleryView";
import SingleInputPopup from "./SingleInputPopup";
import ImageUploader from "./ImageUploader";
import UploadButton from "./UploadButton";

const ImageLibrary = ({ placeholder, multiple, cam = false }) => {
	// const [data, setData] = useState();
	const [imageLibConfig, setImageLibConfig] =
		useRecoilState(imageLibConfigState);
	const { isLoading, isSuccess, data } = usePhotos(imageLibConfig.folder);
	const { data: dataFolders } = useFolders(imageLibConfig.folder);
	const [uploading, setUploading] = useState(false);
	const [selectedImage, setSelectedImage] =
		useRecoilState(selectedImageState);
	const [camopen, setCamopen] = useState(cam);
	const mutation = useUploadPhoto();
	const mutationFolder = useCreateFolder();
	// const mutationDeleteFile = useDeleteFile();
	const mutationArchiveFile = useArchiveFile();
	const [enableOptions, setEnableOptions] = useState(false);
	// const [filesToUpload, setFilesToUpload] = useState([]);
	// const [openImageUploader, setOpenImageUploader] = useState(false);

	const folder = imageLibConfig.folder;
	const setFolder = (newFolder) => {
		setImageLibConfig({
			...imageLibConfig,
			folder: newFolder,
		});
		setEnableOptions(false);
	};

	const uploadImage = (title, photo, name = null) => {
		log.d(photo, "Next to upload");
		setUploading(true);
		const formD = new FormData();

		// formD.append("title", "_qhidden_upload_");
		// formD.append("hidden", true);

		if (folder) {
			formD.append("folder", folder.id);
		}
		if (name) {
			formD.append("file", photo, name);
		} else {
			formD.append("file", photo);
		}

		log.d(formD, "Form Data");
		mutation.mutate(formD);
	};

	// const onFile = (eve) => {
	//     console.log("Got Image");
	//     const photos = eve.target.files;
	//     if (photos.length > 0) {
	//         setFilesToUpload(photos);
	//         setOpenImageUploader(true);
	//     }
	// };

	// const uploadImages = (images) => {
	//     log.d(images, "Uploading all");
	//     images.forEach((photo) => {
	//         if (photo) {
	//             // if (typeof photo === "object" && photo !== null) {
	//             uploadImage("Uploaded", photo);
	//             // }
	//         }
	//     });
	// };

	const createFolder = (name) => {
		log.d(name, "Creating Folder");
		if (name !== "") {
			mutationFolder.mutate({
				name: name,
				parent: folder?.id,
			});
		}
	};

	const deleteSelectedFile = () => {
		if (selectedImage?.id) {
			mutationArchiveFile.mutate(selectedImage?.id);
		}
	};

	log.d(data, "Photos Data");

	return (
		<div>
			{data ? (
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{/* <button>Hello</button> */}
						{/* <Breadcrumb
                            icon="right angle"
                            sections={folder_paths}
                            size="big"
                        /> */}
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							{/* {folder && ( */}
							<Icon
								name="home"
								size="large"
								onClick={() => {
									setFolder(null);
								}}
								style={{
									marginRight: "20px",
								}}
								color="blue"
								link
							/>
							{/* )} */}
							<span
								style={{
									fontSize: "1.5rem",
									fontWeight: "bold",
								}}
							>
								{folder ? capitalize(folder.name) : "Home"}
							</span>
						</div>
						<div>
							<span
								style={{
									paddingRight: "10px",
								}}
							>
								{enableOptions && (
									<Button
										loading={mutationArchiveFile.isLoading}
										color="red"
										onClick={deleteSelectedFile}
										icon
										labelPosition="left"
									>
										Delete
										<Icon name="trash alternate" />
									</Button>
								)}
							</span>
							<UploadButton
								primary={true}
								compress={true}
								getPayload={(photo) => {
									if (folder) {
										return {
											folder: folder?.id,
										};
									} else {
										return {};
									}
								}}
								btn_label={null}
							/>
							{/* <Button
                                icon
                                primary
                                // labelPosition="left"
                                onClick={(eve) => {
                                    eve.preventDefault();
                                    document
                                        .getElementById("photo-in-zzz")
                                        .click();
                                }}
                                // loading={uploading}
                                loading={mutation.isLoading}
                            >
                                <Icon name="upload" />
                            </Button> */}
							<Button
								icon
								primary
								// labelPosition="left"
								onClick={() => setCamopen(true)}
							>
								<Icon name="camera" />
							</Button>

							<SingleInputPopup
								size="mini"
								trigger={
									<Button
										icon
										primary
										// labelPosition="left"
										loading={mutationFolder.isLoading}
									>
										{/* Create Folder */}
										<Icon name="folder" />
									</Button>
								}
								label="New Folder Name"
								btnText="Create"
								onSubmit={(name) => createFolder(name)}
							/>
							<CameraDialog
								open={camopen}
								setOpen={(value) => setCamopen(value)}
								onPhoto={(photo) =>
									uploadImage(
										"Captured",
										photo,
										"captured.jpg"
									)
								}
							/>
							{/* <ImageUploader
                                open={openImageUploader}
                                setOpen={(value) => {
                                    setOpenImageUploader(value);
                                    if (!value) {
                                        setFilesToUpload([]);
                                    }
                                }}
                                photos={filesToUpload}
                                uploadPhotos={uploadImages}
                            /> */}
							{/* <input
                                type="file"
                                // accept="image/*"
                                accept="image/png, image/gif, image/jpeg"
                                multiple="multiple"
                                name="photo"
                                onChange={onFile}
                                id="photo-in-zzz"
                                hidden
                            /> */}
						</div>
					</div>

					{dataFolders && (
						<FolderBar
							dataFolders={dataFolders}
							setFolder={setFolder}
						/>
					)}

					{/* <Divider /> */}
					{data.length > 0 ? (
						<div
							style={{
								height: "70vh",
								overflow: "auto",
								marginTop: "10px",
								// border: "2px dashed red",
							}}
						>
							<GalleryView
								data={data}
								multiple={multiple}
								makeThumb={(item, index) => {
									return {
										width: item.width + 0,
										height: item.height + 0,
										src: getAssetURL(item),
										id: item.id,
										dindex: index,
									};
								}}
								makeImgObj={(photo) => {
									return {
										srcType: "lib",
										id: photo.id,
										width: photo.width,
										height: photo.height,
										title: photo.title,
										filename_disk: photo.filename_disk,
										filename_download:
											photo.filename_download,
										storage: photo.storage,
										type: photo.type,
										filesize: photo.filesize,
										full: getAssetURL(photo),
									};
								}}
								selectedImage={selectedImage}
								setSelectedImage={(newImg) => {
									setSelectedImage(newImg);
									setEnableOptions(true);
								}}
							/>
							<div
								style={{
									height: "70px",
								}}
							></div>
						</div>
					) : (
						<h3 style={{ color: "gray" }}>No photo uploaded</h3>
					)}
				</div>
			) : (
				placeholder
			)}
		</div>
	);
};

export default ImageLibrary;


export function FolderBar({ dataFolders, setFolder }) {
	return <div
		style={{
			marginTop: "10px",
			display: "flex",
			flexDirection: "row",
			gap: "7px",
			flexWrap: "wrap",
		}}
	>
		{dataFolders.map((df) => {
			return (
				<div
					style={{
						// width: "150px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "10px 20px",
						background: "#f5cb42",
						borderRadius: "5px",
						cursor: "pointer",
						gap: "7px",
					}}
					onClick={() => {
						setFolder(df);
					}}
				>
					<Icon name="folder open outline" />
					{capitalize(df.name)}
				</div>
			);
		})}
	</div>
}

export function FolderDropdown({ dataFolders, setFolder, selectedFolder }) {
	return <Dropdown
		button className="icon" labeled icon="folder open outline"
		text={selectedFolder ? capitalize(selectedFolder.name) : "Select Folder"}
		fluid={true}
		selection={true}
		style={{
			// marginTop: "10px",
			background: "#f5cb42",
			// zIndex: 1000,
			// marginLeft: "10px",
		}}
		options={dataFolders.map((df) => {
			return {
				key: df.id,
				text: capitalize(df.name),
				value: df.id,
				onClick: () => {
					setFolder(df);
				},
			};
		}
		)}
		onChange={(e, { value }) => {
			const folder = dataFolders.find((df) => df.id === value);
			setFolder(folder);
		}}
	/>
}