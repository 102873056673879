/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function Divider({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    const dwebStyle = decodeStyle(ps.style, gs);

    let color = dwebStyle.color;

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover),
                }}
            >
                <hr
                    style={{
                        borderTop: `${input.thickness * 0.5}px ${
                            input.lineStyle === "rounded"
                                ? "solid"
                                : input.lineStyle
                        } ${color}`,
                        borderRadius: `${
                            input.lineStyle === "rounded" ? 100 : 0
                        }px`,
                    }}
                ></hr>
            </div>
        </ElementWrapper>
    );
}

export default Divider;
