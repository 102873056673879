const PRIMARY = {
	bookPage: {
		id: "bookPage",
		elementGroup: "primary",
		type: "bookPage",
		name: "Page",
		childs: [],
		bg: [],
		layout: [],
		style: {},
		input: {},
		itype: {},
	},
	freeBox: {
		id: "freeBox",
		elementGroup: "primary",
		type: "freeBox",
		name: "FreeBox",
		childs: [],
		layout: [],
		style: {},
		input: {},
		itype: {},
	},
	header: {
		id: "header",
		elementGroup: "primary",
		type: "header",
		group: "basic",
		pgroup: "free",
		name: "Header",
		resize: "text",
		extends: {
			// type: "default",
			type: "gstyle",
			name: "header",
		},
		dims: {
			w: 200,
			h: 40,
		},
		cstate: "normal",
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	p: {
		id: "p",
		elementGroup: "primary",
		type: "p",
		group: "basic",
		pgroup: "free",
		name: "Text",
		resize: "text",
		extends: {
			type: "gstyle",
			name: "p",
		},
		dims: {
			w: 220,
			h: 60,
		},
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	text: {
		id: "text",
		elementGroup: "primary",
		type: "text",
		group: "basic",
		pgroup: "free",
		name: "Text New",
		resize: "text",
		extends: {
			type: "gstyle",
			name: "text",
		},
		dims: {
			w: 220,
			h: 60,
		},
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	link: {
		id: "link",
		elementGroup: "primary",
		type: "link",
		group: "basic",
		pgroup: "free",
		name: "Link",
		resize: "text",
		extends: {
			type: "default",
			name: "link",
		},
		dims: {
			w: 100,
			h: 40,
		},
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	richtext: {
		id: "richtext",
		elementGroup: "primary",
		type: "richtext",
		group: "basic",
		pgroup: "free",
		name: "Rich Text",
		resize: "text",
		extends: {
			type: "default",
			name: "richtext",
		},
		dims: {
			w: 200,
			h: 100,
		},
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	htmltext: {
		id: "htmltext",
		elementGroup: "primary",
		type: "htmltext",
		group: "basic",
		pgroup: "free",
		name: "Rich Text",
		resize: "text",
		extends: {
			type: "default",
			name: "htmltext",
		},
		dims: {
			w: 200,
			h: 100,
		},
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	button: {
		id: "button",
		elementGroup: "primary",
		type: "button",
		group: "basic",
		pgroup: "free",
		name: "Button",
		readymate: "button" /* button type readymate collection */,
		resize: "text",
		extends: {
			type: "gstyle",
			name: "button",
		},
		dims: {
			w: 120,
			h: 60,
		},
		css: null,
		style: {},
		hover: {},
		tablet: {
			// backgroundColor: "red",
		},
		mobile: {
			// backgroundColor: "orange",
		},
		input: {},
		itype: {
			content: "text",
		},
		itablet: {},
		imobile: {},
	},
	richDialog: {
		id: "richDialog",
		elementGroup: "primary",
		type: "richDialog",
		group: "basic",
		pgroup: "free",
		name: "Rich Dialog",
		readymate: "button" /* button type readymate collection */,
		resize: "text",
		extends: {
			type: "default",
			name: "richDialog",
		},
		dims: {
			w: 150,
			h: 60,
		},
		css: null,
		style: {},
		hover: {},
		tablet: {
			// backgroundColor: "red",
		},
		mobile: {
			// backgroundColor: "orange",
		},
		input: {
			html: [
				{
					type: "paragraph",
					children: [
						{
							text: "",
						},
					],
				},
			],
			content: "Read More",
		},
		itype: {
			html: "html",
			content: "text",
		},
		itablet: {},
		imobile: {},
	},
	img: {
		id: "img",
		elementGroup: "primary",
		type: "img",
		group: "basic",
		pgroup: "free",
		name: "Image",
		resize: "cover",
		extends: {
			type: "default",
			name: "img",
		},
		dims: {
			w: 120,
			h: 90,
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	video: {
		id: "video",
		elementGroup: "primary",
		type: "video",
		group: "basic",
		pgroup: "pro",
		name: "Video",
		resize: "fix",
		extends: {
			type: "default",
			name: "video",
		},
		dims: {
			w: 150,
			h: 100,
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	blank: {
		id: "blank",
		elementGroup: "primary",
		type: "blank",
		group: "basic",
		pgroup: "free",
		name: "Space",
		readymate: null /* button type readymate collection */,
		resize: "free",
		extends: {
			type: "default",
			name: "blank",
		},
		dims: {
			w: 6,
			h: 6,
		},
		css: null,
		style: {},
		tablet: {},
		mobile: {},
		input: {},
		itype: {},
		itablet: {},
		imobile: {},
	},
	divider: {
		id: "divider",
		elementGroup: "primary",
		type: "divider",
		group: "basic",
		pgroup: "free",
		name: "Divider",
		readymate: null /* button type readymate collection */,
		resize: "text",
		extends: {
			type: "default",
			name: "divider",
		},
		dims: {
			w: 200,
			h: 30,
		},
		css: null,
		style: {},
		tablet: {},
		mobile: {},
		input: {},
		itype: {},
		itablet: {},
		imobile: {},
	},
	icon: {
		id: "icon",
		elementGroup: "primary",
		type: "icon",
		group: "basic",
		pgroup: "free",
		name: "Icon",
		resize: "fix",
		extends: {
			type: "default",
			name: "icon",
		},
		dims: {
			w: 75,
			h: 75,
		},
		css: null,
		style: {
			background: {
				type: "simple",
				params: "#E0E1E2",
				value: "#E0E1E2",
			},
		},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	graphic: {
		id: "graphic",
		elementGroup: "primary",
		type: "graphic",
		group: "basic",
		pgroup: "free",
		name: "Graphic",
		resize: "fix",
		extends: {
			type: "default",
			name: "graphic",
		},
		dims: {
			w: 120,
			h: 90,
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	slidingText: {
		id: "slidingText",
		elementGroup: "primary",
		type: "slidingText",
		group: "basic",
		pgroup: "free",
		name: "Sliding Text",
		resize: "text",
		extends: {
			// type: "default",
			type: "default",
			name: "slidingText",
		},
		dims: {
			w: 150,
			h: 50,
		},
		cstate: "normal",
		css: null,
		style: {},
		mobile: {},
		input: {},
		itype: {},
	},
	shape: {
		id: "shape",
		elementGroup: "primary",
		type: "shape",
		group: "basic",
		pgroup: "free",
		name: "Shape",
		resize: "fill",
		extends: {
			type: "default",
			name: "shape",
		},
		dims: {
			w: 75,
			h: 75,
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	list: {
		id: "list",
		elementGroup: "primary",
		type: "list",
		group: "basic",
		pgroup: "free",
		name: "List",
		resize: "contain",
		extends: {
			type: "default",
			name: "list",
		},
		css: null,
		dims: {
			w: 120,
			h: 100,
		},
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
	circle: {
		id: "circle",
		type: "circle",
		group: "basic",
		pgroup: "free",
		name: "Circle",
		extends: null,
		dims: {
			w: 75,
			h: 75,
		},
		css: null,
		style: {},
		hover: {},
		mobile: {},
		input: {},
		itype: {},
	},
};

export default PRIMARY;
