import log from "cslog";
import { atom, selector } from "recoil";

// export const organizationState = atom({
// 	key: "organizationState",
// 	default: null,
// });

export const currentContextState = atom({
	key: "currentContextState",
	default: {
		organization: null,
		person: null
	}
})

export const titleState = atom({
	key: "titleState",
	default: "Unnamed",
});

export const slugState = atom({
	key: "slugState",
	default: " ",
});

export const customDomainState = atom({
	key: "customDomainState",
	default: null,
});

export const creationTypeState = atom({
	key: "creation_type",
	default: {},
});

export const urlSettingsState = atom({
	key: "URLSettings",
	default: {},
});


// info.layout
export const layoutTypeState = atom({
	key: "layoutTypeState",
	default: "",
});

export const bookInfoState = atom({
	key: "bookInfoState",
	default: {
		id: 3,
		domain: null,
		custom_domain: null,
	},
});

export const bookLayoutState = atom({
	key: "bookLayout",
	default: {
		default: {
			pageSize: {
				type: "magazine",
				value: { width: 440, height: 560 },
			},
			padding: {
				type: "multiple",
				params: {
					cside: "top",
					top: 24,
					bottom: 24,
					left: 12,
					right: 12,
				},
				value: "24px 12px 24px 12px",
			},
			background: {
				type: "simple",
				params: "white",
				value: "white",
			},
			borderRadius: {
				type: "single",
				params: 3,
				value: "3px",
			},
		},
	},
});

export const currentLayoutState = selector({
	key: "currentLayoutState",
	get: ({ get }) => {
		const currentPage = get(currentBookPageState);
		const allLayout = get(bookLayoutState);

		const size = currentPage?.size || "default";
		const layout = allLayout[size];
		return layout;
	},
});

export const BOOK_CONFIG_TYPE = {
	defaultFontFamily: "fontFamily",
	padding: "padding",
	pageSize: "pageSize",
	pageMargins: "pageMargins",
	background: "background",
	borderRadius: "borderRadius",
};

export const bookBarState = atom({
	key: "bookBar",
	default: {
		zoom: 1,
	},
});

// export const bookPageFamily = atomFamily({
//     key: "bookPages",
//     default: {},
// });

export const bookPageArrayState = atom({
	key: "bookPageArrayState",
	default: [
		{
			pid: "firstPage",
			id: null,
			page_no: 1,
			name: "Cover Page",
		},
	],
});

export const bookContentPageState = selector({
	key: "bookContentPageArrayState",
	get: ({ get }) => {
		const pages = get(bookPageArrayState);
		const newPages = [...pages];
		const gp = newPages.splice(0, 1);
		// const fp= newPages.splice(0, 1);
		const bp = newPages.splice(1, 1);
		log.d(bp, "BP");
		newPages.push(bp[0]);
		// newPages.splice(0, 3);
		log.d(newPages, "New Pages");
		return newPages;
	},
});
export const currentBookPageState = atom({
	key: "currentBookPage",
	default: {
		pid: "firstPage",
		page_no: 1,
	},
});

// export const bookPageState = selectorFamily({
//     key: "bookPageState",
//     get:
//         (id) =>
//         ({ get }) => {
//             const atm = get(bookPageFamily(id));
//             return atm;
//         },
//     set:
//         (id) =>
//         ({ set }, bookPage) => {
//             set(bookPageFamily(id), bookPage);
//             set(bookPageArray(id), (prev) => [...prev, bookPage.id]);
//         },
// });

export const bookControlState = atom({
	key: "bookControls",
	default: {
		move: true,
		edit: false,
		resize: false,
		zoom: 1,
		snap: true,
	},
});

export const bookLayerState = atom({
	key: "bookLayers",
	default: {
		bg: false,
		fg: true,
	},
});

export const usedSchemasState = atom({
	key: "usedSchemasState",
	default: [],
});

// STEPS FOR TOMORROW 10 AM
// 1) Setup Header, Footer, Left & Right Alignment
// Front Cover 2) Make Front Page
// Front Cover Back 3) Make Back Page- Some Full page Ad
// Back Cover 4) Back Cover- Full Page Add
// Page 1 5) First Page Index
// Page 2 6) Half Page Ads
// Page 3 7) Presidents Message
// Back Cover Inside 8) Quarter Page Ads
// Page 5 9) Article
// Page 6 10) Success Stories
// Page 4 11) 1/6 Ads
