/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ContentEditable from "react-contenteditable";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";
// import { getContent } from "../../../../dynamicdata/parser";
import { getContent } from "../../../junctionAbs";

const isString = (value) => {
    return typeof value === "string" || value instanceof String;
};

function NewText({ id, db, gs, dydb, ctx }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;
    let content = input?.content;
    if (!isString(content)) {
        content = getContent(dydb, content);
    }

    return (
        <ElementWrapper id={id}>
            <div
                css={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    textAlign: input.textAlign,
                    // "p::first-letter": {
                    //     fontSize: "200%",
                    // },
                    fontSize: ps.input.fontSize,
                }}
            >
                <ContentEditable
                    // html={input ? input.content : data.input.content}
                    html={content ? content : ""}
                    disabled={true}
                    tagName="p"
                    placeholder="Start Editing..."
                    style={{
                        lineHeight: ps.input.lineHeight,
                        fontFamily: ps.style.fontFamily,
                        outline: "none",
                        background: "transparent",
                        // whiteSpace: "pre-wrap",
                        maxWidth: "100%",
                        textAlign: ps.input.textAlign,
                    }}
                />
            </div>
        </ElementWrapper>
    );
}

export default NewText;
