import startCase from "lodash/startCase";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import {
    useAllCollections,
    useAllFields,
    useAllItems,
} from "../apis/collections";
import log from "cslog";
// import Handlebars from "handlebars";
import Handlebars from "handlebars/dist/cjs/handlebars";

export function CollectionField({ value, setValue }) {
    const { isLoading, isSuccess, isError, data } = useAllCollections();
    const [collectionOptions, setCollectionOptions] = useState([]);

    useEffect(() => {
        if (isSuccess && data) {
            // log.d(data, "Collections List");
            const options = data.map((table) => {
                let label = "";
                if (table.id.startsWith("qd_")) {
                    label = startCase(table.id.replace("qd_", ""));
                } else if (table.id.startsWith("qdb_")) {
                    label = startCase(table.id.replace("qdb_", ""));
                } else if (table.id.startsWith("directus_")) {
                    label = startCase(table.id.replace("directus_", ""));
                } else {
                    label = startCase(table.id);
                }
                return {
                    key: table.id,
                    text: label,
                    value: table.id,
                };
            });
            // options.push({
            //     key: "directus_users",
            //     text: "Users",
            //     value: "directus_users",
            // });
            setCollectionOptions(options);
        }
    }, [data, isSuccess]);

    return (
        <Form.Field>
            <label>Table</label>
            <Dropdown
                placeholder="Choose Table"
                selection
                loading={isLoading}
                options={collectionOptions}
                value={value}
                onChange={(e, { value }) => {
                    const fullValue = data.find((item) => item.id === value);
                    setValue(value, fullValue);
                }}
                search
            />
        </Form.Field>
    );
}

export function ItemDropdown({
    table,
    label,
    placeholder = "Choose Collection",
    value,
    setValue = () => {},
    setFullValue = () => {},
}) {
    const { isLoading, isSuccess, data } = useAllItems(
        table?.id,
        table?.id !== undefined
    );
    const [itemOptions, setItemOptions] = useState([]);

    const pk = table?.pk || "id";

    // log.d(table, "Table in ItemDD");

    useEffect(() => {
        if (isSuccess) {
            const template = Handlebars.compile(
                table?.display_template || "{{id}}"
            );
            const options = data.map((item) => {
                return {
                    key: item[pk],
                    text: template(item),
                    // text: item["name"],
                    value: item[pk],
                };
            });
            setItemOptions(options);
        }
    }, [isSuccess, data]);

    return (
        <Form.Field>
            {label && <label>{label}</label>}
            <Dropdown
                placeholder={placeholder}
                selection
                search
                loading={isLoading}
                options={itemOptions}
                value={value}
                onChange={(e, { value }) => {
                    setValue(value);
                    setFullValue(
                        itemOptions.find((item) => item.value === value)
                    );
                }}
            />
        </Form.Field>
    );
}

export function FieldDropdown({
    table,
    label,
    value,
    setValue,
    filter,
    setFilter,
    level = "field",
}) {
    const { isLoading, isSuccess, data } = useAllFields(
        table?.id,
        table?.id !== undefined
    );
    const [itemOptions, setItemOptions] = useState([]);
    const [selectedField, setSelectedField] = useState(null);

    useEffect(() => {
        if (isSuccess) {
            const options = data.map((item) => {
                return {
                    key: item["name"],
                    text: item["label"],
                    value: item["name"],
                };
            });
            setItemOptions(options);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isSuccess) {
            // const field = data.find((f) => f.name === value);
            const field = data.find((f) => f.name === filter[level]);
            setSelectedField(field);
        }
    }, [filter[level]]);

    log.d(selectedField, "SelectedField");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Form.Field>
                {label && <label>{label}</label>}
                <Dropdown
                    placeholder="Choose Field"
                    selection
                    // compact
                    loading={isLoading}
                    options={itemOptions}
                    value={filter[level]}
                    onChange={(e, { value }) => {
                        log.d("Value changed");
                        // setValue(value);
                        if (level === "field") {
                            const field = data.find((f) => f.name === value);
                            setSelectedField(field);
                            setFilter({
                                ...filter,
                                [level]: value,
                                ...(field?.interface?.type === "relationM2M"
                                    ? {
                                          junctionfield:
                                              field?.interface?.junction_field,
                                      }
                                    : { junctionfield: null }),
                                subfield: null,
                            });
                        } else {
                            setFilter({
                                ...filter,
                                [level]: value,
                            });
                        }
                    }}
                />
            </Form.Field>
            {selectedField?.interface?.type?.startsWith("relation") && (
                <FieldDropdown
                    table={{
                        id: selectedField?.interface?.foreign_key_table,
                    }}
                    label={false}
                    filter={filter}
                    setFilter={setFilter}
                    level="subfield"
                />
            )}
        </div>
    );
}
