const CHARTS = {
    pie: {
        id: "pie",
        etype: "chart",
        type: "pie",
        group: "basic",
        pgroup: "pro",
        name: "Pie Chart",
        extends: {
            type: "default",
            name: "pie",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    lineChart: {
        id: "lineChart",
        etype: "chart",
        type: "lineChart",
        group: "basic",
        pgroup: "pro",
        name: "Line Chart",
        extends: {
            type: "default",
            name: "lineChart",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    barChart: {
        id: "barChart",
        etype: "chart",
        type: "barChart",
        group: "basic",
        pgroup: "pro",
        name: "Bar Chart",
        extends: {
            type: "default",
            name: "barChart",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
};

export default CHARTS;
