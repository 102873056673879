import { useSelected, ReactEditor, useSlate } from 'slate-react'
import { Transforms } from 'slate'
import DynamicDataOption from '../../../dynamic/DynamicDataOption'
import log from 'cslog'
import { booleanOption } from '../../../customize/SimpleOptions'
import { Fragment, useRef, useState } from 'react'
import { Button, Icon } from '../components'
import StyleButton from '../inputs/StyleButton'
import StyleButtonAlone from '../inputs/StyleButtonAlone'

const QVarElement = ({ attributes, children, element, lookup }) => {
	const selected = useSelected()
	const editor = useSlate()
	const path = ReactEditor.findPath(editor, element)

	const [engaged, setEngaged] = useState(false);

	log.d(element, 'QVarElemet element')

	return (
		<span
			{...attributes}
			contentEditable={false}
			data-playwright-selected={selected}
			data-slate-void
		>
			<InlineChromiumBugfix />
			{children}
			<DynamicDataOption
				value={element.data || {}}
				onChange={new_val => {
					Transforms.setNodes(editor, { data: new_val }, { at: path })
				}}
				ftype='string'
				block_close={engaged}
				trigger={
					<button
						style={{
							// backgroundColor: 'transparent',
							border: '1px dashed #666',
							outline: 'none',
							borderRadius: '2px',
							userSelect: 'none',
							cursor: 'pointer',
							// -----------------------
							fontWeight: element?.data?.bold ? 'bold' : 'normal',
							fontStyle: element?.data?.italic ? 'italic' : 'normal',
							textDecoration: element?.data?.underline ? 'underline' : 'none',
							backgroundColor: element?.data?.bgColor || 'transparent',
							color: element?.data?.color || 'auto',
						}}
					>
						{element.data?.field || 'Edit'}
					</button>
				}
				ExtraComponent={({ value, onChange }) => {
					return <Fragment>
						<div style={{
							display: 'flex',
							flexDirection: "row",
							gap: "15px",
							alignItems: "center",
						}}>
							<Button
								active={element?.data?.bold}
								onMouseDown={event => {
									event.preventDefault()
									Transforms.setNodes(editor, { data: { ...element.data, bold: !element?.data?.bold } }, { at: path })
								}}
							>
								<Icon>fas fa-bold</Icon>
							</Button>
							<Button
								active={element?.data?.italic}
								onMouseDown={event => {
									event.preventDefault()
									Transforms.setNodes(editor, { data: { ...element.data, italic: !element.data?.italic } }, { at: path })
								}}
							>
								<Icon>fas fa-italic</Icon>
							</Button>
							<Button
								active={element?.data?.underline}
								onMouseDown={event => {
									event.preventDefault()
									Transforms.setNodes(editor, { data: { ...element.data, underline: !element.data?.underline } }, { at: path })
								}}
							>
								<Icon>fas fa-underline</Icon>
							</Button>
							<div style={{ width: "10px" }}></div>
							<StyleButtonAlone format='color' icon='fas fa-font'
								onChange={color => {
									Transforms.setNodes(editor, { data: { ...element.data, color } }, { at: path })
								}}
								onOpen={() => {
									setEngaged(true)
								}}
								onClose={() => {
									setEngaged(false)
								}}
							/>
							<StyleButtonAlone
								format='bgColor'
								icon='fas fa-fill-drip'
								onChange={bgColor => {
									Transforms.setNodes(editor, { data: { ...element.data, bgColor } }, { at: path })
								}}
								onOpen={() => {
									setEngaged(true)
								}}
								onClose={() => {
									setEngaged(false)
								}}
								onDone={() => {
									setEngaged(false);
								}}
							// onOpen={onOpenMenu}
							// onChange={onChangeMenu}
							// onDone={onDone}
							/>
							{/* {engaged ? "Yes" : "No"} */}

						</div>
					</Fragment>
				}}
			/>
			<InlineChromiumBugfix />
		</span>
	)
}

export default QVarElement

const InlineChromiumBugfix = () => (
	<span
		contentEditable={false}
		style={{
			fontSize: 0
		}}
	>
		{String.fromCodePoint(160) /* Non-breaking space */}
	</span>
)
