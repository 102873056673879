import { ELEMENTS as DEFAULT } from "../data/defaults/absolute/elements";

export function parseStyle(data, gs, cstate = "normal") {
    let root = data.extends;
    let rootStyles = {};

    if (root) {
        switch (root.type) {
            case "default":
                rootStyles = parseStyle(
                    DEFAULT[root.name],
                    gs,
                    (cstate = cstate)
                );
                break;
            case "gstyle":
                rootStyles = parseStyle(
                    gs.gstyles[root.name],
                    gs,
                    (cstate = cstate)
                );
                break;
            default:
                break;
        }
    }

    // let input = {};
    // if (data["inputFirst"]) {
    //     input = { ...data.inputFirst, ...rootStyles.input, ...data.input };
    // } else {
    //     input = { ...rootStyles.input, ...data.input };
    // }

    return {
        style: {
            ...rootStyles.style,
            ...data.style,
            ...(cstate === "hover" ? data.hover : {}),
        },
        fixStyle: { ...rootStyles.fixStyle, ...data.fixStyle },
        hover: { ...rootStyles.hover, ...data.hover },
        tablet: { ...rootStyles.tablet, ...data.tablet },
        mobile: { ...rootStyles.mobile, ...data.mobile },
        // complexStyle: { ...rootStyles.complexStyle, ...data.complexStyle },
        input: { ...rootStyles.input, ...data.input },
        // input: input,
        itype: { ...rootStyles.itype, ...data.itype },
        itablet: { ...rootStyles.itablet, ...data.itablet },
        imobile: { ...rootStyles.imobile, ...data.imobile },
        classes: { ...rootStyles.classes, ...data.classes },
        dataFields: { ...rootStyles.dataFields, ...data.dataFields },
        styleGroup: decodeGroups(rootStyles.styleGroup, data.styleGroup),
        mobileGroup: decodeGroups(rootStyles.mobileGroup, data.mobileGroup),
    };
}

function decodeGroups(source, desti) {
    if (!desti) {
        return source || {};
    }
    if (!source) {
        return desti;
    }
    const out = { ...source };
    Object.keys(desti).forEach((key) => {
        out[key] = {
            ...source[key],
            ...desti[key],
        };
    });
    // log.d(out, "Out complex");
    return out;
}

export function decodeStyle(style, gs) {
    const result = {};
    let value = null;
    Object.keys(style).map((key) => {
        value = style[key];
        if (typeof value === "object") {
            switch (value.type) {
                case "config":
                    result[key] = gs.config[value.value];
                    // log.p("GStyleConfig");
                    // log.d(gs.config[value.value], key);
                    break;
                // case "none":
                // 	break;
                default:
                    if (value.params?.type === "config") {
                        result[key] = decodeAttribute(value.params, gs);
                    } else {
                        result[key] = value.value;
                    }
            }
        } else {
            result[key] = value;
        }
    });
    return result;
}

export function decodeAttribute(value, gs) {
    switch (value.type) {
        case "config":
            return gs.config[value.value];
        default:
            return value.value;
    }
}
