const TOOLS = {
    qureal_tool: {
        id: "qureal_tool",
        etype: "tool",
        type: "qureal_tool",
        group: "complex",
        name: "Qureal Tool",
        extends: null,
    },
    qureal_app: {
        id: "qureal_app",
        etype: "app",
        type: "qureal_app",
        group: "complex",
        name: "Qureal App",
        extends: null,
    },
    qureal_screen: {
        id: "qureal_screen",
        etype: "screen",
        type: "qureal_screen",
        group: "complex",
        name: "Qureal Screen",
        extends: null,
    },
};

export default TOOLS;
