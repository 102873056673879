import axios from "axios";
import { useMutation } from "react-query";
import { restoreAccessToken } from "../_helper/auth";
import { queryClient } from "../_helper/query";

// export const flaskAxios = axios.create({
// 	baseURL: "https://aiengine.qureal.com/",
// 	headers: {},
// });

export const fastAxios = axios.create({
	baseURL: "https://botapi.qureal.com/",
	headers: {},
});

// "file_name": "ffa63ab4-f15e-4a06-a478-cc57d63ca40c.png"
export const useBGRemove = (onSuccess, onDone = () => { }) => {
	return useMutation(
		(data) => {
			return fastAxios
				// .post(`/image/remove_bg_from_name`, data, {
				.post(`/image/remove_bg_and_crop_by_name`, data, {
					headers: {
						Authorization: `Bearer ${restoreAccessToken()}`,
					},
				})
				.then((res) => {
					return res;
				});
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result);
				queryClient.invalidateQueries("photos");
			},
			onSettled: (data) => {
				onDone();
			},
		}
	);
};


export const useSmartCrop = (onSuccess, onDone = () => { }) => {
	return useMutation(
		(data) => {
			return fastAxios
				.post(`/image/crop_from_name`, data, {
					headers: {
						Authorization: `Bearer ${restoreAccessToken()}`,
					},
				})
				.then((res) => {
					return res;
				});
		},
		{
			onSuccess: (result, variables, context) => {
				onSuccess(result);
				queryClient.invalidateQueries("photos");
			},
			onSettled: (data) => {
				onDone();
			},
		}
	);
};
