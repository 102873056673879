import React, { useEffect, useState } from "react";
import { Button, Icon, Input, Pagination } from "semantic-ui-react";

import { useRecoilState } from "recoil";
import { usePexels, usePixabay, useUnsplash } from "../actions/custom";
import { selectedImageState } from "../db/elementDb";
import GalleryView from "./GalleryView";
import FullScreenCenter from "../components/FullScreenCenter";

const Pixabay = ({ placeholder, multiple, type = "all" }) => {
    const [term, setTerm] = useState(type === "all" ? "design" : "design png");
    const [search, setSearch] = useState(term);
    const [page, setPage] = useState(1);
    const [total_page, setTotalPage] = useState(0);
    const { data, isLoading } = usePixabay(search, 50, page, type);

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);

    useEffect(() => {
        if (!isLoading) {
            setTotalPage(data?.total_pages || 0);
        }
    }, [data?.total_pages]);

    return (
        <div style={{ zIndex: 5000 }}>
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                    }}
                >
                    <Input
                        placeholder="subdomain"
                        value={term}
                        onChange={(eve) => setTerm(eve.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setSearch(term);
                                setPage(1);
                            }
                        }}
                        style={{
                            flex: 1,
                        }}
                        fluid
                        action={
                            <Button
                                positive
                                onClick={() => setSearch(term)}
                                loading={isLoading}
                                icon
                                // labelPosition="right"
                            >
                                {/* Search */}
                                <Icon name="search" />
                            </Button>
                        }
                    />
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={1}
                        activePage={page}
                        onPageChange={(e, { activePage }) =>
                            setPage(activePage)
                        }
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={total_page}
                    />
                </div>
                {/* <Divider /> */}{" "}
                {!isLoading && data?.results?.length === 0 && (
                    <FullScreenCenter height="30vh" logo={false}>
                        <Icon
                            name="box"
                            size="large"
                            style={{ color: "#ccc" }}
                        />
                        <p style={{ fontSize: "1.5em", color: "#ccc" }}>
                            No image found, try different keyword
                        </p>
                    </FullScreenCenter>
                )}
                {data?.results ? (
                    <div
                        style={{
                            height: "70vh",
                            overflow: "auto",
                            marginTop: "10px",
                        }}
                    >
                        <GalleryView
                            data={data.results}
                            multiple={multiple}
                            makeThumb={(item, index) => {
                                return {
                                    src: item.previewURL,
                                    srcRegular: item.previewURL,
                                    width: item.previewWidth,
                                    height: item.previewHeight,
                                    id: item.id,
                                    dindex: index,
                                };
                            }}
                            makeImgObj={(photo) => {
                                const ext = photo.webformatURL.split(".").pop();
                                return {
                                    srcType: "pixabay",
                                    id: photo.id,
                                    width: photo.webformatWidth,
                                    height: photo.webformatHeight,
                                    description: photo.tags,
                                    dev: `https://botapi.qureal.com/proxies/pixabay/download/${photo.id}_${photo.webformatWidth}.${ext}`,
                                    full: `https://botapi.qureal.com/static/pxb/${photo.id}_${photo.webformatWidth}.${ext}`,
                                    // small: photo.previewURL, //optional
                                };
                            }}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                ) : (
                    placeholder
                )}
            </div>
        </div>
    );
};

export default Pixabay;
