import React from "react";
import FullScreenCenter from "../editor/components/FullScreenCenter";

function Unauthorized() {
    return (
        <FullScreenCenter>
            <h3>You are not authorized to edit this creation</h3>
        </FullScreenCenter>
    );
}

export default Unauthorized;
