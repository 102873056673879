import React, { useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

import { useRecoilState } from "recoil";
import { useTenor } from "../actions/custom";
import { selectedImageState } from "../db/elementDb";
import GalleryView from "./GalleryView";

const TenorGif = ({ placeholder, multiple }) => {
    const [term, setTerm] = useState("nature");
    // const [data, setData] = useState();
    const [search, setSearch] = useState(term);
    const { data, isLoading } = useTenor(search, 30);

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);

    return (
        <div style={{ zIndex: 5000 }}>
            <div>
                <Input
                    placeholder="Search Tenor"
                    value={term}
                    onChange={(eve) => setTerm(eve.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setSearch(term);
                        }
                    }}
                    fluid
                    action={
                        <Button
                            positive
                            onClick={() => setSearch(term)}
                            loading={isLoading}
                            icon
                            labelPosition="right"
                        >
                            Search
                            <Icon name="search" />
                        </Button>
                    }
                />
                {/* <Divider /> */}{" "}
                {data ? (
                    <div
                        style={{
                            height: "70vh",
                            overflow: "auto",
                            marginTop: "10px",
                        }}
                    >
                        <GalleryView
                            data={data}
                            multiple={multiple}
                            makeThumb={(item, index) => {
                                const gif = item.media[0].gif;
                                const nanogif = item.media[0].tinygif;
                                return {
                                    src: nanogif.url,
                                    srcRegular: gif.url,
                                    width: gif.dims[0],
                                    height: gif.dims[1],
                                    id: item.id,
                                    dindex: index,
                                };
                            }}
                            makeImgObj={(photo) => {
                                const gif = photo.media[0].gif;
                                const nanogif = photo.media[0].tinygif;
                                return {
                                    srcType: "tenor",
                                    id: photo.id,
                                    width: gif.dims[0],
                                    height: gif.dims[1],
                                    description: photo.title,
                                    thumb: nanogif.url,
                                    full: gif.url,
                                    small: nanogif.url, //optional
                                };
                            }}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                ) : (
                    placeholder
                )}
            </div>
        </div>
    );
};

export default TenorGif;
