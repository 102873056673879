import React from "react";
import FullScreenCenter from "../editor/components/FullScreenCenter";
import {Button } from "semantic-ui-react"

function PleaseSelectCreation() {
    return (
        <FullScreenCenter>
            <h3>Please select creation to edit or create new creation</h3>
			<a href="https://app.qureal.com">
			<Button primary>Go Home</Button>
			</a>
        </FullScreenCenter>
    );
}

export default PleaseSelectCreation;
