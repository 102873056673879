import PRIMARY from "./primary";
import WIDGETS from "./widgets";
import PANEL from "./panel";
import CHARTS from "./charts";
import TOOLS from "./tools";

const ELEMENTS = {
    ...PRIMARY,
    ...WIDGETS,
    ...CHARTS,
    ...TOOLS,
};

export { PRIMARY, WIDGETS, PANEL, ELEMENTS, CHARTS, TOOLS };
