import React from "react";
import { useRecoilValue } from "recoil";
import { pageContextState } from "./db";

const ACTION_MAPPING = {
	rewrite_rewrite: ["rewrite", "rewrite"],
	rewrite_with_tone: ["rewrite", "tone"],
};

// action format= [action, subaction, params, inputs]
export const useElementSuggestions = (element_data) => {
	const content = element_data?.input?.content;

	const out = [];
	switch (element_data?.type) {
		case "header":
		case "p":
			out.push({
				label: "Write",
				value: [
					"write",
					"write",
					null,
					[
						{
							name: "prompt",
							type: "text",
							label: "Describe what to write...",
						},
					],
				],
			});
			if (content?.length > 20) {
				out.push(
					...[
						{
							label: "Rewrite",
							value: ["write", "rewrite"],
						},
						{
							label: "Summarize",
							value: [
								"write",
								"rewrite_tone",
								{ tone: "summarize" },
							],
						},
						{
							label: "Expand",
							value: [
								"write",
								"rewrite_tone",
								{ tone: "expand" },
							],
						},
					]
				);
			}
			break;
		default:
			break;
	}
	return out;
};

export const useSuggestions = () => {
	const page = useRecoilValue(pageContextState);

	return [{ label: "Add new page", value: { action: "add_page" } }];
};
