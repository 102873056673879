import log from "cslog";
import { nanoid } from "nanoid";
import { backdropSizes } from "../../../data/extra/pageSizeData";
import getNewAbsoluteMultipage from "../../absolute/new";

function getInt(obj, key, default_val) {
    if (key in obj) {
        return parseInt(obj[key]) || default_val;
    }
    return default_val;
}

const getNewPackagingBox = (params) => {
    const info = {
        type: "packaging_box",
        layout: "absolute",
        pagination: {
            type: "numbered",
            fixed: [
                "BD",
                "BM",
                "G",
                "FR",
                "LE",
                "BA",
                "RI",
                "TO",
                "BO",
                "CTL",
                "CTR",
                "CBL",
                "CBR",
                "CET",
                "CEB",
                "SS",
            ],
            floatingIndex: true,
            allowAdd: false,
            pageMultiple: 1,
            sizing: "box3d", //null for standard
        },
        startPageId: "frontSide",
    };

    const pheight = getInt(params, "h", 450);
    const plength = getInt(params, "l", 300);
    const pwidth = getInt(params, "w", 200);
    const cap_height = 120;
    const cap_end_height = 50;
    const stick_height = 50;

    const common_properties = {
        padding: {
            type: "multiple",
            params: {
                cside: "top",
                top: 24,
                bottom: 24,
                left: 12,
                right: 12,
            },
            value: "24px 12px 24px 12px",
        },
        background: {
            type: "simple",
            params: "white",
            value: "white",
        },
        borderRadius: {
            type: "single",
            params: 3,
            value: "3px",
        },
    };
    const additionalLayouts = {
        front: {
            pageSize: {
                type: "xx",
                value: { width: plength, height: pheight },
            },
            ...common_properties,
        },
        side: {
            pageSize: {
                type: "yy",
                value: { width: pwidth, height: pheight },
            },
            ...common_properties,
        },
        top: {
            pageSize: {
                type: "zz",
                value: { width: plength, height: pwidth },
            },
            ...common_properties,
        },
        capLeft: {
            pageSize: {
                type: "cap",
                value: { width: pwidth, height: cap_height },
            },
            ...common_properties,
            clip: {
                type: "polygon",
                path: [
                    [0, 100],
                    [10, 90],
                    [15, 0],
                    [95, 0],
                    [95, 95],
                    [100, 100],
                ],
                hflip: false,
                vflip: false,
            },
        },
        capRight: {
            pageSize: {
                type: "cap",
                value: { width: pwidth, height: cap_height },
            },
            ...common_properties,
            clip: {
                type: "polygon",
                path: [
                    [0, 100],
                    [10, 90],
                    [15, 0],
                    [95, 0],
                    [95, 95],
                    [100, 100],
                ],
                hflip: true,
                vflip: false,
            },
        },
        capEnd: {
            pageSize: {
                type: "cap",
                value: { width: plength, height: cap_end_height },
            },
            ...common_properties,
            borderRadius: {
                type: "multiple",
                params: {
                    top: 40,
                    left: 40,
                    right: 0,
                    bottom: 0,
                    cside: "top",
                },
                value: "40px 40px 0px 0px",
            },
        },
        sideStick: {
            pageSize: {
                type: "sideStick",
                value: { width: pheight, height: stick_height },
            },
            ...common_properties,
            borderRadius: {
                type: "multiple",
                params: {
                    top: 40,
                    left: 40,
                    right: 0,
                    bottom: 0,
                    cside: "top",
                },
                value: "40px 40px 0px 0px",
            },
        },
    };

    // pages_structure format: array of [key, name, pageType, size, text, textOffset]
    const pages_structure = [
        [
            "backdropDesktop",
            "Backdrop Desktop",
            "backdrop",
            "desktop",
            "Backdrop",
            [10, 110],
        ],
        [
            "backdropMobile",
            "Backdrop Mobile",
            "backdrop",
            "mobile",
            "Backdrop",
            [10, 110],
        ],
        ["globalPage", "Global Page", "general", "default", null, [0, 0]],
        ["frontSide", "Front Side", "general", "front", "Front Side", [0, 0]],
        ["leftSize", "Left Side", "general", "side", "Left Side", [0, 0]],
        ["backSide", "Back Side", "general", "front", "Back Side", [0, 0]],
        ["rightSide", "Right Side", "general", "side", "Right Side", [0, 0]],
        ["upSide", "Up Side", "general", "top", "Up Side", [0, 0]],
        ["downSide", "Down Side", "general", "top", "Down Side", [0, 0]],
        [
            "capsTopLeft",
            "Caps Top Left",
            "general",
            "capLeft",
            "Caps Top Let",
            [0, 0],
        ],
        [
            "capsTopRight",
            "Caps Top Right",
            "general",
            "capRight",
            "Caps Top Right",
            [0, 0],
        ],
        [
            "capsBottomLeft",
            "Caps Bottom Left",
            "general",
            "capLeft",
            "Caps Bottom Left",
            [0, 0],
        ],
        [
            "capsBottomRight",
            "Caps Bottom Right",
            "general",
            "capRight",
            "Caps Bottom Right",
            [0, 0],
        ],
        [
            "capEndTop",
            "Cap End Top",
            "general",
            "capEnd",
            "Cap End Top",
            [0, 0],
        ],
        [
            "capEndBottom",
            "Cap End Bottom",
            "general",
            "capEnd",
            "Cap End Bottom",
            [0, 0],
        ],
        [
            "sideStick",
            "Side Stick",
            "general",
            "sideStick",
            "Side Stick",
            [0, 0],
        ],
    ];

    const data = getNewAbsoluteMultipage(
        "packaging_box",
        "Unnamed Packaging Box",
        info,
        pages_structure,
        "slider",
        additionalLayouts
    );
    data["info"] = info;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewPackagingBox;
