import log from "cslog";
import getNewAbsoluteMultipage from "../absolute/new";

const getNewAd = () => {
    const info = {
        type: "ad",
        layout: "absolute",
        pagination: {
            type: "none",
            fixed: [],
            floatingIndex: false,
            allowAdd: false,
            pageMultiple: 1,
        },
    };

    const pages_structure = [
        ["globalPage", "Global Page", null],
        ["firstPage", "Main Page", "Main Page"],
    ];

    const data = getNewAbsoluteMultipage(
        "ad",
        "Unnamed Ad",
        info,
        pages_structure,
        // "cardstack"
        "ad"
    );
    data["info"] = info;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewAd;
