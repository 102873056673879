import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Form } from "semantic-ui-react";
import DraggablePanel from "../commons/DraggablePanel";
import {
    // gstyleArrayState,
    themeArrayState,
    gstyleConfigState,
} from "../db/gstyleDb";
import { viewerState } from "../db/viewerDb";
import { contentOptions } from "./ContentOptions";
import styles from "./styles/Customizer.module.scss";

const ViewerCustomizer = () => {
    const [gsc, setGsc] = useRecoilState(gstyleConfigState);
    // const gs = useRecoilValue(gstyleArrayState);
    const gs = useRecoilValue(themeArrayState);
    const [viewer, setViewer] = useRecoilState(viewerState);

    const activeCol = "#fcfcfc";

    const itemStyles = {
        padding: "10px",
        textAlign: "center",
        cursor: "pointer",
        color: "black",
    };

    const setInput = (key, newValue) => {
        setViewer({
            ...viewer,
            input: {
                ...viewer.input,
                [key]: newValue,
            },
        });
    };

    return (
        <DraggablePanel>
            <div className={styles.topWrapper}>
                <div className={styles.singleTabWrapper}>
                    <div
                        style={{
                            ...itemStyles,
                            backgroundColor: activeCol,
                            color: "black",
                            fontWeight: "bold",
                            borderBottom: "5px solid royalblue",
                        }}
                    >
                        {/* <Icon name="edit" />  */}
                        Viewer Customization
                    </div>
                </div>

                <div
                    style={{
                        flex: 1,
                        maxHeight: "100%",
                        overflow: "auto",
                    }}
                >
                    <div className={styles.container}>
                        <Form className={styles.propertiesList}>
                            {Object.entries(viewer.input).map(([key, val]) => {
                                return contentOptions(
                                    key,
                                    viewer.itype[key],
                                    { val },
                                    val,
                                    setInput,
                                    viewer,
                                    setViewer,
                                    gs,
                                    null
                                );
                            })}
                        </Form>
                    </div>
                </div>
            </div>
        </DraggablePanel>
    );
};

export default ViewerCustomizer;
