import { useDragLayer } from "react-dnd";
import { ElementDragging } from "./ComponentPanel";
const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};
function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}
export const CustomDragLayer = (props) => {
    const {
        itemType,
        isDragging,
        item: itemData,
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    const item = itemData?.data || {};
    function renderItem() {
        return <ElementDragging item={item} />;
        return (
            <div
                style={{
                    border: "1px dashed gray",
                    padding: "0.5rem 1rem",
                    backgroundColor: "yellow",
                    cursor: "move",
                    width: "130px",
                    height: "80px",
                }}
            >
                {item.type}
            </div>
        );
    }
    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
};
