import log from "cslog";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Icon } from "semantic-ui-react";
import { useArchivedPhotos, usePhotos } from "../actions/assets";
import {
    useArchiveFile,
    useDeleteFile,
    useRestoreFile,
} from "../actions/mutations/assets";
import { imageLibConfigState } from "../db/editorConfig";
import { selectedImageState } from "../db/elementDb";
import { getAssetURL } from "../_helper/image";
import GalleryView from "./GalleryView";

const Trash = ({ placeholder, multiple, cam = false }) => {
    // const [data, setData] = useState();
    const [imageLibConfig, setImageLibConfig] =
        useRecoilState(imageLibConfigState);
    // const [folder, setFolder] = useState(null);
    const { isLoading, isSuccess, data } = useArchivedPhotos();
    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);
    // const mutationDeleteFile = useDeleteFile();
    const mutationRestoreFile = useRestoreFile();
    const mutationDeleteFile = useDeleteFile();
    const [enableOptions, setEnableOptions] = useState(false);

    const deleteSelectedFile = () => {
        if (selectedImage?.id) {
            mutationDeleteFile.mutate(selectedImage?.id);
        }
    };

    const restoreSelectedFile = () => {
        if (selectedImage?.id) {
            mutationRestoreFile.mutate(selectedImage?.id);
        }
    };

    log.d(data, "Photos Data");

    return (
        <div>
            {data ? (
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {/* <button>Hello</button> */}
                        {/* <Breadcrumb
                            icon="right angle"
                            sections={folder_paths}
                            size="big"
                        /> */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {/* {folder && ( */}
                            <Icon
                                name="trash alternate"
                                size="large"
                                style={{
                                    marginRight: "20px",
                                }}
                                color="blue"
                                link
                            />
                            {/* )} */}
                            <span
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Deleted Media
                            </span>
                        </div>
                        <div>
                            {enableOptions && (
                                <span
                                    style={{
                                        paddingRight: "10px",
                                    }}
                                >
                                    <Button
                                        loading={mutationRestoreFile.isLoading}
                                        color="primary"
                                        onClick={restoreSelectedFile}
                                        icon
                                        labelPosition="left"
                                    >
                                        Restore
                                        <Icon name="undo" />
                                    </Button>
                                    <Button
                                        loading={mutationDeleteFile.isLoading}
                                        color="red"
                                        onClick={deleteSelectedFile}
                                    >
                                        Delete
                                    </Button>
                                </span>
                            )}
                        </div>
                    </div>

                    {/* <Divider /> */}
                    {data.length > 0 ? (
                        <div
                            style={{
                                height: "70vh",
                                overflow: "auto",
                                marginTop: "10px",
                                // border: "2px dashed red",
                            }}
                        >
                            <GalleryView
                                data={data}
                                multiple={multiple}
                                makeThumb={(item, index) => {
                                    return {
                                        width: item.width + 0,
                                        height: item.height + 0,
                                        src: getAssetURL(item),
                                        id: item.id,
                                        dindex: index,
                                    };
                                }}
                                makeImgObj={(photo) => {
                                    return {
                                        srcType: "lib",
                                        id: photo.id,
                                        width: photo.width,
                                        height: photo.height,
                                        title: photo.title,
                                        filename_disk: photo.filename_disk,
                                        filename_download:
                                            photo.filename_download,
                                        storage: photo.storage,
                                        type: photo.type,
                                        filesize: photo.filesize,
                                        full: getAssetURL(photo),
                                    };
                                }}
                                selectedImage={selectedImage}
                                setSelectedImage={(newImg) => {
                                    setSelectedImage(newImg);
                                    setEnableOptions(true);
                                }}
                            />
                            <div
                                style={{
                                    height: "70px",
                                }}
                            ></div>
                        </div>
                    ) : (
                        <h3 style={{ color: "gray" }}>Trash is empty</h3>
                    )}
                </div>
            ) : (
                placeholder
            )}
        </div>
    );
};

export default Trash;
