import { nanoid } from "nanoid";
// import { ELEMENTS } from "../absolute/data/elements";
import { ELEMENTS } from "../../data/elements/absolute/elements";
import { GSTYLE, THEME_COLORS, THEME_FONTSET } from "../../data/gstyle";
import log from "cslog";

import { pageSizes, backdropSizes } from "../../data/extra/pageSizeData";
import { VIEWERS } from "../../data/extra/viewerData";

// pages_structure format: array of [key, name, size, text]
const getNewAbsoluteMultipage = (
    type,
    name,
    info,
    pages_structure,
    pageSizeType,
    additionalLayouts = {},
    config = {}
) => {
    const data = {};

    data["title"] = name;
    data["slug"] = `${type}_${nanoid(5)}`;
    data["type"] = type;

    data["info"] = info;

    data["viewers"] = VIEWERS[type] || VIEWERS["default"];

    if (config.id) {
        data["creation_type"] = config.id;
    }

    // const defaultPageSize = pageSizes[pageSizeType][0];
    let defaultPageSize = {
        id: `${config.ratio}`,
        value: {
            width: config.width,
            height: config.height
        }
    };

    if (!(config.width && config.height)) {
        defaultPageSize = pageSizes[pageSizeType][0];
    }


    let layout = {
        default: {
            pageSize: {
                type: defaultPageSize.id,
                value: defaultPageSize.value,
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 24,
                    bottom: 24,
                    left: 12,
                    right: 12,
                },
                value: "24px 12px 24px 12px",
            },
            background: {
                type: "simple",
                params: "white",
                value: "white",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: "3px",
            },
        },
        ...additionalLayouts,
    };

    // add backdrop layouts
    pages_structure.forEach((page) => {
        if (page[2] === "backdrop") {
            layout = {
                ...layout,
                [page[3]]: {
                    pageSize: backdropSizes[page[3]],
                    padding: {
                        type: "multiple",
                        params: {
                            cside: "top",
                            top: 24,
                            bottom: 24,
                            left: 12,
                            right: 12,
                        },
                        value: "24px 12px 24px 12px",
                    },
                    background: {
                        type: "simple",
                        params: "#9356C8",
                        value: "#9356C8",
                    },
                },
            };
        }
    });

    data["layout"] = layout;

    const gconfig = {
        colors: THEME_COLORS,
        fontSets: THEME_FONTSET,
    };

    const gstyles = {};
    Object.keys(GSTYLE).map((key) => {
        const style_data = { id: key, ...GSTYLE[key] };
        gstyles[key] = style_data;
    });

    const globalConfig = {
        defaultFontFamily: `Arial, Helvetica, sans-serif`,
        pageBackground: `#f9f6ff`,
    };

    data["gconfig"] = {
        gconfig: gconfig,
        gstyles: gstyles,
        globalConfig: globalConfig,
    };

    const htmlMapping = {};
    pages_structure.map((pg) => {
        htmlMapping[pg[0]] = createPage(pg[0], pg[2], pg[3], pg[4], pg[5]);
    });

    const pagelist = pages_structure.map((pg, i) => {
        return {
            pid: pg[0],
            id: null,
            page_no: i,
            name: pg[1],
            type: pg[2],
            size: pg[3],
        };
    });

    const pages = [];
    pagelist.forEach((page) => {
        pages.push({
            pid: page.pid,
            id: page.id,
            page_no: page.page_no,
            pageType: page.type,
            size: page.size,
            info: page,
            html: htmlMapping[page.pid],
            book_id: info.id,
        });
    });

    // data["pages"] = pages;
    data["data"] = {
        page_array: pagelist,
        pages: pages,
    };

    // data["globalConfig"] = globalConfig;
    data["others"] = {};

    const seo = {
        title: "",
        description: "",
        previewImage: {
            params: null,
            value: "/img_pp.png",
        },
        favicon: process.env.PUBLIC_URL + "img_pp.png",
        ogTitle: "",
        ogImage:
            "https://images.unsplash.com/photo-1565118531796-763e5082d113?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTIyMTV8MHwxfHNlYXJjaHw1fHx8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080",
    };
    data["seo"] = seo;

    log.d(data, "CREATED DATA");
    return data;
};

function createPage(page_id, pageType, size, text, textOffset = [0, 0]) {
    const bookpage_id = page_id;
    const freebox_id = "q_" + nanoid(10);

    const out = {};

    //==========

    let free_box_child = {};
    if (text) {
        const header_id = "q_" + nanoid(10);
        const header_data = {
            ...ELEMENTS["header"],
            id: header_id,
            pid: freebox_id,
            input: {
                ...ELEMENTS["header"].input,
                content: text,
            },
        };
        // addElement(header_id, header_data);
        out[header_id] = header_data;
        free_box_child = {
            [header_id]: {
                id: header_id,
                width: header_data.dims.w + 50,
                height: header_data.dims.h,
                translate: textOffset,
                rotate: 0,
            },
        };
    }
    //==========

    const freebox_data = {
        ...ELEMENTS["freeBox"],
        id: freebox_id,
        pid: bookpage_id,
        childs: free_box_child,
    };
    // addElement(freebox_id, freebox_data);
    out[freebox_id] = freebox_data;

    const bg_id = "q_" + nanoid(10);
    const bg_data = {
        ...ELEMENTS["freeBox"],
        id: bg_id,
        pid: bookpage_id,
        childs: {},
    };
    // addElement(bg_id, bg_data);
    out[bg_id] = bg_data;

    const bookpage_data = {
        ...ELEMENTS["bookPage"],
        id: bookpage_id,
        pageType: pageType,
        size: size,
        childs: [freebox_id],
        bg: [bg_id],
    };
    // addElement(bookpage_id, bookpage_data);
    out[bookpage_id] = bookpage_data;

    return out;
}

export default getNewAbsoluteMultipage;
