/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useState } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import Webcam from "react-webcam";
import log from "cslog";

function CameraDialog({ open, setOpen, onPhoto }) {
    const webcamRef = React.useRef();
    const [photo, setPhoto] = useState(null);
    const [config, setConfig] = useState({
        mirror: true,
    });

    const width = 500;
    const height = 400;

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const capturePhoto = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setPhoto(imageSrc);
        log.d(imageSrc, "Captured");
        log.d(dataURItoBlob(imageSrc), "Captured");
    }, [webcamRef]);

    return (
        <Modal size="tiny" open={open} onClose={() => setOpen(false)}>
            {/* <Modal.Header style={{ textAlign: "center" }}>
                Smile Please
            </Modal.Header> */}
            <Modal.Content
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                `}
            >
                <h3
                    style={{
                        textAlign: "center",
                        margin: 0,
                        padding: 0,
                        paddingBottom: "10px",
                    }}
                >
                    Smile Please
                </h3>
                {photo ? (
                    <img
                        src={photo}
                        style={{ width: width, height: height }}
                        alt="captured"
                    />
                ) : (
                    <Webcam
                        audio={false}
                        height={height}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={width}
                        mirrored={config.mirror}
                        videoConstraints={{
                            width: width,
                            height: height,
                            facingMode: "user",
                        }}
                        css={css`
                            border-radius: 10px;
                        `}
                    />
                )}
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                    {photo ? (
                        <Fragment>
                            <Button
                                onClick={() => setPhoto(null)}
                                color="black"
                                icon
                                labelPosition="left"
                            >
                                <Icon name="undo" />
                                Retake
                            </Button>
                            <Button
                                onClick={() => {
                                    const blob = dataURItoBlob(photo);
                                    onPhoto(blob);
                                    setOpen(false);
                                }}
                                primary
                                icon
                                labelPosition="right"
                            >
                                <Icon name="arrow right" />
                                Proceed
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Button icon onClick={() => setOpen(false)}>
                                <Icon name="close" />
                            </Button>
                            <Button
                                icon
                                onClick={() =>
                                    setConfig({
                                        ...config,
                                        mirror: !config.mirror,
                                    })
                                }
                            >
                                <Icon name="exchange" />
                            </Button>
                            <Button
                                onClick={capturePhoto}
                                primary
                                icon
                                labelPosition="left"
                            >
                                <Icon name="camera" />
                                Capture
                            </Button>
                        </Fragment>
                    )}
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default CameraDialog;
