import { atom, atomFamily, useRecoilState } from "recoil";
import log from "cslog";

// export const elementArray = atom({
// 	key: "elementArray",
// 	default: {},
// });

export const globalVariablesState = atom({
    key: "global_variables",
    default: {},
});

export const globalVariablesConfigState = atom({
    key: "global_variables_config",
    default: {},
});

export const useGlobalVariable = (var_name, default_val) => {
    const [gv, setGV] = useRecoilState(globalVariablesState);

    return [
        gv[var_name] || default_val,
        (newVal) =>
            setGV({
                ...gv,
                [var_name]: newVal,
            }),
    ];
};

export const globalDataState = atom({
    key: "global_data",
    default: {},
});

export const dataArray = {};

export const dataPageState = (id, data = null) => {
    if (!id) {
        id = "NULL";
    }
    if (id in dataArray) {
        return dataArray[id];
    }
    dataArray[id] = atom({
        key: `data_${id}`,
        default: data ? data : {},
    });
    return dataArray[id];
};

export const addPageData = (id, initialData) => {
    if (id in dataArray) {
        return;
    }
    dataArray[id] = atom({
        key: `data_${id}`,
        default: initialData,
    });
};

export const addPageDataForcefully = (id, initialData) => {
    dataArray[id] = atom({
        key: id,
        default: initialData,
    });
};

// here default takes {[id]: defalt_value}, means all family atoms are stored with id as key and value as value
// export const filtersAtomFamily = atomFamily({
//     key: "filtersAtomFamily",
//     default: {},
// });

// export const filterPageState = (page_id) => filtersAtomFamily(page_id);

export const selectedDataBlockState = atom({
    key: "selected_datablock",
    default: {
        dbid: null,
        id: null,
    },
});

//db_params
export const globalDbConfig = atom({
    key: "global_db_configs",
    default: {},
});

export const usePageDbConfig = (page_id) => {
    const [dbp, setDbp] = useRecoilState(globalDbConfig);

    return [
        dbp[page_id] || {},
        (newVal) =>
            setDbp((old_dbp) => ({
                ...old_dbp,
                [page_id]: newVal,
            })),
    ];
};

// //search

// export const globalSearchState = atom({
//     key: "global_search",
//     default: {},
// });

// export const usePageSearchState = (page_id) => {
//     const [gsearch, setGSearch] = useRecoilState(globalSearchState);

//     return [
//         gsearch[page_id] || {},
//         (newVal) =>
//             setGSearch((old_gsearch) => ({
//                 ...old_gsearch,
//                 [page_id]: newVal,
//             })),
//     ];
// };
