import React, { Fragment } from "react";
import DocumentDevice from "./commons/DocumentDevice";
import log from "cslog"

function DocumentViewer({ data }) {

	log.d(data, "data in DocumentViewer");

	const layout = data.layout?.["default"]

	const page = data.data.pages[0];
	log.d(page, "Page in DocumentViewer")


	const creation_id = data.creation; //only availabe in rendering (show)

	const viewer = null;
	const vi = viewer || data.viewers.input;


	const ctx = {};

	const query_params = ctx?.query_params || {};
	const is_preview = Object.keys(query_params).includes("preview");


	return (
		<DocumentDevice layout={layout}
			creation_id={creation_id}
			vi={vi}
			ctx={ctx}
			is_preview={is_preview}
			page_content={page.content}
		>
			<h3>Preview Document Render</h3>
			<p>coming soon...</p>
		</DocumentDevice>
	);
}

export default DocumentViewer;
