import log from "cslog";
import { nanoid } from "nanoid";
import { backdropSizes } from "../../data/extra/pageSizeData";
import getNewAbsoluteMultipage from "../absolute/new";

const getNewSlider = () => {
    const info = {
        type: "card",
        layout: "absolute",
        pagination: {
            type: "numbered",
            fixed: ["BD", "BM", "G", "F", "B"],
            floatingIndex: true,
            allowAdd: false,
            pageMultiple: 1,
        },
        startPageId: "frontSide",
    };

    const additionalLayouts = {};

    // pages_structure format: array of [key, name, pageType, size, text, textOffset]
    const pages_structure = [
        [
            "backdropDesktop",
            "Backdrop Desktop",
            "backdrop",
            "desktop",
            "Desktop Backdrop",
            [100, 110],
        ],
        [
            "backdropMobile",
            "Backdrop Mobile",
            "backdrop",
            "mobile",
            "Mobile Backdrop",
            [10, 110],
        ],
        ["globalPage", "Global Page", "general", "default", null, [0, 0]],
        ["frontSide", "Front Side", "general", "default", "Front Side", [0, 0]],
        ["backSide", "Back Side", "general", "default", "Back Side", [0, 0]],
    ];

    const data = getNewAbsoluteMultipage(
        "card",
        "Unnamed Card",
        info,
        pages_structure,
        "card",
        additionalLayouts
    );
    data["info"] = info;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewSlider;
