import log from "cslog";
import { nanoid } from "nanoid";
import { backdropSizes } from "../../data/extra/pageSizeData";
import getNewAbsoluteMultipage from "../absolute/new";

const getNewSinglePage = (config) => {
    const info = {
        type: "singlepage", //for not to break existing logics
        typebase: "singlepage",
        layout: "absolute",
        ratio: config.ratio,
        pagination: {
            type: "none",
            fixed: ["F"],
            floatingIndex: false,
            allowAdd: false,
            pageMultiple: 1,
        },
        startPageId: "frontSide",
    };

    const additionalLayouts = {};

    // pages_structure format: array of [key, name, pageType, size, text, textOffset]
    const pages_structure = [
        ["frontSide", "Front Side", "general", "default", "Front Side", [0, 0]],
    ];

    const data = getNewAbsoluteMultipage(
        "singlepage",
        // "Unnamed Single Page",
        `Unnamed ${config.name}`,
        info,
        pages_structure,
        "singlepage",
        additionalLayouts,
        config
    );
    data["info"] = info;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewSinglePage;
