import React from 'react'
import Handlebars from 'handlebars'

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}

	if (leaf.code) {
		children = <code>{children}</code>
	}

	if (leaf.italic) {
		children = <em>{children}</em>
	}

	if (leaf.underline) {
		children = <u>{children}</u>
	}

	if (leaf.strikethrough) {
		children = <strike>{children}</strike>
	}

	if (leaf.color) {
		children = <span style={{ color: leaf.color }}>{children}</span>
	}

	if (leaf.bgColor) {
		children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
	}

	return <span {...attributes}>{children}</span>
}

export default Leaf
