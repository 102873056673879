import {
	parseStyle,
	decodeStyle,
	decodeAttribute,
} from "../parser/parserAbsolute";

import { mobile, tablet, isHidden } from "../data/bp";

import { PAGE_BEFORE_ID } from "../data/defaults";

import { getFileURL, parseImgURL } from "../_helper/image";

import {
	parseImageSrc,
	parseImageArray,
	getContent,
	getRecordId,
	getTableContent,
	getHTMLContent,
} from "../dynamic/parser";
import { getContentObject } from "../dynamic/parser";


import { checkConditions } from "../dynamic/utils";

import { useContent } from "../dynamic/apis/query";

import { SlateViewer } from "./juntion";

import { resolveClipPath } from "../_helper/image";
import { atom } from "jotai";

import { useExport } from "../commons/export/use_export";

import { parseDynamicSocialIcon } from "../_helper/render/social_icon";

import { DEFAULT_PADDING_CORRECTION_IN_MARGIN, INCH_TO_PIXEL } from "../constants";

const FillablePanel = () => { };

const fillableAllState = atom({});

const iCtxState = atom({});

const ExportPanel = () => <p>Export Panel</p>


const ShowBar = ({ creation_id, dims, protected_access, page_content }) => {

	if (!page_content || Object.keys(page_content).length === 0) {
		return <ExportPanel
			width={dims.width}
			height={dims.height} />
	}
	return <FillablePanel
		content={page_content}
		wrapper_styles={{}}
		dims={dims}
		protected_access={protected_access}
		creation_id={creation_id}
	/>
}

const QIFrameComm = () => <></>


export {
	parseStyle,
	decodeStyle,
	decodeAttribute,
	SlateViewer,
	mobile,
	tablet,
	isHidden,
	PAGE_BEFORE_ID,
	getFileURL,
	parseImgURL,
	getContent,
	getHTMLContent,
	useContent,
	resolveClipPath,
	parseImageSrc,
	parseImageArray,
	FillablePanel,
	checkConditions,
	getRecordId,
	getTableContent,
	fillableAllState,
	ExportPanel,
	useExport,
	ShowBar,
	QIFrameComm,
	iCtxState,
	getContentObject,
	parseDynamicSocialIcon,
	DEFAULT_PADDING_CORRECTION_IN_MARGIN,
	INCH_TO_PIXEL
};
