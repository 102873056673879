/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React, { createRef, useLayoutEffect } from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

// TODO: work on responsive style
function QIcon({ id, db, gs }) {
    const data = db[id];
    const iconRef = createRef();

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    let borderRadius = "10000px";
    if (input.frameShape.type === "square") {
        borderRadius = "0px";
    } else if (input.frameShape.type === "custom") {
        borderRadius = input.frameShape.value;
    }

    useLayoutEffect(() => {
        iconRef.current.addEventListener("resize", () => {
            const ww = iconRef.current.clientWidth;
            log.d(ww, "New Width");
        });
        // setData({
        //     ...data,
        //     input: {
        //         ...data.input,
        //         iconSize: {
        //             type: "custom",
        //             params: ww,
        //             value: `${ww}px`,
        //         },
        //     },
        // });
    }, []);

    return (
        <ElementWrapper id={id}>
            <div
                ref={iconRef}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <span
                    className={input.hoverAnimation}
                    css={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        "&:hover": decodeStyle(ps.hover, gs),
                        // alignSelf: input.alignImage,
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        fontSize: input.iconSize.value,
                        // fontSize: "30px",
                        // color: input.frameType === "solid" ? "white" : "black",
                        // color: ps.style.color,
                        backgroundColor:
                            input.frameType === "solid"
                                ? ps.style.backgroundColor
                                : "transparent",
                        border:
                            input.frameType === "frame"
                                ? `3px solid ${ps.style.color}`
                                : "none",
                        // padding: input.iconSize.params * 0.7,
                        // width: input.iconSize.params * input.iconGap,
                        // height: input.iconSize.params * input.iconGap,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: borderRadius,
                        // alignSelf: "flex-start",
                    }}
                >
                    <i className={input.iconSource.value}></i>
                </span>
            </div>
        </ElementWrapper>
    );
}

export default QIcon;
