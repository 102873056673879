/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import { decodeStyle, getContent, parseStyle, getContentObject, parseDynamicSocialIcon } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";
// import { getContentObject } from "../../../../dynamic/parser";
// import { parseDynamicSocialIcon } from "../../../../_helper/render/social_icon";

function IconList({ id, db, gs, dydb, ctx }) {
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);


	const styleIcon = decodeStyle(ps.styleGroup.icon, gs);
	const styleText = decodeStyle(ps.styleGroup.text, gs);
	// const ps = data;

	log.d(ps, "IconList data ps");

	let input = ps.input;

	let borderRadius = "10000px";
	if (input.frameShape.type === "square") {
		borderRadius = "0px";
	} else if (input.frameShape.type === "custom") {
		borderRadius = input.frameShape.value;
	}

	const type = input.socialItems?.type || "static"
	let items = Array.isArray(input.socialItems) ? input.socialItems : input.socialItems?.type === "static" ? input.socialItems?.data : []


	if (type === "dynamic") {
		const content = getContentObject(dydb, input.socialItems?.data?.source)
		items = parseDynamicSocialIcon(content);
	}

	return (
		<ElementWrapper id={id}>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: input.orientation === "h" ? "row" : "column",
					// padding: "15px",
					justifyContent: input.alignItems,
					alignItems: input.alignItems,
					gap: `${input.itemGap - 0.5}rem`,
					flexWrap: "wrap"
				}}
			>
				{items?.map((item) => {
					return (
						<span
							css={{
								...styleIcon,
								fontSize: input.iconSize.value,
								color: item.color || styleIcon.color,
								backgroundColor:
									input.frameType === "solid"
										? styleIcon.backgroundColor
										: "transparent",
								border:
									input.frameType === "frame"
										? `2px solid ${styleIcon.color}`
										: "none",
								borderRadius: borderRadius,
								// width: input.orientation === "h" ? "auto" : input.iconSize.params * input.iconGap,
								width: "auto",
								height: input.iconSize.params * input.iconGap,
								display: "flex",
								// justifyContent: "center",
								justifyContent: "flex-start",
								alignItems: "center",
								// alignItems: "flex-start",
								cursor: "pointer",
								gap: "0.3em",
								padding: `${input.iconGap}rem`,
							}}
						>
							<i className={item.icon?.value}></i>
							{input.showText && <p style={{
								color: "black",
								fontSize: "0.8em",
								whiteSpace: "nowrap",
								...styleText
							}}>
								{/* {item.text} */}
								{getContent(dydb, item.text || "")}
							</p>}
						</span>
					);
				})}
			</div>
		</ElementWrapper>
	);
}

export default IconList;
