import React from "react";
// import ReactDOM from "react-dom";
// import styled from "styled-components";
import { css, cx } from "@emotion/css";
import { Portal } from "semantic-ui-react";

export const Button = React.forwardRef(
    ({ className, active, reversed, ...props }, ref) => (
        <span
            {...props}
            ref={ref}
            className={className}
            style={{
                color: reversed
                    ? active
                        ? "white"
                        : "#aaa"
                    : active
                    ? "black"
                    : "#ccc",
                cursor: "pointer",
            }}
        />
    )
);

export const EditorValue = React.forwardRef(
    ({ className, value, ...props }, ref) => {
        const textLines = value.document.nodes
            .map((node) => node.text)
            .toArray()
            .join("\n");
        return (
            <div
                ref={ref}
                {...props}
                className={className}
                style={{ margin: "30px -20px 0" }}
            >
                <div
                    style={{
                        fontSize: "14px",
                        padding: "5px 20px",
                        color: "#404040",
                        borderTop: "2px solid #eeeeee",
                        background: "#f8f8f8",
                    }}
                >
                    Slate's value as text
                </div>
                <div
                    className={{
                        color: "#404040",
                        font: "12px monospace",
                        whiteSpace: "pre-wrap",
                        padding: "10px 20px",
                    }}
                >
                    {textLines}
                </div>
            </div>
        );
    }
);

// export const Icon = React.forwardRef(({ className, ...props }, ref) => (
//     <span
//         {...props}
//         ref={ref}
//         className={className}
//         style={{
//             fontSize: "18px",
//             verticalAlign: "text-bottom",
//         }}
//     />
// ));

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
    <span
        {...props}
        ref={ref}
        className={cx(
            "material-icons",
            className,
            css`
                // font-size: 18px;
                font-size: 16px;
                vertical-align: text-bottom;
            `
        )}
    >
        <i class={props.children}></i>
    </span>
));

// export const Portal = ({ children }) => {
//     return ReactDOM.createPortal(children, document.body);
// };

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
    <div
        {...props}
        className={className}
        style={{
            position: "fixed",
            top: 0,
            background: "white",

            zIndex: 10,
            borderBottom: "2px solid #eee",
            marginBottom: "20px",
            padding: "10px",
            paddingTop: "15px",

            display: "flex",
            flexDirection: "row",
            gap: "20px",
        }}
    />
));

export const FloatingToolbar = React.forwardRef(
    ({ className, ...props }, ref) => (
        <Portal open={true}>
            <div
                {...props}
                className={className}
                style={{
                    position: "fixed",
                    top: 40,
                    left: "50%",
                    // transform: "translateX(-50%)",
                    transform: "translateX(calc(-50% + 170px))",
                    background: "white",

                    zIndex: 10,
                    borderBottom: "2px solid #eee",
                    marginBottom: "20px",
                    // padding: "10px",
                    padding: "10px 20px",
                    paddingTop: "15px",

                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    borderRadius: "15px",
                }}
            />
        </Portal>
    )
);
