export const GFONTS = {
	"sans-serif": [
		"Roboto",
		"Open Sans",
		"Noto Sans JP",
		"Montserrat",
		"Poppins",
		"Lato",
		"Inter",
		"Roboto Condensed",
		"Oswald",
		"Noto Sans",
		"Raleway",
		"Nunito Sans",
		"Nunito",
		"Ubuntu",
		"Rubik",
		"Noto Sans KR",
		"PT Sans",
		"Kanit",
		"Noto Sans TC",
		"Work Sans",
		"Fira Sans",
		"Mulish",
		"Manrope",
		"DM Sans",
		"Quicksand",
		"Barlow",
		"IBM Plex Sans",
		"Titillium Web",
		"Heebo",
		"Space Grotesk",
		"Karla",
		"Bebas Neue",
		"Nanum Gothic",
		"Noto Color Emoji",
		"Libre Franklin",
		"Josefin Sans",
		"Hind Siliguri",
		"Mukta",
		"Arimo",
		"Dosis",
		"Jost",
		"Cabin",
		"Outfit",
		"Barlow Condensed",
		"Anton",
		"PT Sans Narrow",
		"Abel",
		"Fira Sans Condensed",
		"Archivo",
		"Exo 2",
		"Oxygen",
		"Cairo",
		"Hind",
		"Noto Sans Arabic",
		"Figtree",
		"Noto Sans SC",
		"Source Sans 3",
		"Plus Jakarta Sans",
		"IBM Plex Sans Arabic",
		"Public Sans",
		"Teko",
		"Assistant",
		"Red Hat Display",
		"Prompt",
		"Fjalla One",
		"Lexend",
		"M PLUS Rounded 1c",
		"Signika Negative",
		"Chakra Petch",
		"Archivo Black",
		"Overpass",
		"Varela Round",
		"Maven Pro",
		"Rajdhani",
		"Asap",
		"Schibsted Grotesk",
		"Sora",
		"Barlow Semi Condensed",
		"Roboto Flex",
		"Merriweather Sans",
		"Play",
		"Tajawal",
		"M PLUS 1p",
		"Questrial",
		"Catamaran",
		"Almarai",
		"Urbanist",
		"Sarabun",
		"Archivo Narrow",
		"Signika",
		"Noto Sans Thai",
		"Noto Sans HK",
		"Noto Kufi Arabic",
		"Ubuntu Condensed",
		"Exo",
		"Acme",
		"Be Vietnam Pro",
		"Montserrat Alternates",
		"Lexend Deca",
		"Inter Tight",
		"ABeeZee",
		"Encode Sans",
		"Orbitron",
		"Alegreya Sans",
		"Russo One",
		"Chivo",
		"Yanone Kaffeesatz",
		"Hind Madurai",
		"Rubik Mono One",
		"Noto Sans Display",
		"Saira Condensed",
		"Changa",
		"Readex Pro",
		"Saira",
		"Gothic A1",
		"PT Sans Caption",
		"League Spartan",
		"Didact Gothic",
		"Francois One",
		"Paytone One",
		"Gruppo",
		"Cantarell",
		"Albert Sans",
		"Alata",
		"Asap Condensed",
		"Zen Kaku Gothic New",
		"Philosopher",
		"Yantramanav",
		"Red Hat Text",
		"News Cycle",
		"Sawarabi Gothic",
		"IBM Plex Sans Condensed",
		"Khand",
		"Epilogue",
		"Syne",
		"Atkinson Hyperlegible",
		"Geologica",
		"Anek Devanagari",
		"Commissioner",
		"Quattrocento Sans",
		"Noto Sans Mono",
		"Tenor Sans",
		"Pathway Gothic One",
		"Cuprum",
		"Advent Pro",
		"Encode Sans Condensed",
		"Kumbh Sans",
		"Zen Maru Gothic",
		"Sen",
		"El Messiri",
		"Kosugi Maru",
		"Mitr",
		"Jura",
		"Mukta Malar",
		"Viga",
		"Noto Sans Bengali",
		"Bai Jamjuree",
		"Fira Sans Extra Condensed",
		"Amaranth",
		"Blinker",
		"Hind Vadodara",
		"Noto Sans Tamil",
		"Ropa Sans",
		"Ruda",
		"Saira Semi Condensed",
		"Istok Web",
		"Unbounded",
		"Fredoka",
		"Arsenal",
		"Radio Canada",
		"Economica",
		"Gudea",
		"Hammersmith One",
		"Hanken Grotesk",
		"League Gothic",
		"BIZ UDPGothic",
		"Krub",
		"Andika",
		"Mada",
		"Onest",
		"Alexandria",
		"Antonio",
		"Cabin Condensed",
		"Sofia Sans",
		"Quantico",
		"Anuphan",
		"Secular One",
		"Jaldi",
		"Varela",
		"Sofia Sans Condensed",
		"Basic",
		"Reem Kufi",
		"Monda",
		"Julius Sans One",
		"Actor",
		"Six Caps",
		"Martel Sans",
		"Sarala",
		"Noto Sans Malayalam",
		"Darker Grotesque",
		"Niramit",
		"Alegreya Sans SC",
		"Pontano Sans",
		"Tomorrow",
		"Ramabhadra",
		"Pragati Narrow",
		"BenchNine",
		"Instrument Sans",
		"Telex",
		"Noto Sans Hebrew",
		"Khula",
		"Sansita",
		"Alef",
		"Wix Madefor Text",
		"Golos Text",
		"Aclonica",
		"Black Han Sans",
		"Mandali",
		"Laila",
		"Hind Guntur",
		"Sintony",
		"IBM Plex Sans Thai",
		"Bricolage Grotesque",
		"Electrolize",
		"Vazirmatn",
		"Syncopate",
		"Palanquin",
		"Belleza",
		"Noto Sans Devanagari",
		"Voltaire",
		"M PLUS 1",
		"Days One",
		"Athiti",
		"Allerta Stencil",
		"Armata",
		"Michroma",
		"Scada",
		"Akshar",
		"Do Hyeon",
		"Alatsi",
		"Jua",
		"Saira Extra Condensed",
		"Antic",
		"Livvic",
		"Sofia Sans Extra Condensed",
		"Candal",
		"Nobile",
		"Palanquin Dark",
		"Arya",
		"Spinnaker",
		"Krona One",
		"Proza Libre",
		"Georama",
		"Pattaya",
		"Aldrich",
		"Alumni Sans",
		"K2D",
		"Anek Malayalam",
		"Allerta",
		"Marmelad",
		"Metrophobic",
		"Share",
		"Biryani",
		"Pathway Extreme",
		"Kosugi",
		"Average Sans",
		"Encode Sans Expanded",
		"Fahkwang",
		"Zen Kaku Gothic Antique",
		"Rambla",
		"Glory",
		"Montserrat Subrayada",
		"Rosario",
		"B612",
		"Lexend Exa",
		"Encode Sans Semi Condensed",
		"Amiko",
		"IBM Plex Sans KR",
		"Manjari",
		"Miriam Libre",
		"BIZ UDGothic",
		"M PLUS 2",
		"Magra",
		"Carrois Gothic",
		"Mallanna",
		"Zen Kurenaido",
		"Gayathri",
		"Sofia Sans Semi Condensed",
		"RocknRoll One",
		"Thasadith",
		"Dongle",
		"Fresca",
		"DotGothic16",
		"Yusei Magic",
		"Murecho",
		"Spline Sans",
		"Lexend Zetta",
		"Capriola",
		"Sunflower",
		"Cambay",
		"Marvel",
		"Geo",
		"KoHo",
		"Molengo",
		"Federo",
		"Wix Madefor Display",
		"Mochiy Pop One",
		"REM",
		"Rethink Sans",
		"Noto Sans Meetei Mayek",
		"Noto Sans Lao Looped",
		"Jockey One",
		"Noto Sans Sinhala",
		"Gantari",
		"Mukta Mahee",
		"Mukta Vaani",
		"Inder",
		"Noto Emoji",
		"Gotu",
		"ZCOOL XiaoWei",
		"IBM Plex Sans JP",
		"Anek Latin",
		"Mouse Memoirs",
		"Familjen Grotesk",
		"Noto Sans NKo Unjoined",
		"Harmattan",
		"Recursive",
		"Notable",
		"Farro",
		"Asul",
		"Share Tech",
		"Padauk",
		"Kodchasan",
		"NTR",
		"Noto Sans Georgian",
		"Encode Sans Semi Expanded",
		"Mingzat",
		"Puritan",
		"Inria Sans",
		"Tenali Ramakrishna",
		"Anaheim",
		"Numans",
		"Noto Sans Symbols",
		"Nokora",
		"Mako",
		"Duru Sans",
		"ZCOOL QingKe HuangYou",
		"Imprima",
		"Timmana",
		"Carme",
		"New Amsterdam",
		"Reddit Sans Condensed",
		"Noto Sans Khmer",
		"Bubbler One",
		"Noto Sans Myanmar",
		"Sarpanch",
		"Bayon",
		"Kufam",
		"Gowun Dodum",
		"SUSE",
		"Monomaniac One",
		"Wendy One",
		"Sulphur Point",
		"Anek Bangla",
		"Shanti",
		"Doppio One",
		"Orienta",
		"Anek Telugu",
		"Stick",
		"Wire One",
		"Kantumruy Pro",
		"Carlito",
		"Chau Philomene One",
		"Voces",
		"Lexend Peta",
		"Convergence",
		"Strait",
		"Homenaje",
		"Afacad",
		"IBM Plex Sans Hebrew",
		"Anek Tamil",
		"Varta",
		"Lexend Mega",
		"Kdam Thmor Pro",
		"Tauri",
		"Pavanam",
		"Lexend Giga",
		"Rationale",
		"Noto Sans Armenian",
		"Noto Sans Telugu",
		"Chocolate Classical Sans",
		"Ubuntu Sans",
		"ZCOOL KuaiLe",
		"Noto Sans Kannada",
		"Mochiy Pop P One",
		"Rum Raisin",
		"Shippori Antique",
		"Noto Sans Anatolian Hieroglyphs",
		"Ysabeau Office",
		"Noto Sans Thai Looped",
		"Carrois Gothic SC",
		"Noto Sans Buhid",
		"Denk One",
		"Reddit Sans",
		"Mina",
		"Gafata",
		"Stylish",
		"AR One Sans",
		"Noto Sans Gujarati",
		"Noto Sans Ethiopic",
		"Khmer",
		"Belanosima",
		"Genos",
		"Seymour One",
		"Port Lligat Sans",
		"Radio Canada Big",
		"Kulim Park",
		"Mooli",
		"Kite One",
		"Noto Sans Lao",
		"Shippori Antique B1",
		"Mohave",
		"Stick No Bills",
		"Cantora One",
		"Noto Sans Glagolitic",
		"Meera Inimai",
		"Gemunu Libre",
		"Zain",
		"Ysabeau SC",
		"Ruluko",
		"Cagliostro",
		"Madimi One",
		"Trispace",
		"Text Me One",
		"Yaldevi",
		"Dorsa",
		"Tac One",
		"Noto Sans Math",
		"Anek Gujarati",
		"Gasoek One",
		"IBM Plex Sans Thai Looped",
		"Galdeano",
		"Noto Sans Symbols 2",
		"Noto Sans Gothic",
		"Noto Sans Tai Viet",
		"Englebert",
		"Lexend Tera",
		"Sono",
		"Braah One",
		"Akatab",
		"Agdasima",
		"Finlandica",
		"Smooch Sans",
		"Dhurjati",
		"Cairo Play",
		"Anta",
		"Noto Sans Hanunoo",
		"GFS Neohellenic",
		"Noto Sans Oriya",
		"Anek Gurmukhi",
		"Encode Sans SC",
		"Truculenta",
		"Anton SC",
		"Hubballi",
		"Moderustic",
		"Chathura",
		"Ysabeau",
		"Snippet",
		"Ysabeau Infant",
		"Reem Kufi Ink",
		"Qahiri",
		"IBM Plex Sans Devanagari",
		"Noto Sans Samaritan",
		"Anek Kannada",
		"Preahvihear",
		"Siemreap",
		"Matemasie",
		"Gidugu",
		"Inclusive Sans",
		"Teachers",
		"Noto Sans Tangsa",
		"Arsenal SC",
		"M PLUS Code Latin",
		"Reem Kufi Fun",
		"Alumni Sans Pinstripe",
		"Noto Sans Gurmukhi",
		"Tiny5",
		"Comme",
		"Sankofa Display",
		"Anek Odia",
		"Jaro",
		"Beiruti",
		"Alumni Sans Collegiate One",
		"Noto Music",
		"Fustat",
		"Noto Sans Adlam",
		"Hedvig Letters Sans",
		"Noto Sans Javanese",
		"Nuosu SIL",
		"Orbit",
		"Moulpali",
		"Noto Sans Coptic",
		"Lunasima",
		"Tsukimi Rounded",
		"Noto Sans Warang Citi",
		"Noto Sans Sora Sompeng",
		"Noto Sans Kaithi",
		"Noto Sans Osmanya",
		"Noto Sans Tamil Supplement",
		"Noto Sans Egyptian Hieroglyphs",
		"Noto Sans Syloti Nagri",
		"Noto Sans Tagalog",
		"Noto Sans Mongolian",
		"Noto Sans Cherokee",
		"Noto Traditional Nushu",
		"Noto Sans Carian",
		"Noto Sans Tifinagh",
		"Noto Sans Old Italic",
		"Noto Sans Cypro Minoan",
		"Noto Sans Adlam Unjoined",
		"Noto Sans Miao",
		"Noto Sans Canadian Aboriginal",
		"Noto Sans Thaana",
		"Noto Sans Old Hungarian",
		"Noto Sans Sharada",
		"Noto Sans Batak",
		"Noto Sans Balinese",
		"Noto Sans Deseret",
		"Noto Sans Chorasmian",
		"Noto Sans Old Permic",
		"Ojuju",
		"Noto Sans Imperial Aramaic",
		"Noto Sans Khojki",
		"Noto Sans Marchen",
		"Noto Sans Avestan",
		"Noto Sans Bamum",
		"Noto Sans Nandinagari",
		"Noto Sans Linear A",
		"Noto Sans Nag Mundari",
		"Noto Sans Syriac Eastern",
		"Noto Sans Lycian",
		"Noto Sans Vai",
		"Noto Sans New Tai Lue",
		"Noto Sans Vithkuqi",
		"Noto Sans Cham",
		"Noto Sans Tagbanwa",
		"Noto Sans Yi",
		"Noto Sans Cuneiform",
		"Noto Sans Osage",
		"Noto Sans Tai Tham",
		"Noto Sans Lydian",
		"Noto Sans Old Persian",
		"Noto Sans Masaram Gondi",
		"Noto Sans Sundanese",
		"Noto Sans Inscriptional Pahlavi",
		"Noto Sans Old Turkic",
		"Noto Sans Old North Arabian",
		"Noto Sans Multani",
		"Noto Sans Brahmi",
		"Noto Sans Tai Le",
		"Noto Sans Indic Siyaq Numbers",
		"Noto Sans Kawi",
		"Noto Sans Ol Chiki",
		"Noto Sans Bassa Vah",
		"Noto Sans NKo",
		"Noto Sans Palmyrene",
		"Noto Sans Siddham",
		"Noto Sans Inscriptional Parthian",
		"Noto Sans Chakma",
		"Noto Sans Medefaidrin",
		"Noto Sans Psalter Pahlavi",
		"Noto Sans Mro",
		"Noto Sans Takri",
		"Noto Sans Lisu",
		"Noto Sans Rejang",
		"Noto Sans Zanabazar Square",
		"Noto Sans Runic",
		"Noto Sans Duployan",
		"Noto Sans Grantha",
		"Noto Sans Cypriot",
		"Noto Sans Sogdian",
		"Noto Sans Hanifi Rohingya",
		"Noto Sans Wancho",
		"Noto Sans Limbu",
		"Noto Sans Kayah Li",
		"Noto Sans Mayan Numerals",
		"Noto Sans Modi",
		"Noto Sans Pahawh Hmong",
		"Noto Sans Ogham",
		"Noto Sans Phags Pa",
		"Noto Sans Lepcha",
		"Noto Sans Newa",
		"Noto Sans Syriac",
		"Noto Sans Linear B",
		"Noto Sans Old South Arabian",
		"Noto Sans Mandaic",
		"Noto Sans Nabataean",
		"Noto Sans Caucasian Albanian",
		"Noto Sans Buginese",
		"Noto Sans Mahajani",
		"Noto Sans Gunjala Gondi",
		"Noto Sans Elymaic",
		"Noto Sans Saurashtra",
		"Noto Sans Kharoshthi",
		"Noto Znamenny Musical Notation",
		"Noto Sans Soyombo",
		"Noto Sans Phoenician",
		"Noto Sans Old Sogdian",
		"Noto Sans Khudawadi",
		"Noto Sans Meroitic",
		"Noto Sans Shavian",
		"Noto Sans Manichaean",
		"Noto Sans Hatran",
		"Noto Sans Tirhuta",
		"Noto Sans Pau Cin Hau",
		"Noto Sans SignWriting",
		"Noto Sans Elbasan",
		"Noto Sans Bhaiksuki",
		"Noto Sans Nushu",
		"Noto Sans Ugaritic",
		"Noto Sans Mende Kikakui"
	],
	"monospace": [
		"Material Icons",
		"Roboto Mono",
		"Inconsolata",
		"Material Symbols Outlined",
		"Material Icons Outlined",
		"Material Symbols Rounded",
		"Source Code Pro",
		"Material Icons Round",
		"Material Icons Sharp",
		"IBM Plex Mono",
		"Material Icons Two Tone",
		"Ubuntu Mono",
		"Courier Prime",
		"Space Mono",
		"JetBrains Mono",
		"DM Mono",
		"PT Mono",
		"Cousine",
		"Material Symbols Sharp",
		"Fira Mono",
		"VT323",
		"Anonymous Pro",
		"Share Tech Mono",
		"Fira Code",
		"Overpass Mono",
		"Azeret Mono",
		"Cutive Mono",
		"B612 Mono",
		"Oxygen Mono",
		"Reddit Mono",
		"Lekton",
		"Nova Mono",
		"Major Mono Display",
		"Xanh Mono",
		"Syne Mono",
		"Chivo Mono",
		"Kode Mono",
		"Martian Mono",
		"Red Hat Mono",
		"Fragment Mono",
		"Monofett",
		"M PLUS 1 Code",
		"Spline Sans Mono",
		"Sometype Mono",
		"Victor Mono",
		"Workbench",
		"Sixtyfour",
		"LXGW WenKai Mono TC",
		"Ubuntu Sans Mono"
	],
	"serif": [
		"Playfair Display",
		"Merriweather",
		"Roboto Slab",
		"Lora",
		"PT Serif",
		"Noto Serif",
		"Libre Baskerville",
		"EB Garamond",
		"Bitter",
		"Noto Serif JP",
		"Crimson Text",
		"Arvo",
		"IBM Plex Serif",
		"Zilla Slab",
		"Cormorant Garamond",
		"Slabo 27px",
		"Nanum Myeongjo",
		"DM Serif Display",
		"Domine",
		"Source Serif 4",
		"Cinzel",
		"Vollkorn",
		"Frank Ruhl Libre",
		"Noto Serif KR",
		"Alegreya",
		"Bree Serif",
		"Spectral",
		"Mate",
		"Cormorant",
		"Marcellus",
		"Noto Serif TC",
		"Tinos",
		"Amiri",
		"Prata",
		"Antic Slab",
		"Martel",
		"Cardo",
		"DM Serif Text",
		"Sawarabi Mincho",
		"Noticia Text",
		"Rokkitt",
		"Roboto Serif",
		"Libre Caslon Text",
		"Crete Round",
		"Mate SC",
		"Crimson Pro",
		"Neuton",
		"Bodoni Moda",
		"Old Standard TT",
		"Quattrocento",
		"Sanchez",
		"Josefin Slab",
		"Eczar",
		"Aleo",
		"Unna",
		"Gelasio",
		"Baskervville",
		"Taviraj",
		"Noto Naskh Arabic",
		"Literata",
		"Playfair Display SC",
		"Noto Serif SC",
		"Gilda Display",
		"Pridi",
		"Fraunces",
		"Faustina",
		"Vidaloka",
		"STIX Two Text",
		"Alice",
		"Shippori Mincho",
		"Abhaya Libre",
		"Ultra",
		"Noto Serif Bengali",
		"Volkhov",
		"Sorts Mill Goudy",
		"Libre Bodoni",
		"Playfair",
		"Lusitana",
		"Rufina",
		"Newsreader",
		"Holtwood One SC",
		"Cormorant Infant",
		"Adamina",
		"Karma",
		"GFS Didot",
		"Zen Old Mincho",
		"Bevan",
		"Yrsa",
		"Arapey",
		"Kiwi Maru",
		"Petrona",
		"Lustria",
		"Glegoo",
		"Kreon",
		"Kameron",
		"Andada Pro",
		"Marcellus SC",
		"PT Serif Caption",
		"Shippori Mincho B1",
		"Graduate",
		"Noto Serif Devanagari",
		"Noto Serif Display",
		"Brygada 1918",
		"Podkova",
		"Besley",
		"Cantata One",
		"Gupter",
		"Kurale",
		"Bellefair",
		"Rozha One",
		"BioRhyme",
		"Ovo",
		"Caudex",
		"Ibarra Real Nova",
		"Italiana",
		"Noto Nastaliq Urdu",
		"Halant",
		"Castoro",
		"Trocchi",
		"Suez One",
		"Trirong",
		"Oranienbaum",
		"Arbutus Slab",
		"Judson",
		"Inknut Antiqua",
		"Vesper Libre",
		"Enriqueta",
		"Hepta Slab",
		"Cormorant Upright",
		"Gurajada",
		"Radley",
		"Rasa",
		"David Libre",
		"Alegreya SC",
		"Caladea",
		"Markazi Text",
		"Maitree",
		"Lateef",
		"Gloock",
		"Alike",
		"Cambo",
		"Hahmlet",
		"Goudy Bookletter 1911",
		"Fanwood Text",
		"Coustard",
		"IM Fell English",
		"Average",
		"Bentham",
		"Cormorant SC",
		"Gabriela",
		"Antic Didone",
		"Spectral SC",
		"Fauna One",
		"Inria Serif",
		"Copse",
		"Montaga",
		"Imbue",
		"Fjord One",
		"IM Fell English SC",
		"Quando",
		"IM Fell DW Pica",
		"Brawler",
		"Della Respira",
		"Kadwa",
		"Almendra",
		"Cutive",
		"Poly",
		"Kaisei Decol",
		"Hanuman",
		"Solway",
		"Aref Ruqaa",
		"Headland One",
		"Vast Shadow",
		"Esteban",
		"Vollkorn SC",
		"Young Serif",
		"Zen Antique Soft",
		"BIZ UDPMincho",
		"Kaisei Opti",
		"Suranna",
		"Tienne",
		"Slabo 13px",
		"Amethysta",
		"Sumana",
		"Asar",
		"Kaisei Tokumin",
		"Noto Serif Thai",
		"Alike Angular",
		"Libre Caslon Display",
		"Hina Mincho",
		"Sura",
		"Instrument Serif",
		"Rosarivo",
		"IM Fell Double Pica",
		"BhuTuka Expanded One",
		"Cormorant Unicase",
		"Gowun Batang",
		"Balthazar",
		"Zilla Slab Highlight",
		"Scope One",
		"Mirza",
		"Prociono",
		"Artifika",
		"Bona Nova",
		"Charis SIL",
		"Tiro Devanagari Hindi",
		"Ledger",
		"Zen Antique",
		"Belgrano",
		"Buenard",
		"Noto Serif Malayalam",
		"Noto Serif HK",
		"Yuji Syuku",
		"Baskervville SC",
		"Platypi",
		"Noto Serif Georgian",
		"Manuale",
		"Scheherazade New",
		"Peralta",
		"Kalnia",
		"Montagu Slab",
		"IM Fell French Canon",
		"Texturina",
		"IM Fell DW Pica SC",
		"Gulzar",
		"Ramaraja",
		"Trykker",
		"Stint Ultra Expanded",
		"Song Myung",
		"Rhodium Libre",
		"Maiden Orange",
		"Fenix",
		"Marko One",
		"Habibi",
		"Piazzolla",
		"IM Fell Great Primer",
		"Port Lligat Slab",
		"Grenze",
		"Abyssinica SIL",
		"Bodoni Moda SC",
		"Jomolhari",
		"Stoke",
		"Tiro Bangla",
		"Donegal One",
		"IM Fell Double Pica SC",
		"Kaisei HarunoUmi",
		"IM Fell Great Primer SC",
		"Sree Krushnadevaraya",
		"IM Fell French Canon SC",
		"Noto Serif Kannada",
		"Junge",
		"Noto Serif Khmer",
		"Koh Santepheap",
		"Noto Serif Telugu",
		"Gentium Plus",
		"Stint Ultra Condensed",
		"Kotta One",
		"Alkalami",
		"Suwannaphum",
		"Almendra SC",
		"Gentium Book Plus",
		"Benne",
		"New Tegomin",
		"Arbutus",
		"Inika",
		"Linden Hill",
		"Wellfleet",
		"Sahitya",
		"Jacques Francois",
		"Aref Ruqaa Ink",
		"BIZ UDMincho",
		"Noto Serif Khojki",
		"Noto Serif Armenian",
		"Aoboshi One",
		"Peddana",
		"Tiro Devanagari Marathi",
		"Joan",
		"Tiro Devanagari Sanskrit",
		"Noto Serif Hebrew",
		"Poltawski Nowy",
		"Labrada",
		"Suravaram",
		"Amiri Quran",
		"Odor Mean Chey",
		"Sedan SC",
		"Uchen",
		"Tiro Telugu",
		"BioRhyme Expanded",
		"Noto Serif Sinhala",
		"Bacasime Antique",
		"Yuji Mai",
		"Yuji Boku",
		"Noto Serif Lao",
		"Tiro Kannada",
		"Noto Serif Gujarati",
		"Wittgenstein",
		"Noto Rashi Hebrew",
		"Sedan",
		"Bona Nova SC",
		"Noto Serif Tibetan",
		"Diphylleia",
		"Hedvig Letters Serif",
		"Tiro Tamil",
		"Noto Serif Balinese",
		"Tai Heritage Pro",
		"Dai Banna SIL",
		"Noto Serif Vithkuqi",
		"Noto Serif Myanmar",
		"Cactus Classical Serif",
		"Tiro Gurmukhi",
		"Noto Serif Tamil",
		"Noto Serif Ethiopic",
		"Ruwudu",
		"Noto Serif NP Hmong",
		"Grandiflora One",
		"Noto Serif Tangut",
		"Noto Serif Ottoman Siyaq",
		"Maname",
		"Annapurna SIL",
		"Noto Serif Toto",
		"Narnoor",
		"Danfo",
		"Noto Serif Khitan Small Script",
		"Noto Serif Yezidi",
		"Namdhinggo",
		"Noto Serif Ahom",
		"Lisu Bosa",
		"Noto Serif Grantha",
		"Noto Serif Gurmukhi",
		"Padyakke Expanded One",
		"Noto Serif Oriya",
		"Noto Serif Old Uyghur",
		"Noto Serif Makasar",
		"Noto Serif Dogra",
		"Kay Pho Du"
	],
	"handwriting": [
		"Dancing Script",
		"Pacifico",
		"Caveat",
		"Shadows Into Light",
		"Satisfy",
		"Permanent Marker",
		"Indie Flower",
		"Nanum Gothic Coding",
		"Amatic SC",
		"Great Vibes",
		"Kalam",
		"Merienda",
		"Courgette",
		"Kaushan Script",
		"Yellowtail",
		"Sacramento",
		"Gloria Hallelujah",
		"Zeyada",
		"Allura",
		"Architects Daughter",
		"Patrick Hand",
		"Sofia",
		"Tangerine",
		"Cookie",
		"Comic Neue",
		"Handlee",
		"Parisienne",
		"Nerko One",
		"Homemade Apple",
		"Alex Brush",
		"Itim",
		"Mr Dafoe",
		"Sriracha",
		"Bad Script",
		"Nothing You Could Do",
		"Rock Salt",
		"Marck Script",
		"Nanum Pen Script",
		"Reenie Beanie",
		"Neucha",
		"Pangolin",
		"Damion",
		"Covered By Your Grace",
		"Pinyon Script",
		"Amita",
		"Italianno",
		"Caveat Brush",
		"Delius",
		"Gochi Hand",
		"Mrs Saint Delafield",
		"Julee",
		"Leckerli One",
		"Herr Von Muellerhoff",
		"Charm",
		"Nanum Brush Script",
		"Berkshire Swash",
		"Mali",
		"La Belle Aurore",
		"Cedarville Cursive",
		"Just Another Hand",
		"Rancho",
		"Shadows Into Light Two",
		"Coming Soon",
		"Norican",
		"Arizonia",
		"Allison",
		"Klee One",
		"Ms Madi",
		"Schoolbell",
		"Annie Use Your Telescope",
		"Yesteryear",
		"Grand Hotel",
		"Niconne",
		"Kristi",
		"Dawning of a New Day",
		"Petit Formal Script",
		"Short Stack",
		"Oooh Baby",
		"Waiting for the Sunrise",
		"Monsieur La Doulaise",
		"Rochester",
		"Calligraffitti",
		"Playwrite CU",
		"Style Script",
		"Over the Rainbow",
		"Qwigley",
		"Ma Shan Zheng",
		"Mansalva",
		"Quintessential",
		"Mr De Haviland",
		"Ephesis",
		"Smooch",
		"Meddon",
		"Loved by the King",
		"Aguafina Script",
		"Sue Ellen Francisco",
		"Hurricane",
		"Walter Turncoat",
		"Fondamento",
		"Sedgwick Ave",
		"Give You Glory",
		"Euphoria Script",
		"Vibur",
		"Hi Melody",
		"Bilbo Swash Caps",
		"Montez",
		"Patrick Hand SC",
		"Fuggles",
		"Crafty Girls",
		"Rouge Script",
		"Qwitcher Grypen",
		"League Script",
		"Clicker Script",
		"Gamja Flower",
		"Charmonman",
		"Licorice",
		"The Girl Next Door",
		"Birthstone",
		"Just Me Again Down Here",
		"Corinthia",
		"Gaegu",
		"Ruthie",
		"Lovers Quarrel",
		"Square Peg",
		"Redressed",
		"Meow Script",
		"Playpen Sans",
		"Delius Unicase",
		"MonteCarlo",
		"Grape Nuts",
		"Birthstone Bounce",
		"Edu SA Beginner",
		"Swanky and Moo Moo",
		"Dekko",
		"Comforter Brush",
		"Passions Conflict",
		"Hachi Maru Pop",
		"East Sea Dokdo",
		"WindSong",
		"Sunshiney",
		"Whisper",
		"Sedgwick Ave Display",
		"Delicious Handrawn",
		"Yomogi",
		"Delius Swash Caps",
		"Edu VIC WA NT Beginner",
		"Waterfall",
		"Fuzzy Bubbles",
		"The Nautigal",
		"Vujahday Script",
		"Solitreo",
		"Meie Script",
		"Stalemate",
		"Moon Dance",
		"Chilanka",
		"Engagement",
		"Beth Ellen",
		"Carattere",
		"Edu NSW ACT Foundation",
		"Eagle Lake",
		"Zhi Mang Xing",
		"Shalimar",
		"Borel",
		"Kavivanar",
		"Condiment",
		"Imperial Script",
		"Liu Jian Mao Cao",
		"Beau Rivage",
		"Comforter",
		"Bilbo",
		"Long Cang",
		"Edu TAS Beginner",
		"Felipa",
		"Mrs Sheppards",
		"Mynerve",
		"Festive",
		"Water Brush",
		"Gwendolyn",
		"Praise",
		"Lugrasimo",
		"Bonheur Royale",
		"Romanesco",
		"Updock",
		"Luxurious Script",
		"Lavishly Yours",
		"Lumanosimo",
		"Mr Bedfort",
		"Edu AU VIC WA NT Hand",
		"Dr Sugiyama",
		"Princess Sofia",
		"Molle",
		"Lakki Reddy",
		"Mea Culpa",
		"Devonshire",
		"Ballet",
		"Caramel",
		"Grey Qo",
		"Playwrite US Trad",
		"Babylonica",
		"Miss Fajardose",
		"Jim Nightshade",
		"Butterfly Kids",
		"Twinkle Star",
		"Inspiration",
		"Neonderthaw",
		"Island Moments",
		"Bonbon",
		"Love Light",
		"Kolker Brush",
		"My Soul",
		"Splash",
		"Ruge Boogie",
		"Send Flowers",
		"Explora",
		"Fleur De Leah",
		"Playwrite AR",
		"Petemoss",
		"Playwrite BE VLG",
		"Sassy Frass",
		"Grechen Fuemen",
		"Edu QLD Beginner",
		"Playwrite FR Moderne",
		"Playwrite AU NSW",
		"Playwrite DE Grund",
		"Playwrite DK Uloopet",
		"LXGW WenKai TC",
		"Estonia",
		"Are You Serious",
		"Playwrite AU SA",
		"Playwrite ES Deco",
		"Playwrite IS",
		"Playwrite AU VIC",
		"Playwrite DK Loopet",
		"Playwrite BE WAL",
		"Playwrite HR Lijeva",
		"Playwrite AT",
		"Cherish",
		"Playwrite HU",
		"Tapestry",
		"Ole",
		"Playwrite HR",
		"Ingrid Darling",
		"Playwrite CL",
		"Playwrite CZ",
		"Playwrite PE",
		"Playwrite GB S",
		"Slackside One",
		"Playwrite NG Modern",
		"Playwrite MX",
		"Playwrite NL",
		"Kings",
		"Playwrite ES",
		"Yuji Hentaigana Akari",
		"Playwrite AU TAS",
		"Playwrite PT",
		"Playwrite AU QLD",
		"Playwrite IT Moderna",
		"Playwrite CO",
		"Playwrite PL",
		"Playwrite SK",
		"Playwrite NO",
		"Puppies Play",
		"Playwrite NZ",
		"Playwrite US Modern",
		"Playwrite RO",
		"Playwrite IE",
		"Playwrite FR Trad",
		"Playwrite VN",
		"Playwrite ZA",
		"Playwrite BR",
		"Playwrite CA",
		"Playwrite GB J",
		"Playwrite IN",
		"Playwrite DE VA",
		"Playwrite DE SAS",
		"Playwrite ID",
		"Playwrite DE LA",
		"Playwrite IT Trad",
		"Playwrite TZ",
		"Yuji Hentaigana Akebono"
	],
	"display": [
		"Comfortaa",
		"Lobster",
		"Lilita One",
		"Abril Fatface",
		"Rowdies",
		"Alfa Slab One",
		"Concert One",
		"Righteous",
		"Patua One",
		"Oleo Script",
		"Lobster Two",
		"Passion One",
		"Luckiest Guy",
		"Bungee",
		"Titan One",
		"Spicy Rice",
		"Silkscreen",
		"Bangers",
		"Yeseva One",
		"Baloo Bhaijaan 2",
		"Rubik Bubbles",
		"Poiret One",
		"Special Elite",
		"Staatliches",
		"Libre Barcode 39",
		"Creepster",
		"Bowlby One",
		"Squada One",
		"Pirata One",
		"Baloo 2",
		"Macondo",
		"Yatra One",
		"Fugaz One",
		"Press Start 2P",
		"Changa One",
		"Audiowide",
		"Carter One",
		"Dela Gothic One",
		"Playball",
		"Lalezar",
		"Forum",
		"Unica One",
		"Monoton",
		"Black Ops One",
		"Rammetto One",
		"Balsamiq Sans",
		"Londrina Solid",
		"Big Shoulders Display",
		"Oxanium",
		"Averia Serif Libre",
		"Coda",
		"Cabin Sketch",
		"Calistoga",
		"Shrikhand",
		"Cinzel Decorative",
		"Chewy",
		"Grandstander",
		"Racing Sans One",
		"Seaweed Script",
		"Corben",
		"Lemonada",
		"Fredericka the Great",
		"Boogaloo",
		"Angkor",
		"Shantell Sans",
		"Averia Libre",
		"Koulen",
		"Overlock",
		"Rye",
		"Odibee Sans",
		"Sigmar One",
		"Knewave",
		"Gabarito",
		"Skranji",
		"Nixie One",
		"Lemon",
		"Stardos Stencil",
		"Wallpoet",
		"Baloo Da 2",
		"Chonburi",
		"Bubblegum Sans",
		"Contrail One",
		"Poetsen One",
		"Gravitas One",
		"Limelight",
		"Bowlby One SC",
		"UnifrakturMaguntia",
		"MuseoModerno",
		"Irish Grover",
		"Zen Dots",
		"Love Ya Like A Sister",
		"Jomhuria",
		"Bungee Inline",
		"McLaren",
		"Big Shoulders Text",
		"Oleo Script Swash Caps",
		"Kelly Slab",
		"Grenze Gotisch",
		"Bungee Shade",
		"Sniglet",
		"Libre Barcode 39 Text",
		"Chelsea Market",
		"Aboreto",
		"Bellota Text",
		"Prosto One",
		"Baloo Paaji 2",
		"Turret Road",
		"Expletus Sans",
		"Baloo Thambi 2",
		"Aladin",
		"Pompiere",
		"Megrim",
		"Rakkas",
		"Baloo Tamma 2",
		"Sansita Swashed",
		"Baloo Chettan 2",
		"Agbalumo",
		"Croissant One",
		"Mountains of Christmas",
		"Flow Circular",
		"Allan",
		"Barriecito",
		"Gluten",
		"Shojumaru",
		"Caprasimo",
		"Libre Barcode 39 Extended Text",
		"Supermercado One",
		"Libre Barcode 128",
		"Gugi",
		"Orelega One",
		"Potta One",
		"Happy Monkey",
		"Oregano",
		"Anybody",
		"Bruno Ace SC",
		"Nova Square",
		"Averia Sans Libre",
		"Battambang",
		"Bellota",
		"Pixelify Sans",
		"Coiny",
		"Freehand",
		"Saira Stencil One",
		"Goldman",
		"Ruslan Display",
		"Bigshot One",
		"Cherry Cream Soda",
		"Dynalight",
		"Unkempt",
		"Amarante",
		"Elsie",
		"Finger Paint",
		"Vina Sans",
		"Bakbak One",
		"Goblin One",
		"Codystar",
		"Baloo Bhai 2",
		"Dokdo",
		"Raleway Dots",
		"Rampart One",
		"Atma",
		"Metamorphous",
		"Mogra",
		"Kranky",
		"Tilt Warp",
		"Handjet",
		"Slackey",
		"Rubik Scribble",
		"Uncial Antiqua",
		"Germania One",
		"Salsa",
		"Galada",
		"Overlock SC",
		"Life Savers",
		"Faster One",
		"Lily Script One",
		"Nova Round",
		"Original Surfer",
		"Viaoda Libre",
		"Red Rose",
		"ADLaM Display",
		"Flamenco",
		"Fontdiner Swanky",
		"Medula One",
		"Road Rage",
		"Sevillana",
		"Poller One",
		"Reggae One",
		"Fasthand",
		"Baumans",
		"Trade Winds",
		"Eater",
		"Cherry Swash",
		"Modak",
		"Karantina",
		"Ceviche One",
		"Freckle Face",
		"Libre Barcode 128 Text",
		"Chango",
		"Iceberg",
		"Cute Font",
		"Tektur",
		"Moul",
		"Emilys Candy",
		"Frijole",
		"Crushed",
		"Henny Penny",
		"Sail",
		"Iceland",
		"Arima",
		"Protest Guerrilla",
		"Katibeh",
		"Modern Antiqua",
		"Miniver",
		"UnifrakturCook",
		"Tillana",
		"Sancreek",
		"Rubik Dirt",
		"Srisakdi",
		"Akronim",
		"Ranchers",
		"Ribeye",
		"Baloo Bhaina 2",
		"Macondo Swash Caps",
		"MedievalSharp",
		"Fascinate Inline",
		"Sonsie One",
		"Nova Flat",
		"Akaya Telivigala",
		"Londrina Outline",
		"Black And White Picture",
		"Unlock",
		"Baloo Tammudu 2",
		"Alkatra",
		"Tilt Neon",
		"Barrio",
		"Jacques Francois Shadow",
		"Asset",
		"Farsan",
		"Paprika",
		"Ewert",
		"Nova Slim",
		"Ribeye Marrow",
		"Nova Oval",
		"Smythe",
		"Gorditas",
		"Caesar Dressing",
		"Vampiro One",
		"Train One",
		"Kenia",
		"Astloch",
		"Zen Tokyo Zoo",
		"Redacted",
		"Protest Revolution",
		"Simonetta",
		"Mystery Quest",
		"Rubik Moonrocks",
		"Chicle",
		"Akaya Kanadaka",
		"Margarine",
		"Big Shoulders Stencil Text",
		"Miltonian Tattoo",
		"Girassol",
		"Spirax",
		"Risque",
		"Nosifer",
		"Nova Cut",
		"New Rocker",
		"Marhey",
		"Rubik Doodle Shadow",
		"Sarina",
		"Nova Script",
		"Miltonian",
		"Bungee Spice",
		"Metal Mania",
		"Milonga",
		"Ranga",
		"Atomic Age",
		"Underdog",
		"Darumadrop One",
		"Jolly Lodger",
		"Kavoon",
		"Tilt Prism",
		"Yeon Sung",
		"Federant",
		"Londrina Shadow",
		"Tulpen One",
		"Offside",
		"Poor Story",
		"Lacquer",
		"Averia Gruesa Libre",
		"Kirang Haerang",
		"Kumar One",
		"Autour One",
		"Keania One",
		"Plaster",
		"Galindo",
		"Content",
		"Smokum",
		"Elsie Swash Caps",
		"Dangrek",
		"Buda",
		"Kumar One Outline",
		"Glass Antiqua",
		"Protest Riot",
		"Joti One",
		"Bungee Hairline",
		"Piedra",
		"Bungee Outline",
		"Fascinate",
		"Erica One",
		"Ravi Prakash",
		"Chela One",
		"Libre Barcode 39 Extended",
		"Protest Strike",
		"Lancelot",
		"Bahiana",
		"Tourney",
		"Sirin Stencil",
		"Jersey 20 Charted",
		"DynaPuff",
		"Big Shoulders Inline Text",
		"Alumni Sans Inline One",
		"Climate Crisis",
		"Trochut",
		"Griffy",
		"Flavors",
		"Oldenburg",
		"Gideon Roman",
		"Phudu",
		"Metal",
		"Revalia",
		"Diplomata SC",
		"Fruktur",
		"Bigelow Rules",
		"Bungee Tint",
		"Stalinist One",
		"Castoro Titling",
		"Sofadi One",
		"Jersey 15",
		"Blaka",
		"Single Day",
		"Rubik Iso",
		"Almendra Display",
		"Rubik Glitch",
		"Libre Barcode EAN13 Text",
		"Kalnia Glaze",
		"Big Shoulders Stencil Display",
		"Luxurious Roman",
		"Chenla",
		"Bokor",
		"Foldit",
		"Honk",
		"Freeman",
		"Bruno Ace",
		"Emblema One",
		"Hanalei Fill",
		"Diplomata",
		"Rubik Distressed",
		"Passero One",
		"Cherry Bomb One",
		"Jacquard 12",
		"Aubrey",
		"Londrina Sketch",
		"Nabla",
		"Redacted Script",
		"Micro 5",
		"Purple Purse",
		"Rubik Beastly",
		"Butcherman",
		"Rubik Wet Paint",
		"Langar",
		"Combo",
		"Vibes",
		"Taprom",
		"Flow Block",
		"Big Shoulders Inline Display",
		"Bagel Fat One",
		"Jacquard 12 Charted",
		"Bahianita",
		"Konkhmer Sleokchher",
		"Geostar",
		"Rubik Vinyl",
		"Jersey 10",
		"Sigmar",
		"Flow Rounded",
		"Oi",
		"Geostar Fill",
		"Rubik Glitch Pop",
		"Jersey 20",
		"Kablammo",
		"Palette Mosaic",
		"Rubik Doodle Triangles",
		"Rubik 80s Fade",
		"Ga Maamli",
		"Snowburst One",
		"Rock 3D",
		"Zen Loop",
		"Rubik Spray Paint",
		"Jacquard 24",
		"Gajraj One",
		"Moirai One",
		"Moo Lah Lah",
		"Syne Tactile",
		"Jersey 25",
		"Shizuru",
		"Warnes",
		"Hanalei",
		"Rubik Pixels",
		"Rubik Puddles",
		"Rubik Burned",
		"Rubik Marker Hatch",
		"Rubik Maps",
		"Rubik Gemstones",
		"Blaka Hollow",
		"Rubik Microbe",
		"Blaka Ink",
		"Rubik Maze",
		"Wavefont",
		"Rubik Storm",
		"Rubik Broken Fax",
		"Jaini",
		"Jersey 15 Charted",
		"Rubik Lines",
		"Jersey 25 Charted",
		"Jaini Purva",
		"Linefont",
		"Chokokutai",
		"Jacquarda Bastarda 9 Charted",
		"Jacquarda Bastarda 9",
		"Jersey 10 Charted",
		"Micro 5 Charted",
		"Jacquard 24 Charted",
		"Yarndings 20",
		"Yarndings 12",
		"Yarndings 12 Charted",
		"Yarndings 20 Charted"
	]
}
