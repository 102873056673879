import { atom, useRecoilState, useRecoilValue } from "recoil";
import produce from "immer";
import { globalVariablesConfigState, globalVariablesState } from "./dataDb";
import log from "cslog";

export const selectedExtensionState = atom({
    key: "selected_extension",
    default: null,
});

export const installedExtensionsState = atom({
    key: "installed_extensions",
    default: {},
});

export const extensionsInstallationDoneState = atom({
    key: "extensions_installation_done",
    default: [],
});

export const useInstalledExtensions = () => {
    const [ie, setIE] = useRecoilState(installedExtensionsState);

    const uninstallExtension = (id) => {
        if (id in ie) {
            setIE(
                produce(ie, (draft) => {
                    delete draft[id];
                })
            );
        }
    };

    const installExtension = (id, data) => {
        if (!(id in ie)) {
            setIE(
                produce(ie, (draft) => {
                    draft[id] = data;
                })
            );
        }
    };

    const installExtensions = (obj) => {
        const out = {};
        Object.keys(obj).forEach((key) => {
            if (!(key in ie)) {
                out[key] = obj[key];
            }
        });
        const newIE = {
            ...ie,
            ...out,
        };

        log.d({ ie, out }, "IE OUT");

        setIE(newIE);
    };

    log.d(ie, "IE");

    return {
        installed: ie,
        installExtension,
        installExtensions,
        uninstallExtension,
    };
};

export const additionalAllPageSettingsState = atom({
    key: "additionalAllPageSettilngs",
    default: [
        {
            id: "title",
            label: "Page Title",
            vtype: "text",
            itype: "shorttext",
            default: " ",
        },
        {
            id: "description",
            label: "Page Description",
            vtype: "text",
            itype: "text",
            default: " ",
        },
        {
            id: "hidePageBefore",
            label: "Hide Page Before",
            vtype: "boolean",
            itype: "boolean",
            default: false,
        },
        {
            id: "hidePageAfter",
            label: "Hide Page After",
            vtype: "boolean",
            itype: "boolean",
            default: false,
        },
    ],
});

export const useAdditionalAllPageSettingsValueState = () => {
    const addition_all_page_settings = useRecoilValue(
        additionalAllPageSettingsState
    );

    const out = {};
    addition_all_page_settings.forEach((item) => {
        out[item.id] = item.default;
    });
    return out;
};

export const useAdditionalAllPageSettings = () => {
    const [settings, setSettings] = useRecoilState(
        additionalAllPageSettingsState
    );

    const addAllPageSetting = (newPanel) => {
        if (settings.findIndex((p) => p.id === newPanel.id) === -1) {
            setSettings([...settings, newPanel]);
        }
    };

    const addAllPageSettings = (newPanels) => {
        const reallyNewPanels = newPanels.filter((np) => {
            if (settings.findIndex((p) => p.id === np.id) === -1) {
                return true;
            }
            return false;
        });
        setSettings((old_settings) => [...old_settings, ...reallyNewPanels]);
    };

    return { settings, addAllPageSetting, addAllPageSettings };
};

export const additionPanelsState = atom({
    key: "additionPanels",
    default: [],
});

export const useAdditionalPanels = () => {
    const [panels, setPanels] = useRecoilState(additionPanelsState);

    const addPanel = (newPanel) => {
        if (panels.findIndex((p) => p.id === newPanel.id) === -1) {
            setPanels([...panels, newPanel]);
        }
    };

    const addPanels = (newPanels) => {
        const reallyNewPanels = newPanels.filter((np) => {
            if (panels.findIndex((p) => p.id === np.id) === -1) {
                return true;
            }
            return false;
        });
        setPanels((old_panels) => [...old_panels, ...reallyNewPanels]);
    };

    return { panels, addPanel, addPanels };
};

export const additionalElementsState = atom({
    key: "additionalElements",
    default: {},
});

export const useAdditionalElements = () => {
    const [elements, setElements] = useRecoilState(additionalElementsState);

    const addElement = (newElement) => {
        if (!(newElement.id in elements)) {
            setElements(
                produce(elements, (draft) => {
                    draft[newElement.id] = newElement;
                })
            );
        }
    };

    const addElements = (newElements) => {
        const reallyNewElements = Object.keys(newElements).filter((el) => {
            return !(el in elements);
        });
        setElements((old_elements) =>
            produce(old_elements, (draft) => {
                reallyNewElements.forEach((el) => {
                    draft[el] = newElements[el];
                });
            })
        );
    };

    return { elements, addElement, addElements };
};

export const additionalDataSourceState = atom({
    key: "additionalDataSources",
    default: {},
});

export const useAdditionalDataSource = () => {
    const [ds, setDS] = useRecoilState(additionalDataSourceState);

    const addDataSource = (newDS) => {
        if (!(newDS.id in ds)) {
            setDS(
                produce(ds, (draft) => {
                    draft[newDS.id] = newDS;
                })
            );
        }
    };

    const addDataSources = (newDSs) => {
        const reallyNewElements = Object.keys(newDSs).filter((el) => {
            return !(el in ds);
        });
        setDS((old_ds) =>
            produce(old_ds, (draft) => {
                reallyNewElements.forEach((el) => {
                    draft[el] = newDSs[el];
                });
            })
        );
    };

    return { data_sources: ds, addDataSource, addDataSources };
};

// export const additionalActionsState = atom({});

export const additionalElementsRenderState = atom({
    key: "additionalElementsRender",
    default: {},
});

export const useAdditionalElementsRender = () => {
    const [ers, setErs] = useRecoilState(additionalElementsRenderState);

    const addElementRender = (newElement) => {
        if (!(newElement.id in ers)) {
            setErs(
                produce(ers, (draft) => {
                    draft[newElement.id] = newElement;
                })
            );
        }
    };

    const addElementsRenders = (newElements) => {
        const reallyNewElements = Object.keys(newElements).filter((el) => {
            return !(el in ers);
        });
        setErs((old_ers) =>
            produce(old_ers, (draft) => {
                reallyNewElements.forEach((el) => {
                    draft[el] = newElements[el];
                });
            })
        );
    };

    return { element_renders: ers, addElementRender, addElementsRenders };
};

export const extensionSettingsState = atom({
    key: "extensionSettings",
    default: {},
});

export const extensionSettingsConfigState = atom({
    key: "extensionSettingsConfig",
    default: {},
});

export function useExtensionSettings() {
    const [es, setES] = useRecoilState(extensionSettingsState);
    const [esc, setESC] = useRecoilState(extensionSettingsConfigState);

    function addES(name, obj) {
        // if (!(name in es)) {
        //     setES({
        //         ...es,
        //         [name]: obj["default_value"],
        //     });
        //     setESC({
        //         ...esc,
        //         [name]: obj,
        //     });
        //}
    }

    function addESs(name, arr) {
        log.p("Adding extension settings");
        const out = {};
        const out_c = {};
        const existing_seeting = es[name] || {};
        arr.forEach((obj) => {
            if (!(obj["name"] in es)) {
                out[obj["name"]] =
                    existing_seeting[obj["name"]] || obj["default_value"];
                out_c[obj["name"]] = obj;
            }
        });
        setES((old_es) => ({
            ...old_es,
            // ...out,
            [name]: out,
        }));
        setESC((old_esc) => ({
            ...old_esc,
            // ...out_c,
            [name]: out_c,
        }));
    }

    return { es, esc, addES, addESs };
}

export function useAddtionalGlobalVariables() {
    const [gv, setGV] = useRecoilState(globalVariablesState);
    const [gvc, setGVC] = useRecoilState(globalVariablesConfigState);

    function addGV(obj) {
        if (!(obj["name"] in gv)) {
            setGV({
                ...gv,
                [obj["name"]]: obj["default_value"],
            });
            setGVC({
                ...gvc,
                [obj["name"]]: obj,
            });
        }
    }

    function addGVs(arr) {
        const out = {};
        const out_c = {};
        arr.forEach((obj) => {
            if (!(obj["name"] in gv)) {
                out[obj["name"]] = obj["default_value"];
                out_c[obj["name"]] = obj;
            }
        });
        setGV((old_gv) => ({
            ...old_gv,
            ...out,
        }));
        setGVC((old_gvc) => ({
            ...old_gvc,
            ...out_c,
        }));
    }

    return {
        gv,
        addGV,
        addGVs,
    };
}

export const additionalGlobalState = atom({
    key: "additionalGlobalStates",
    default: {},
});

export const useAdditionalGlobalStates = () => {
    const [globalStates, setGlobalStates] = useRecoilState(
        additionalGlobalState
    );

    const addGlobalState = (newState) => {
        if (!(newState.id in globalStates)) {
            setGlobalStates(
                produce(globalStates, (draft) => {
                    draft[newState.id] = newState;
                })
            );
        }
    };

    const addGlobalStates = (newElements) => {
        const reallyNewElements = Object.keys(newElements).filter((el) => {
            return !(el in globalStates);
        });
        setGlobalStates(
            produce(globalStates, (draft) => {
                reallyNewElements.forEach((el) => {
                    draft[el] = newElements[el];
                });
            })
        );
    };

    return {
        global_states: globalStates,
        addGlobalState: addGlobalState,
        addGlobalStates: addGlobalStates,
    };
};
