import axios from "axios";
import { useQuery } from "react-query";

export const useUnsplash = (search_term, per_page = 25, page_no = 1) => {
    return useQuery(["unsplash", search_term, page_no, per_page], () =>
        axios
            .get(`https://api.unsplash.com/search/photos?page=${page_no}`, {
                headers: {
                    Authorization:
                        "Client-ID a7201dc2e0f593d5afec59f5df5b8b5bd61c860877042f34d21078fade0469c8",
                },
                params: { query: search_term, per_page: per_page },
            })
            // .then((res) => res.data?.results)
            .then((res) => res.data)
    );
};

export const usePexels = (search_term, per_page = 25, page_no = 1) => {
    return useQuery(["pexels", search_term, page_no], () =>
        // https://api.pexels.com/v1/search?query=nature&per_page=1
        axios
            .get(`https://api.pexels.com/v1/search`, {
                headers: {
                    Authorization:
                        "tQQFG7tTJ2wveurwxVybGyQGszsBsPoAur7Zk1EbJP9pNA5tiNlpwd1x",
                },
                params: {
                    query: search_term,
                    per_page: per_page,
                    page: page_no,
                },
            })
            // .then((res) => res.data?.results)
            .then((res) => {
                const data = res.data;
                return {
                    results: data?.photos,
                    total_pages: data.total_results / per_page,
                };
            })
    );
};

export const usePixabay = (
    search_term,
    per_page = 25,
    page_no = 1,
    type = "photo"
) => {
    return useQuery(["pixabay", search_term, page_no, type, per_page], () =>
        // https://api.pexels.com/v1/search?query=nature&per_page=1
        axios
            // .get(`https://pixabay.com/api`, {
            .get(`https://botapi.qureal.com/proxies/pixabay/search`, {
                headers: {},
                params: {
                    q: type === "png" ? `${search_term} ${type}` : search_term,
                    per_page: per_page,
                    page: page_no,
                    image_type: type,
                },
            })
            // .then((res) => res.data?.results)
            .then((res) => {
                const data = res.data;
                return {
                    results: data?.hits,
                    total_pages: data.total / per_page,
                };
            })
    );
};

export const useTenor = (search_term, per_page = 12) => {
    return useQuery(["tenor", search_term], () =>
        axios
            .get("https://g.tenor.com/v1/search?key=QMOXPYZL62ZY", {
                params: { q: search_term, limit: per_page },
            })
            .then((res) => res.data?.results)
    );
};
