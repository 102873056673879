import log from "cslog";
import React from "react";
import { Form } from "semantic-ui-react";
import {
    DataSourceDropdown,
    DynamicFiltersConfigOption,
} from "../dynamic/DynamicDataOption";
import { selectOption } from "./SimpleOptions";

export const dynamicListColumnsOption = (label, value, setValue) => {
    return (
        <div>
            <h3>Dynamic List Columns</h3>
        </div>
    );
};

export const dyanmicColumnOption = (label, value, setValue, rest) => {
    return (
        <>
            {selectOption(
                label,
                value,
                setValue,
                rest.fields.map((f) => [f.label, f.name])
            )}
        </>
    );
};

export const dynamicListOption = (label, value, setValue) => {
    log.d(value, "Value dLO");
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <DataSourceDropdown
                value={value}
                setValue={(newVal) => setValue(newVal)}
                filterFunc={(item) => item?.data?.type === "list"}
            />
        </Form.Field>
    );
};

export const dynamicItemOption = (label, value, setValue) => {
    log.d(value, "Value");
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <DataSourceDropdown
                value={value}
                setValue={(newVal) => setValue(newVal)}
                filterFunc={(item) => true}
            />
        </Form.Field>
    );
};

export const dynamicFilterConfigOption = (label, value, setValue) => {
    return <DynamicFiltersConfigOption value={value} setValue={setValue} />;
};
