import React, { Fragment, useState } from "react";
import styles from "./styles/QurealBotWidget.module.css";
import { Icon } from "semantic-ui-react";
import QurealBotAbolute from "../absolute/QurealBotAbsolute";
import AI_BOT_IMAGE from "../../../media/imgs/cute_ai_bot.png";

function QurealBotWidget() {
    const [show, setShow] = useState(false);

    return (
        <div
            className={styles.wrapper}
            style={{
                width: show ? "400px" : "70px",
                cursor: !show ? "pointer" : "auto",
            }}
        >
            {!show && (
                <div className={styles.trigger} onClick={() => setShow(true)}>
                    <img src={AI_BOT_IMAGE} alt="ai bot" />
                    {/* <Icon
                        // name="microchip"
                        name="android"
                        size="big"
                        style={{ color: "white" }}
                    /> */}
                </div>
            )}
            {show && (
                <Fragment>
                    <div className={styles.header_wrapper}>
                        <span
                            className={styles.header}
                            onClick={() => setShow(false)}
                        >
                            QUREAL BOT
                        </span>
                    </div>
                    <div className={styles.content}>
                        <QurealBotAbolute />
                    </div>
                </Fragment>
            )}
        </div>
    );
}

export default QurealBotWidget;
