import React, { useState } from "react";
import { useFolders } from "../actions/assets";
import capitalize from "lodash/capitalize";
import { Button, Icon } from "semantic-ui-react";
import {
    useCreateFolder,
    useUploadFile,
    useUploadPhoto,
} from "../actions/mutations/assets";
import log from "cslog";
import SingleInputPopup from "./SingleInputPopup";

function FolderStructure({ acceptTypes, folder, setFolder }) {
    // const [folder, setFolder] = useState(null);
    const { data: dataFolders } = useFolders(folder);
    const mutationFile = useUploadFile();
    const mutationFolder = useCreateFolder();

    const uploadFile = (title, photo, name = null) => {
        const formD = new FormData();

        if (folder) {
            formD.append("folder", folder.id);
        }
        if (name) {
            formD.append("file", photo, name);
        } else {
            formD.append("file", photo);
        }

        log.d(formD, "Form Data");
        mutationFile.mutate(formD);
    };

    const onFile = (eve) => {
        console.log("Got File");
        const photos = eve.target.files;
        if (photos.length > 0) {
            Array.from(photos).forEach((photo) => {
                if (photo) {
                    if (typeof photo === "object" && photo !== null) {
                        uploadFile("Uploaded", photo);
                    }
                }
            });
        }
    };

    const createFolder = (name) => {
        log.d(name, "Creating Folder");
        if (name !== "") {
            mutationFolder.mutate({
                name: name,
                parent: folder?.id,
            });
        }
    };

    log.d(mutationFile, "Mutation File");

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {/* {folder && ( */}
                    <Icon
                        name="home"
                        size="large"
                        onClick={() => {
                            setFolder(null);
                        }}
                        style={{
                            marginRight: "20px",
                        }}
                        color="blue"
                        link
                    />
                    {/* )} */}
                    <span
                        style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                        }}
                    >
                        {folder ? capitalize(folder.name) : "Home"}
                    </span>
                </div>
                <div>
                    <Button
                        icon
                        primary
                        labelPosition="left"
                        onClick={(eve) => {
                            eve.preventDefault();
                            document.getElementById("file-in-zzz").click();
                        }}
                        // loading={uploading}
                        loading={mutationFile.isLoading}
                    >
                        Upload File
                        <Icon name="upload" />
                    </Button>

                    <SingleInputPopup
                        size="mini"
                        trigger={
                            <Button
                                icon
                                primary
                                labelPosition="left"
                                loading={mutationFolder.isLoading}
                            >
                                Create Folder
                                <Icon name="folder" />
                            </Button>
                        }
                        label="New Folder Name"
                        btnText="Create"
                        onSubmit={(name) => createFolder(name)}
                    />

                    <input
                        type="file"
                        // accept="image/*"
                        accept={acceptTypes}
                        multiple="multiple"
                        name="photo"
                        onChange={onFile}
                        id="file-in-zzz"
                        hidden
                    />
                </div>
            </div>
            {dataFolders && (
                <div
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                    }}
                >
                    {dataFolders.map((df) => {
                        return (
                            <div
                                key={df.name}
                                style={{
                                    // width: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 20px",
                                    background: "#f5cb42",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    gap: "7px",
                                }}
                                onClick={() => {
                                    setFolder(df);
                                }}
                            >
                                <Icon name="folder open outline" />
                                {capitalize(df.name)}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default FolderStructure;
