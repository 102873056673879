// import React from "react";
// import ReactDOM from "react-dom";
// import { RecoilRoot } from "recoil";
// import EditorApplication from "./EditorApplication";

// ReactDOM.render(<EditorApplication />, document.querySelector("#root"));

import { createRoot } from "react-dom/client";
import EditorApplication from "./EditorApplication";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<EditorApplication />);

// For logic builder
// https://rete.js.org/#/examples/basic
