// import log from "cslog";
import React from "react";
// import { GOOGLE_FONTS } from "../../customize/ComplexOptions";
import { decodeStyle } from "../../../../junctionAbs";
import CanvasFreeView from "./CanvasFreeView";
import styles from "./styles/BookPage.module.css";

const BookPage = ({
    id,
    db,
    globalDb,
    index,
    device,
    gs,
    globalConfig,
    bookLayout,
}) => {
    const gdata = globalDb["globalPage"];
    const data = db[id];
    const globalConfigD = decodeStyle(globalConfig, gs);

    // log.d(db, "DB: BookPage: " + id);

    const dims = bookLayout.pageSize.value;
    let padding = bookLayout.padding.params;
    if (bookLayout.padding.type === "single") {
        padding = {
            top: bookLayout.padding.params,
            bottom: bookLayout.padding.params,
            left: bookLayout.padding.params,
            right: bookLayout.padding.params,
        };
    }

    // useEffect(() => {
    //     if (GOOGLE_FONTS.includes(globalConfigD.defaultFontFamily)) {
    //         window.WebFont.load({
    //             google: {
    //                 families: [globalConfigD.defaultFontFamily],
    //             },
    //         });
    //     }
    // }, []);

    return (
        <div
            style={{
                width: dims.width,
                height: dims.height,
                position: "relative",
                // border: "2px dashed green",
            }}
        >
            <div
                id={id}
                name={id}
                style={{
                    width: dims.width,
                    height: dims.height,
                    boxShadow: "0px 0px 8px 1px rgba(222,222,222,1)",
                    marginTop: 0,
                    position: "absolute",
                    borderRadius: bookLayout.borderRadius.value,
                    // "*": globalConfig,
                    fontFamily: globalConfigD.defaultFontFamily,
                    background: globalConfigD.pageBackground,
                    backgroundColor: "transparent",
                    // pointerEvents: "none",
                }}
                css={
                    {
                        // "*": globalConfig,
                        // fontFamily: globalConfigD.defaultFontFamily,
                        // background: globalConfigD.pageBackground,
                        // background: "yellow",
                    }
                }
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        // background: bookLayout.background.value,
                        backgroundPosition: bookLayout?.backgroundPosition,
                        borderRadius: bookLayout.borderRadius.value,
                        // overflow: "hidden",
                    }}
                >
                    {/* <div className={styles.grid_wrapper}>
                        <div style={{ position: "relative" }}>
                            {gdata.bg.map((item) => {
                                return (
                                    <CanvasFreeView
                                        key={item}
                                        id={item}
                                        db={globalDb}
                                        width={dims.width}
                                        height={dims.height}
                                        padding={padding}
                                        device={device}
                                        gs={gs}
                                        isBg={false}
                                    />
                                );
                            })}
                            {gdata.childs.map((item) => {
                                return (
                                    <CanvasFreeView
                                        key={item}
                                        id={item}
                                        db={globalDb}
                                        width={
                                            dims.width
                                            // dims.width -
                                            // padding.left -
                                            // padding.right
                                        }
                                        height={
                                            dims.height
                                            // dims.height -
                                            // padding.top -
                                            // padding.bottom
                                        }
                                        padding={padding}
                                        device={device}
                                        gs={gs}
                                        isBg={false}
                                    />
                                );
                            })}
                        </div>
                    </div> */}
                </div>

                <div className={styles.grid_wrapper}>
                    <div style={{ position: "relative" }}>
                        {data.bg.map((item) => {
                            return (
                                <CanvasFreeView
                                    key={item}
                                    id={item}
                                    db={db}
                                    width={dims.width}
                                    height={dims.height}
                                    padding={padding}
                                    device={device}
                                    gs={gs}
                                    isBg={false}
                                />
                            );
                        })}
                        {data.childs.map((item) => {
                            return (
                                <CanvasFreeView
                                    key={item}
                                    id={item}
                                    db={db}
                                    width={
                                        dims.width
                                        // dims.width -
                                        // padding.left -
                                        // padding.right
                                    }
                                    height={
                                        dims.height
                                        // dims.height -
                                        // padding.top -
                                        // padding.bottom
                                    }
                                    padding={padding}
                                    device={device}
                                    gs={gs}
                                    isBg={false}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            {/* <FreeControlBar />
            <LayersBar /> */}
        </div>
    );
};

export default BookPage;
