import { atom, selector } from "recoil";

export const widgetArray = {};

export const widgetState = (id, data = null) => {
    if (!id) {
        id = "NULL";
    }
    if (id in widgetArray) {
        return widgetArray[id];
    }
    widgetArray[id] = atom({
        key: `widget_${id}`,
        default: data ? data : {},
    });
    return widgetArray[id];
};
