import { Transforms } from "slate";

export const createParagraphNode = (children = [{ text: "" }]) => ({
    type: "paragraph",
    children,
});

export const insertParagraph = (editor, text) => {
    const textNode = { type: "paragraph", children: [{ text: text }] };
    Transforms.insertNodes(editor, textNode);
};
