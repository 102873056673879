/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import { Modal, Button, Tab, Form, Input } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { selectedImageState } from "../db/elementDb";
import log from "cslog";
import { FAS, REGULAR, BRANDS } from "../data/faicons";
import startCase from "lodash/startCase";
import { FixedSizeGrid as Grid } from "react-window";

function IconCell({ item, style, prefix, setIcon, active }) {
    if (!item) {
        return null;
    }
    return (
        <div style={style}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100px;
                    gap: 10px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-radius: 5px;
                    outline: none;
                    user-select: none;
                    background: ${active && "lightgreen"};
                    :hover {
                        cursor: pointer;
                        background: cyan;
                    }
                    :focus {
                        background: lightgreen;
                    }
                `}
                onClick={() => {
                    setIcon({
                        params: {
                            type: prefix,
                            name: item,
                        },
                        value: `${prefix} fa-${item}`,
                    });
                }}
            >
                <span
                    style={{
                        fontSize: "40px",
                        color: "#505050",
                    }}
                >
                    <i className={`${prefix} fa-${item}`}></i>
                </span>
                <span
                    style={{
                        color: "gray",
                        fontSize: "0.8rem",
                        textAlign: "center",
                    }}
                >
                    {startCase(item)}
                </span>
            </div>
        </div>
    );
}

const IconGrid = ({ data, classPrefix, setIcon, selectedIcon }) => {
    const [searchText, setSearchText] = useState("");
    const [iconset, setIconset] = useState(data);

    useEffect(() => {
        if (searchText.length >= 1) {
            setIconset(data.filter((item) => item.includes(searchText)));
        } else {
            setIconset(data);
        }
    }, [searchText, data]);

    return (
        <div
            style={{
                height: "70vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
            }}
        >
            <Input
                type="text"
                placeholder="Search Icon"
                style={{ marginBottom: "5px", width: "50%" }}
                icon="search"
                value={searchText}
                onChange={(eve) => setSearchText(eve.target.value)}
            />
            {iconset.length <= 0 && (
                <div
                    style={{
                        color: "gray",
                        fontSize: "1.1rem",
                        marginTop: "20px",
                    }}
                >
                    No matching icon found
                </div>
            )}
            <Grid
                rowCount={Math.ceil(iconset.length / 5)}
                columnCount={5}
                width={780}
                columnWidth={150}
                height={450}
                rowHeight={150}
            >
                {/* {IconCell} */}
                {({ columnIndex, rowIndex, style }) => (
                    <IconCell
                        item={iconset[rowIndex * 5 + columnIndex]}
                        style={style}
                        prefix={classPrefix}
                        setIcon={setIcon}
                        active={
                            selectedIcon.params.name ===
                            iconset[rowIndex * 5 + columnIndex]
                        }
                    />
                )}
            </Grid>
        </div>
    );
};

const IconDialog = ({ setIcon, label, button }) => {
    const [open, setOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState({
        params: { type: "fa", name: "none" },
        value: "none",
    });

    const isSelected = selectedIcon === undefined;

    const panes = [
        {
            menuItem: {
                key: "solid",
                icon: "globe",
                content: "Solid",
            },
            render: () => (
                <IconGrid
                    data={FAS}
                    classPrefix="fa"
                    setIcon={(icon) => setSelectedIcon(icon)}
                    selectedIcon={selectedIcon}
                />
            ),
        },
        {
            menuItem: { key: "regular", icon: "images", content: "Regular" },
            render: () => (
                <IconGrid
                    data={REGULAR}
                    classPrefix="far"
                    setIcon={(icon) => setSelectedIcon(icon)}
                    selectedIcon={selectedIcon}
                />
            ),
        },
        {
            menuItem: { key: "brand", icon: "upload", content: "Brands" },
            render: () => (
                <IconGrid
                    data={BRANDS}
                    classPrefix="fab"
                    setIcon={(icon) => setSelectedIcon(icon)}
                    selectedIcon={selectedIcon}
                />
            ),
        },
    ];

    return (
        <Modal
            size="large"
            open={open}
            // trigger={<Button primary>Select Photo</Button>}
            trigger={
                <Form.Field>
                    {label && <label>{label}</label>}
                    {button ? button : <Button primary>Change Image</Button>}
                </Form.Field>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {/* <Modal.Header>Select Image</Modal.Header> */}
            <Modal.Content>
                {/* <Modal.Content scrolling> */}
                <Tab
                    menu={{ fluid: true, vertical: true, tabular: true }}
                    panes={panes}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    positive
                    disabled={isSelected}
                    onClick={() => {
                        log.d(selectedIcon, "SELECTED ICON");
                        setIcon(selectedIcon);
                        setOpen(false);
                    }}
                >
                    Proceed
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default IconDialog;
