import React, { useState } from "react";
import { Button, Input } from "semantic-ui-react";
import GalleryView from "./GalleryView";

import { useRecoilState } from "recoil";
import { selectedImageState } from "../db/elementDb";

const TEXTURES = [
    "http://www.transparenttextures.com/patterns/3px-tile.png",
    "http://www.transparenttextures.com/patterns/45-degree-fabric-dark.png",
    "http://www.transparenttextures.com/patterns/45-degree-fabric-light.png",
    "http://www.transparenttextures.com/patterns/60-lines.png",
    "http://www.transparenttextures.com/patterns/absurdity.png",
    "http://www.transparenttextures.com/patterns/ag-square.png",
    "http://www.transparenttextures.com/patterns/always-grey.png",
    "http://www.transparenttextures.com/patterns/arabesque.png",
    "http://www.transparenttextures.com/patterns/arches.png",
    "http://www.transparenttextures.com/patterns/argyle.png",
];

const Texture = ({ placeholder, multiple = false }) => {
    const [term, setTerm] = useState("nature");
    const [data, setData] = useState();
    const [search, setSearch] = useState("");

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);

    React.useEffect(() => {
        setData(
            TEXTURES.map((item) => ({
                id: item,
                width: 100,
                height: 100,
                description: "Something",
                thumb: item,
                regular: item,
                small: item,
            }))
        );
        // log.d(term, "Now searching");
        // API.get("/search/photos", {
        //     params: { query: term },
        // })
        //     .then((response) => {
        //         console.log(response.data);
        //         // if (!data) {
        //         setData(response.data.results);
        //         // }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        // // eslint-disable-next-line
    }, []);

    return (
        <div style={{ zIndex: 5000 }}>
            {data ? (
                <div>
                    {/* <Input
                        placeholder="subdomain"
                        value={term}
                        onChange={(eve) => setTerm(eve.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setSearch(term);
                            }
                        }}
                        fluid
                        action={
                            <Button
                                positive
                                onClick={() => setSearch(term)}
                                loading={false}
                            >
                                Search
                            </Button>
                        }
                    /> */}
                    {/* <Divider /> */}
                    <div
                        style={{
                            height: "70vh",
                            overflow: "auto",
                            marginTop: "10px",
                        }}
                    >
                        <GalleryView
                            data={data}
                            multiple={multiple}
                            makeThumb={(item, index) => {
                                return {
                                    src: item.thumb,
                                    srcRegular: item.regular,
                                    width: item.width,
                                    height: item.height,
                                    id: item.id,
                                    dindex: index,
                                };
                            }}
                            makeImgObj={(item) => {
                                return {
                                    id: item.id,
                                    width: item.width,
                                    height: item.height,
                                    description: item.description,
                                    thumb: item.thumb,
                                    full: item.regular,
                                    small: item.small, //optional
                                };
                            }}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                </div>
            ) : (
                placeholder
            )}
        </div>
    );
};

export default Texture;
