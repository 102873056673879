import axios from 'axios'
import { useMutation, useQuery } from "react-query";
import { restoreAccessToken, secureAxios } from "../../_helper/auth";
import { queryClient } from "../../_helper/query";
import log from "cslog"

const aiAxios = axios.create({
	baseURL: 'https://botapi.qureal.com/',
	headers: {
		'Content-Type': 'application/json',
		"Authorization": `Bearer ${restoreAccessToken()}`
	}
})

export const useUploadScreenshot = (onDone) => {
	return useMutation(
		(newItem) => {
			return secureAxios.post("/files", newItem).then((res) => res.data);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("templates");
				onDone(data);
			},
		}
	);
};

export const useUploadFile = () => {
	return useMutation(
		(newItem) => {
			return secureAxios.post("/files", newItem).then((res) => res.data);
		},
		{
			onSuccess: () => queryClient.invalidateQueries("files"),
		}
	);
};

export const useAutoTagSvg = (filename, enabled) => {
	return useQuery(
		["ai", "tags", filename],
		() =>
			aiAxios
				.post(`/image/get_tags`, { svg_filename: filename })
				.then((res) => res.data),
		{
			enabled: enabled,
		}
	);
}




export const useUploadPhoto = (onDone = () => { }, auto_tag = false) => {
	return useMutation(
		(newItem) => {
			log.p(`Uploading with auto_tag ${auto_tag ? "Yes" : "No"}`);
			// if (auto_tag) {
			// 	return aiAxios.post(`/image/upload_svg_with_tags`, newItem).then((res) => res.data);
			// } else {
			return secureAxios.post("/files", newItem).then((res) => res.data);
			// }
		},
		{
			onSuccess: () => queryClient.invalidateQueries("photos"),
			onSettled: (data) => {
				onDone(data);
			},
		}
	);
};

export const useArchiveFile = () => {
	return useMutation(
		(id) => {
			return secureAxios.patch(`/files/${id}`, {
				archived: true,
			});
		},
		{
			onSuccess: () => queryClient.invalidateQueries("photos"),
		}
	);
};

export const useRestoreFile = () => {
	return useMutation(
		(id) => {
			return secureAxios.patch(`/files/${id}`, {
				archived: false,
			});
		},
		{
			onSuccess: () => queryClient.invalidateQueries("photos"),
		}
	);
};

export const useDeleteFile = () => {
	return useMutation(
		(id) => {
			return secureAxios.delete(`/files/${id}`);
		},
		{
			onSuccess: () => queryClient.invalidateQueries("photos"),
		}
	);
};

export const useCreateFolder = () => {
	return useMutation(
		(newItem) => {
			return secureAxios
				.post("/folders", newItem)
				.then((res) => res.data);
		},
		{
			onSuccess: () => queryClient.invalidateQueries("folders"),
		}
	);
};
