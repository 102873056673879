import PRIMARY from "./primary";
import WIDGETS from "./widgets";
import CHARTS from "./charts";

const ELEMENTS = {
    ...PRIMARY,
    ...WIDGETS,
    ...CHARTS,
};

export { PRIMARY, WIDGETS, ELEMENTS };
