export const EDITOR_ICONS = {
    header: {
        vb: "0 0 253 252.7",
        path: "M244,9V61.7H151V243.6H98.3a2,2,0,0,0,.1-.7c0-.5-.1-1.1-.1-1.6V61.7H9V9H244m9-9H0V69.8H0V80.3a9.6,9.6,0,0,1,9-9.6H89.3V241.8c0,.4.1.7.1,1.1s-.1.8-.1,1.2a9.6,9.6,0,0,1-9.6,8.4H90.3l68.7.2h10.6a9.6,9.6,0,0,1-9.6-8.8V70.7h84a9.6,9.6,0,0,1,9,9.6V69.8h0V0ZM89.3,241.8v2.3c0-.4.1-.8.1-1.2s-.1-.7-.1-1.1Zm70.7.3v0ZM90.3,252.5h0v-.2Zm69.1.1h0Z",
    },
    button: {
        vb: "0 0 348.7 262.1",
        path: "M161.5,145.9H19.5a15,15,0,0,1-15-15V19.5a15,15,0,0,1,15-15H329.2a15,15,0,0,1,15,15V130.9a15,15,0,0,1-15,15H201.7a8.9,8.9,0,0,0-9.9,0h-6.6m67.7,33.3v15.2c0,47.1-22.5,63.4-52.7,63.2h-1.7c-14.7-.6-29.7-15.9-39.3-27l-7-8.2-26.8-28.7c-5.6-6.5-6.2-15.2-1.2-19.5a10.7,10.7,0,0,1,9.3-2.1,18.7,18.7,0,0,1,4.1,1.5,21.7,21.7,0,0,1,5.7,4.5l18.2,20.8V111c0-8.6,5.3-15.5,11.9-15.5a10.5,10.5,0,0,1,8.3,4.5,17.8,17.8,0,0,1,3.5,11v48.9c0,56.6.2-3.1.5-4.6a15.5,15.5,0,0,1,6.1-9.4,8.9,8.9,0,0,1,9.9,0,11.8,11.8,0,0,1,3.2,3.1,17.4,17.4,0,0,1,3.4,10.6v25.1c.1-.8.2-23.9.4-24.8,1.5-6.3,5.9-11,11.1-11,3.2,0,6.1,1.8,8.2,4.6a18.4,18.4,0,0,1,3.4,10.9v21c0-1.5.2-15.6.5-16.9,1.4-5.7,5.3-9.8,10-9.8a9.2,9.2,0,0,1,7.4,4.1,15.5,15.5,0,0,1,2.7,6.1v.6a.6.6,0,0,0,.1.4A56.5,56.5,0,0,1,252.9,179.2Z",
    },
    video: {
        vb: "0 0 337.6 252.4",
        path: "M328.6,9V243.4H9V9H328.6M140.5,193.3a24,24,0,0,0,11.9-3.3l74.5-43a23.9,23.9,0,0,0,0-41.5l-74.5-43a23,23,0,0,0-11.9-3.3,24.1,24.1,0,0,0-24.1,24.1v85.9a24.1,24.1,0,0,0,24.1,24.1M337.6,0H0V252.4H337.6V0ZM140.5,184.3a15.1,15.1,0,0,1-15.1-15.1V83.3a15.1,15.1,0,0,1,15.1-15.1,14.6,14.6,0,0,1,7.4,2.1l74.5,43a14.9,14.9,0,0,1,0,25.9l-74.5,43a14.6,14.6,0,0,1-7.4,2.1Z",
    },
    section: {
        vb: "0 0 535.3 253",
        path: "M526.3,9V244H402.8V9H526.3m-197,0V244H205.9V9H329.3M132.4,9V244H9V9H132.4M535.3,0H393.8V253H535.3V0Zm-197,0H196.9V253H338.3V0ZM141.4,0H0V253H141.4V0Z",
    },
    richtext: {
        vb: "0 0 450.9 206",
        path: "M42,4.5H337.6M91.5,201.5H337.6M89.3,162.4H324.2M0,43.7H324.6M0,82.9H269.3M0,122.2H253M351.1,21.7,335.4,86.5l-66.1,17,66.1,16.6,15.7,65.2,16.2-65.6L433,103.5,368.8,85.9Z",
    },
    p: {
        vb: "0 0 337.6 209",
        path: "M42,4.5H337.6M91.5,204.5H337.6M89.3,165.4H335.4M0,43.7H337.6M0,82.9H337.6M0,122.2H337.6",
    },
    link: {
        vb: "0 0 337.6 209",
        path: "M42,4.5H337.6M91.5,204.5H337.6M89.3,165.4H335.4M0,43.7H337.6M0,82.9H337.6M0,122.2H337.6",
    },
    img: {
        vb: "0 0 346.6 261.4",
        path: "M342.1,256.9H4.5V4.5H342.1Zm-4.2-68.4L271.8,97.6A15,15,0,0,0,249,95.9l-65.9,66.2a15,15,0,0,1-17.8,2.6L84.9,120.5a15.2,15.2,0,0,0-18.3,3L8.4,186.9M182.5,36.6a37.3,37.3,0,1,0,37.3,37.3A37.3,37.3,0,0,0,182.5,36.6Z",
    },
    list: {
        vb: "0 0 277.6 242.5",
        path: "M58.5,31.5a27,27,0,1,1-27-27A27,27,0,0,1,58.5,31.5Zm-27,63.2a27,27,0,1,0,27,27A27,27,0,0,0,31.5,94.7Zm0,89.3a27,27,0,1,0,27,27A27,27,0,0,0,31.5,184ZM82,32.3H277.6M80,209.6H275.7M81.2,118.8H276.8",
    },
    divider: {
        vb: "0 0 399.1 252.8",
        path: "M326.1,0V26.9c0,9.6-6.7,17.4-15,17.4H88.1c-8.3,0-15-7.8-15-17.4V0m253,252.8v-27c0-9.5-6.7-17.3-15-17.3H88.1c-8.3,0-15,7.8-15,17.3v27M218.6,126.4H399.1M0,126.6H179.6m20-10.3a10.1,10.1,0,1,0,10,10.1A10.1,10.1,0,0,0,199.6,116.3Z",
    },
    blank: {
        vb: "0 0 262 252.8",
        path: "M257.5,0V26.9c0,9.6-6.7,17.4-15,17.4H19.5c-8.3,0-15-7.8-15-17.4V0m253,252.8v-27c0-9.5-6.7-17.3-15-17.3H19.5c-8.3,0-15,7.8-15,17.3v27M130.8,51.2V202.3M157.6,87.9,130.7,50.4,104.4,87.1m0,78.9,26.9,37.5,26.3-36.7",
    },
    icon: {
        vb: "0 0 253.3 253.3",
        path: "M244.3,9V244.3H9V9H244.3M79.3,222.5a20.1,20.1,0,0,0,12-4l33.1-24.2a2.1,2.1,0,0,1,1.5-.5,2.8,2.8,0,0,1,1.3.3l35.2,21.1a20.5,20.5,0,0,0,27.2-5.7,19.9,19.9,0,0,0,2.8-18.3l-12.8-39a2.6,2.6,0,0,1,.8-2.7l30.9-26.9a20.5,20.5,0,0,0-13.5-36H156.7a2.5,2.5,0,0,1-2.3-1.5L138.3,47.5a20.5,20.5,0,0,0-38.4,1.8l-12.6,39A2.4,2.4,0,0,1,85.1,90L44.3,93.7a20.5,20.5,0,0,0-10.2,37.1l33.3,23.9a2.8,2.8,0,0,1,1,2.7l-9.2,39.9a20.2,20.2,0,0,0,3.9,17.3,20.9,20.9,0,0,0,16.2,7.9M253.3,0H0V253.3H253.3V0ZM79.3,213.5A11.6,11.6,0,0,1,68,199.3l9.1-39.9a11.4,11.4,0,0,0-4.5-12L39.4,123.5a11.6,11.6,0,0,1,5.7-20.9L85.9,99a11.5,11.5,0,0,0,10-8l12.5-39A11.5,11.5,0,0,1,130,51l16.1,37.7a11.4,11.4,0,0,0,10.6,7h41.1a11.5,11.5,0,0,1,7.5,20.2l-30.9,26.9a11.4,11.4,0,0,0-3.3,12.3l12.8,39a11.5,11.5,0,0,1-11,15.1,11.9,11.9,0,0,1-5.9-1.6l-35.2-21.1a11.4,11.4,0,0,0-5.9-1.6,11.3,11.3,0,0,0-6.8,2.2L86,211.2a11.1,11.1,0,0,1-6.7,2.3Z",
    },
    iconList: {
        vb: "0 0 296.4 214",
        path: "M52.1,61.8,41.3,55.3a3.6,3.6,0,0,0-4,.2L27.2,62.9a3.5,3.5,0,0,1-5.6-3.6L24.4,47a3.5,3.5,0,0,0-1.3-3.7L12.8,35.9a3.6,3.6,0,0,1,1.8-6.4l12.5-1.1A3.5,3.5,0,0,0,30.2,26l3.9-12a3.5,3.5,0,0,1,6.6-.3l4.9,11.6a3.6,3.6,0,0,0,3.3,2.1H61.5a3.6,3.6,0,0,1,2.4,6.2l-9.5,8.3a3.6,3.6,0,0,0-1.1,3.8l4,11.9A3.6,3.6,0,0,1,52.1,61.8ZM72.6,72.6V3.3H3.3V72.6m54,55.6-4-12a3.6,3.6,0,0,1,1.1-3.8l9.5-8.3a3.6,3.6,0,0,0-2.4-6.2H48.9a3.7,3.7,0,0,1-3.3-2.2L40.7,84.2a3.5,3.5,0,0,0-6.6.3l-3.9,12A3.6,3.6,0,0,1,27.1,99l-12.5,1.1a3.6,3.6,0,0,0-1.8,6.4l10.3,7.4a3.3,3.3,0,0,1,1.3,3.6l-2.8,12.3a3.6,3.6,0,0,0,5.6,3.7L37.3,126a3.8,3.8,0,0,1,4-.2l10.8,6.5A3.5,3.5,0,0,0,57.3,128.2Zm15-55H3.6V142H72.3ZM57.3,197l-4-12a3.7,3.7,0,0,1,1.1-3.8l9.5-8.3a3.6,3.6,0,0,0-2.4-6.2H48.9a3.7,3.7,0,0,1-3.3-2.2L40.7,153a3.5,3.5,0,0,0-6.6.3l-3.9,12a3.6,3.6,0,0,1-3.1,2.5l-12.5,1.1a3.6,3.6,0,0,0-1.8,6.4l10.3,7.4a3.3,3.3,0,0,1,1.3,3.6l-2.8,12.3a3.6,3.6,0,0,0,5.6,3.7l10.1-7.5a3.8,3.8,0,0,1,4-.2l10.8,6.5A3.5,3.5,0,0,0,57.3,197ZM3.5,141.8v68.9H72.4V141.8M100.7,36.9H296.4M98.8,178.9H294.5M100,105.5H295.6",
    },
    navMenu: {
        vb: "0 0 451.8 236.9",
        path: "M304.7,4.5H446.2M304.7,37.7H446.2M304.7,70.9H446.2M296.9,179.1V133.9A7.9,7.9,0,0,0,289,126H163.2a7.9,7.9,0,0,0-7.9,7.9v45.2a8,8,0,0,0,7.9,8h57.7v-3.7H163.2a4.2,4.2,0,0,1-4.2-4.3V133.9a4.3,4.3,0,0,1,4.2-4.3H289a4.3,4.3,0,0,1,4.2,4.3v45.2a4.2,4.2,0,0,1-4.2,4.3H237.7a5.5,5.5,0,0,0-5,0h-2.2v3.7h3.2l.5-.3a1.7,1.7,0,0,1,2,0l.5.3H289A8,8,0,0,0,296.9,179.1ZM146.1,133.9v45.2a8,8,0,0,1-8,8H12.4a8,8,0,0,1-7.9-8V133.9a7.9,7.9,0,0,1,7.9-7.9H138.1A7.9,7.9,0,0,1,146.1,133.9Zm-3.7,0a4.3,4.3,0,0,0-4.3-4.3H12.4a4.3,4.3,0,0,0-4.2,4.3v45.2a4.2,4.2,0,0,0,4.2,4.3H138.1a4.3,4.3,0,0,0,4.3-4.3Zm304.9,0v45.2a8,8,0,0,1-8,8H313.6a8,8,0,0,1-7.9-8V133.9a7.9,7.9,0,0,1,7.9-7.9H439.3A7.9,7.9,0,0,1,447.3,133.9Zm-3.7,0a4.3,4.3,0,0,0-4.3-4.3H313.6a4.3,4.3,0,0,0-4.2,4.3v45.2a4.2,4.2,0,0,0,4.2,4.3H439.3a4.3,4.3,0,0,0,4.3-4.3ZM252.8,226.6c4.7-4.6,7.1-11.9,7.1-21.7v-6.2a27.1,27.1,0,0,0-.4-4v-.5A7.1,7.1,0,0,0,258,191a5.7,5.7,0,0,0-4.5-2.4,5.2,5.2,0,0,0-2.9.9,10.1,10.1,0,0,0-1.2-2.3,6.2,6.2,0,0,0-4.8-2.6,5.6,5.6,0,0,0-4,1.7l-.6-.9a5.8,5.8,0,0,0-1.8-1.7,5.3,5.3,0,0,0-3-.9,6,6,0,0,0-2.9.8V171.1a9.5,9.5,0,0,0-1.7-5.6,6.4,6.4,0,0,0-4.9-2.6c-3.6,0-6.6,3.7-6.6,8.2v30.8l-4.2-4.8a10.5,10.5,0,0,0-2.9-2.3,8.6,8.6,0,0,0-2.1-.7,6,6,0,0,0-5.4,1.2c-2.8,2.4-2.6,7,.4,10.5l10.8,11.7,2.9,3.3c4.2,4.8,10.6,11.3,17.3,11.6h1C243.6,232.4,249,230.4,252.8,226.6Zm-45.9-28.5a2.5,2.5,0,0,1,2.2-.5l1.3.5a5.5,5.5,0,0,1,1.7,1.4l10.6,12.1V171.1c0-2.5,1.4-4.5,3-4.5a2.8,2.8,0,0,1,2,1.1,6.2,6.2,0,0,1,1,3.4v29.6h0c.2,1.6,1.2,1.9,1.8,1.9a1.8,1.8,0,0,0,1.9-2v-3.3c0-2.7.1-7.1.1-7.9a4.1,4.1,0,0,1,1.7-2.6,1.8,1.8,0,0,1,2,0,3.2,3.2,0,0,1,.9.8,5.5,5.5,0,0,1,1,3.2V201l3.6.2c0-.2.1-.7.1-3.7s0-5.6.1-6.3,1.5-2.9,2.7-2.9a2.8,2.8,0,0,1,1.9,1.1,6.2,6.2,0,0,1,1,3.4v8.5h3.6c0-1,.1-5.7.2-6.4s1.3-2.6,2.2-2.6a2.1,2.1,0,0,1,1.6,1,4.4,4.4,0,0,1,.7,1.7v.2h0a19.5,19.5,0,0,1,.3,3.4v6.2c0,8.8-2,15.2-5.9,19.1s-7.8,4.8-13.7,4.7H236c-5.9-.2-12.5-7.8-14.7-10.3l-2.8-3.3-10.9-11.7C206,201.5,205.7,199.1,206.9,198.1Zm44.4-3.3Zm-9.4-3.5Zm-9.4-1.8h0Z",
    },
    audioPlayer: {
        vb: "0 0 197.79 253.48",
        path: "M98.38,78.46,28.27,38A15.85,15.85,0,0,0,4.5,51.7V233.11a15.84,15.84,0,0,0,23.77,13.72l157.1-90.7a15.85,15.85,0,0,0,0-27.45L125.42,94.07M112.26.78s-5,28.34,19,41.44c24.59,13.41,22.26,31.88,20,43.69m-39-85V125.26m0-2.06c0,13-12.3,23.62-27.46,23.62S57.38,136.25,57.38,123.2,69.67,99.58,84.84,99.58,112.3,110.16,112.3,123.2Z",
    },
    viewer3dd: {
        vg: "0 0 231.69 236.28",
        path: "M113.19,231.38,8,185.37a2.5,2.5,0,0,1-1.5-2.25L4.5,51.87A2.5,2.5,0,0,1,8,49.55l105.19,46a2.52,2.52,0,0,1,1.5,2.25l2,131.25A2.5,2.5,0,0,1,113.19,231.38Z",
    },
    qrCodee: {
        vg: "0 0 252.97 252.97",
        path: "M48.92,65.85V53H61.75V65.85Zm66.87-36.24v-6h-6v6Zm24.29,0v-6h-6v6ZM127.65,42V36h-6v6ZM115.77,67.26v-6h-6v6Zm26.61,7.61v-6h-6v6Zm-14.73,54.25v-6h-6v6Zm49.38,19v-6h-6v6Zm-49.38,33.94v-6h-6v6Zm90.13,1v-6h-6v6ZM115.51,170.07v-6h-6v6Zm62,12v-6h-6v6Zm0,11.21v-6h-6v6Zm-32.08,37.75v-6h-6v6Zm11.82,0v-6h-6v6Zm60.53,0v-6h-6v6Zm11.82,0v-6h-6v6Zm-96.31-12v-6h-6v6Zm-30.08-114V99h-6v6Zm-29.2,0V99H68v6ZM27.65,142.11v-6h-6v6Zm11.64,0v-6h-6v6ZM69.56,130.5v-6h-6v6ZM28.14,117.16v-6h-6v6Zm53.18,25v-6h-6v6Zm58-24.29v-6h-6v6Zm67.75-12v-6h-6v6Zm-11.59,11.36v-6h-6v6ZM230,127.48v-6h-6v6Zm0,44.22v-6h-6v6Zm-12.24,0v-6h-6v6ZM153.73,74.87v-6h-6v6ZM90.11,92.21v-67h-67v67ZM61.75,203.69V190.86H48.92v12.83Zm28.36,26.36v-67h-67v67ZM200.67,65.85V53H187.83V65.85ZM229,92.21v-67H162v67ZM248.47,4.5H4.5v244h244Z",
    },
    codeview: {
        vb: "0 0 432.11 247.95",
        path: "M423.11,9V239H9V9H423.11M314.43,100.64q-8.12-12.21-23.58-12.21-13.62,0-21.56,10.55t-8,28.35v1q0,18.06,8.09,28.69a25.44,25.44,0,0,0,21.28,10.63q15.75,0,23.86-11.58l.35,10.17h7.91V57.79h-8.4v42.85m60.22,67q17.72,0,27-13.49l-5.3-4A26.57,26.57,0,0,1,387,158a27.64,27.64,0,0,1-12,2.47,23.7,23.7,0,0,1-18.74-8.65q-7.45-8.66-7.45-21.64V128.6h54.5v-4.73q0-16.45-8.15-25.95t-22.07-9.49a29.3,29.3,0,0,0-16.51,5,33.94,33.94,0,0,0-11.9,13.87,44.89,44.89,0,0,0-4.27,19.84v3a41.69,41.69,0,0,0,4.38,19.24A32.69,32.69,0,0,0,357,162.84a33.18,33.18,0,0,0,17.62,4.8m-161.38,0a33,33,0,0,0,17.72-4.8,31.91,31.91,0,0,0,12-13.66,45.34,45.34,0,0,0,4.27-20v-1.76q0-17.65-9.46-28.35t-24.7-10.69a32.09,32.09,0,0,0-29.76,18.64,44.88,44.88,0,0,0-4.27,19.83v1.7q0,17.64,9.49,28.34t24.68,10.7m-75.75,0a32.26,32.26,0,0,0,14.29-3.25,27.32,27.32,0,0,0,10.7-9,24,24,0,0,0,4.38-12.74h-8.12a17.5,17.5,0,0,1-6.46,12.88,22.31,22.31,0,0,1-14.79,5q-11.51,0-17.83-8.19t-6.31-22.8V127q0-14.88,6.31-23.15t17.76-8.26a20.93,20.93,0,0,1,14.86,5.51q5.9,5.51,6.46,14.47h8.12q-.57-12.21-8.72-19.7t-20.72-7.48a31.3,31.3,0,0,0-17.26,4.73,30.38,30.38,0,0,0-11.33,13.41,47.69,47.69,0,0,0-4,20v2.54q0,17.66,8.79,28.1t23.83,10.45M32.12,149.92l59.65-26.76v-6.85L32.12,89.63v8.68L82.31,119.7,32.12,141.16v8.76m341-54.29a19.51,19.51,0,0,1,15.54,6.92q5.92,6.92,6.28,17.93v.92H349.09q1.41-11.79,7.94-18.78a21.13,21.13,0,0,1,16.06-7m-160,0a23.28,23.28,0,0,1,13.41,4,26.1,26.1,0,0,1,9.11,11.26,38.94,38.94,0,0,1,3.24,16v1.63q0,14.4-7.09,23.19a24,24,0,0,1-37.13,0q-7.09-8.79-7.1-22.49v-1.55q0-14.33,7.13-23.19a22.57,22.57,0,0,1,18.43-8.86m79.27.14q15.47,0,22,14.9v35.86q-6.57,13.77-22.17,13.77-10.65,0-16.52-8.44t-5.86-23.05q0-16.31,5.93-24.67t16.59-8.37M432.11,0H0V248H432.11V0Z",
    },
    gallery: {
        vb: "0 0 322.85 252.74",
        path: "M296.26,222.66H4.5V4.5H296.26Zm-.67-54.46L235.48,85a13,13,0,0,0-19.67-1.51L158.86,140.7a13,13,0,0,1-15.43,2.22L74,104.78a13,13,0,0,0-15.78,2.59L4.93,165.19M158.32,32.26a32.25,32.25,0,1,0,32.24,32.25A32.25,32.25,0,0,0,158.32,32.26ZM297.6,30.08h20.75V248.24H26.59V220.69",
    },
    map: {
        vb: "0 0 196.99 261.31",
        path: "M54.92,197.44,185.7,64.84M122.19,8.58,9.37,123.22m58.36-59.3-33-33M102.19,4.57c-53.6-2-97.69,40-97.69,92.12a90.51,90.51,0,0,0,17.71,53.87,635.26,635.26,0,0,1,36.16,54l26.1,44.33a16.35,16.35,0,0,0,28.05,0l31.4-53.33a416.46,416.46,0,0,1,29.55-43.22,90.62,90.62,0,0,0,19-55.78C192.41,47.31,152.34,6.45,102.19,4.57Zm-4.1,46.18a43.19,43.19,0,1,0,43.19,43.19A43.18,43.18,0,0,0,98.09,50.75Z",
    },
    imageCarousel: {
        vb: "0 0 476.54 254.38",
        path: "M398.83,249.87l-326.78-1L72.81,4.51l326.78,1Zm.68-217.57,36.17.11-.59,190.8-36.17-.11M435.86,54.39,472,54.5l-.45,146.85-36.17-.12M40.68,200,4.51,199.9,5,53.05l36.17.11M73.41,222.09,36,222l.59-190.8L74,31.29",
    },
    verticalMenu: {
        vb: "0 0 332.28 250.72",
        path: "M327.78,246.22H4.5V4.5H327.78ZM80.87,59H265.73m0,67.16H80.87m0,67.17H265.73",
    },
    typingText: {
        vb: "0 0 256.89 252.38",
        path: "M55,67.16H45.33L0,187H10.61L23,153.3H77.34L89.76,187h10.53ZM26.08,144.74l24-65.24,24,65.24Zm164.26-9.92a28.73,28.73,0,0,0-16.83-10.28,27.72,27.72,0,0,0,13.87-9.84,26.55,26.55,0,0,0,5.06-16.18q0-15.47-10-23.42t-29.2-7.94H117.9V187H158q18.27,0,28.51-8.92t10.25-24.79A28.64,28.64,0,0,0,190.34,134.82ZM128,75.72h25.26q14.55,0,21.76,5.48t7.2,17q0,11-7,16.69t-19.62,6H128Zm51,96.06q-7.62,6.61-20.61,6.61H128V129.44H159.2q12.84,0,20.11,6.45t7.29,17.53Q186.6,165.17,179,171.78ZM256.42,9h-61V0h61Zm-26,238.67h-9V3.34h9Zm26.45,4.71h-61v-9h61Z",
    },
    formContact: {
        vb: "0 0 262.69 254.37",
        path: "M25.94,37.73H137.77M25.94,189.86H95M25.91,160.09h93.34M25.94,67.56H179.7M25.94,97.4H179.7M25.94,127.23H149.66m51.48-70.91V250.21L4.5,249.82V4.41l143.79.11Zm52.79,23L133,223.44l-31.44,10.69a.33.33,0,0,1-.43-.35l4-33.75L226,55.89a18.22,18.22,0,0,1,25.66-2.24h0A18.21,18.21,0,0,1,253.93,79.31ZM207.65,76.7l27.92,23.43m-40-11.36L223.5,112.2M122.3,179.74l27.92,23.44",
    },
};
