import React from "react";
import { Transforms } from "slate";
import { ReactEditor, useFocused, useSelected, useSlate } from "slate-react";

const Image = ({ attributes, children, element, style }) => {
    const editor = useSlate();
    // const editor = useSlate();
    const path = ReactEditor.findPath(editor, element);

    const selected = useSelected();
    const focused = useFocused();

    console.log("selected", selected);
    console.log("focused", focused);

    return (
        <div
            {...attributes}
            style={{
                textAlign: "right",
            }}
        >
            {children}
            <div
                contentEditable={false}
                style={{
                    position: "relative",
                    // padding: "10px 0px",
                }}
            >
                <img
                    src={element.url}
                    style={{
                        display: "block",
                        maxWidth: "100%",
                        maxHeight: "20rem",
                        boxShadow:
                            selected && focused ? "0 0 0 3px #B4D5FF" : "none",
                    }}
                    alt="preview"
                />
                <div
                    onClick={() => Transforms.removeNodes(editor, { at: path })}
                    style={{
                        position: "absolute",
                        top: "0.5em",
                        left: "0.5em",
                        display: selected && focused ? "inline" : "none",
                        fontSize: "18px",
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "3px 6px",
                        cursor: "pointer",
                    }}
                >
                    <i className="fas fa-trash" />
                </div>
            </div>
        </div>
    );
};

export default Image;
