import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { useFiles } from "../actions/assets";
import FolderStructure from "./FolderStructure";
import log from "cslog";
import DataGridView from "./DataGridView";
import { useRecoilState } from "recoil";
import { selectedFileState } from "../db/elementDb";

const BE_TYPES = {
    audio: "audio",
    file3d: "application",
    pdf: "application/pdf",
    any: null,
};

const ACCEPT_TYPES = {
    audio: "audio/*",
    file3d: "*",
    pdf: ".pdf",
    any: "*",
};

function FilePicker({ label, button, onFile, type }) {
    const [open, setOpen] = useState(false);
    const [folder, setFolder] = useState(null);
    const {
        data: dataFiles,
        isLoading: loadingFiles,
        isSuccess: successFiles,
    } = useFiles(folder, BE_TYPES[type]);
    const [selectedFile, setSelectedFile] = useRecoilState(selectedFileState);

    log.d(dataFiles, "Files");

    const isSelected =
        selectedFile?.id !== undefined &&
        (!BE_TYPES[type] || selectedFile?.type?.startsWith(BE_TYPES[type]));

    return (
        <Modal
            size="large"
            open={open}
            // trigger={<Button primary>Select Photo</Button>}
            trigger={
                <Form.Field>
                    {label && <label>{label}</label>}
                    {button ? button : <Button primary>Change File</Button>}
                </Form.Field>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{
                zIndex: 5000,
                backgroundColor: "yellow",
            }}
        >
            <Modal.Content
                style={{ height: "80vh", overflow: "hidden", zIndex: 5000 }}
            >
                <FolderStructure
                    acceptTypes={ACCEPT_TYPES[type]}
                    folder={folder}
                    setFolder={setFolder}
                />
                <DataGridView
                    data={dataFiles}
                    loading={loadingFiles}
                    success={successFiles}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    positive
                    disabled={!isSelected}
                    onClick={() => {
                        if (isSelected) {
                            onFile(selectedFile);
                        }
                        setOpen(false);
                    }}
                >
                    Proceed
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default FilePicker;
