import log from "cslog";
import startCase from "lodash/startCase";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useCreateItem } from "./actions/mutations/items";
import Loading from "./Loading";
import { useSingleItem } from "./actions/items";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";

function useQueryParams() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CreateNewFromTemplate() {
	const { tid } = useParams();
	const [template_id, setTemplate_id] = useState(tid);
	const query = useQueryParams();
	const {
		data: dataSingle,
		isSuccess: successSingle,
		isLoading: loadingSingle,
	} = useSingleItem("qdb_creation_template", `/${tid}`, tid !== null);
	const mutation = useCreateItem("qp_creation");

	log.d(mutation, "Mutation now");

	useEffect(() => {
		if (template_id) {
			if (successSingle && dataSingle) {
				log.d(dataSingle, "Cloning from");
				const newData = cloneDeep(dataSingle.data);
				newData["slug"] = `${newData["slug"]}_${nanoid(5)}`;
				log.d(newData, "New Data for Creation Template");
				mutation.mutate({
					...newData,
					from_id: template_id,
				});
				setTemplate_id(null);
			}
		}
	}, [template_id, successSingle]);

	const params = useMemo(() => {
		let out = {};
		Array.from(query.entries()).map((item) => {
			out[item[0]] = item[1];
		});
		return out;
	}, [query]);
	// log.d(mutaion, "Mutation Result");
	log.d(params, "Query Params");

	if (mutation.isSuccess) {
		log.success("Mutation success");
		log.d(mutation.data, "Mutation Data");
		const id = mutation.data?.id;
		if (id) {
			return <Redirect to={`/${id}/edit`} />;
		} else {
			alert("Error, check CreateNew");
		}
		// return <Redirect to="/" />;
	}

	return tid ? (
		<Loading title={`Generating from template`} full={true} />
	) : (
		<div
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				justifyContent: "center",
				alignItems: "center",
				color: "gray",
			}}
		>
			Create New From Template
		</div>
	);
}
