/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React, { createRef, useState } from "react";
import QRCode from "react-qr-code";
import { decodeStyle, getContent, parseStyle } from "../../../../junctionAbs";
import ElementWrapper from "../wrappers/ElementWrapper";

const useRefDimensions = (ref) => {
	const [dimensions, setDimensions] = useState({ width: 1, height: 2 });
	React.useEffect(() => {
		if (ref.current) {
			const { current } = ref;
			// const boundingRect = current.getBoundingClientRect();
			// const { width, height } = boundingRect;
			const width = current.clientWidth;
			const height = current.clientHeight;
			if (Math.round(width) !== dimensions.width) {
				setDimensions({
					width: Math.round(width),
					height: Math.round(height),
				});
			}
		}
	}, [ref]);
	return dimensions;
};

function QRCodeView({ id, db, gs, dydb, ctx }) {
	const divRef = createRef();
	const dimensions = useRefDimensions(divRef);

	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);
	log.d(ps, "PS");

	let input = ps.input;

	const content = getContent(dydb, input?.content);

	return (
		<ElementWrapper id={id}>
			<div
				ref={divRef}
				style={{
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					...decodeStyle(ps.style, gs),
					backgroundColor: input.backgroundColor,
					padding: input.gap || 20,
				}}
			>
				<QRCode
					value={content}
					size={dimensions.width - (input.gap || 20) * 2}
					fgColor={input.color}
					bgColor={input.backgroundColor}
				/>
			</div>
		</ElementWrapper>
	);
}

export default QRCodeView;
