import log from 'cslog'
import { getContent } from '../../../dynamic/parser'

const QVarElementView = ({ attributes, children, element, style, dydb }) => {
	log.d(element, 'QVarElemet element')
	log.d(children, 'children in QVarElementView')
	log.d(attributes, 'attributes in QVarElementView')
	log.d(style, 'style in QVarElementView')

	const content = getContent(dydb, {
		id: element?.data?.id,
		field: element?.data?.field
	})

	children.text = 'New Children text'

	return (
		<span style={style} {...attributes}>
			<span style={{ display: 'none' }}>{children}</span>

			{/* {element?.data?.bold ? <b>{content}</b> : content} */}
			<span style={{
				fontWeight: element?.data?.bold ? 'bold' : 'normal',
				fontStyle: element?.data?.italic ? 'italic' : 'normal',
				textDecoration: element?.data?.underline ? 'underline' : 'none',
				backgroundColor: element?.data?.bgColor || 'transparent',
				color: element?.data?.color || 'auto',
			}}>{content}</span>

		</span>
	)
}

export default QVarElementView
