import log from "cslog";
import { nanoid } from "nanoid";
import getNewAbsoluteMultipage from "../absolute/new";

const getNewFlipbook = (config) => {
    log.p("Generating new flipbook");
    const info = {
        type: "flipbook",
        typebase: "flipbook",
        layout: "absolute",
        pagination: {
            type: "numbered",
            fixed: ["BD", "BM", "G", "F", "B"],
            floatingIndex: true,
            allowAdd: true,
            pageMultiple: 2,
            newSize: {
                all: "default",
                // odd: "odd",
                // even: "even",
                // page_3: "upper"

                // first check page_n, then odd, even, then all, then "default"
            },
        },
        startPageId: "coverPage",
    };

    const additionalLayouts = {};

    const oneid = `q_${nanoid(10)}`;
    const twoid = `q_${nanoid(10)}`;

    // pages_structure format: array of [key, name, pageType, size, text, textOffset]
    const pages_structure = [
        [
            "backdropDesktop",
            "Backdrop Desktop",
            "backdrop",
            "desktop",
            "Backdrop",
            [10, 110],
        ],
        [
            "backdropMobile",
            "Backdrop Mobile",
            "backdrop",
            "mobile",
            "Backdrop",
            [10, 110],
        ],
        ["globalPage", "Global Page", "general", "default", null, [0, 0]],
        [
            "coverPage",
            "Cover Page",
            "general",
            "default",
            "Front Cover",
            [0, 0],
        ],
        ["backPage", "Back Page", "general", "default", "Back Cover", [0, 0]],
        [oneid, "Page 1", "general", "default", "Page One", [0, 0]],
        [twoid, "Page 2", "general", "default", "Page Two", [0, 0]],
    ];

    const data = getNewAbsoluteMultipage(
        "flipbook",
        `Unnamed ${config.name}`,
        info,
        pages_structure,
        "magazine",
        additionalLayouts,
        config
    );
    data["info"] = info;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewFlipbook;
