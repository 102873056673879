import axios from 'axios'
import log from 'cslog'
import { history } from './history'

import { BASE_URL, EXTERNAL_URL } from '../../constants'

//===================================================
export function saveTokens (data) {
  localStorage.setItem('access_token', data?.access_token)
  localStorage.setItem('refresh_token', data?.refresh_token)
}

export function restoreAccessToken () {
  return localStorage.getItem('access_token') || undefined
}

export function restoreRefreshToken () {
  return localStorage.getItem('refresh_token') || undefined
}

export function resetTokens () {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}
//=====================================================

export function saveUser (data) {
  localStorage.setItem('userid', data?.id)
  localStorage.setItem('user', JSON.stringify(data))
}

export function restoreUserId () {
  return localStorage.getItem('userid') || undefined
}

export function restoreUser () {
  // return JSON.parse(localStorage.getItem("user") || {});
  return JSON.parse(localStorage.getItem('user')) || {}
}

//=====================================================

axios.defaults.baseURL = BASE_URL

export const externalAxios = axios.create({
  baseURL: EXTERNAL_URL
})

export const publicAxios = axios.create({
  baseURL: BASE_URL
})

publicAxios.interceptors.response.use(
  function (res) {
    return res.data
  },
  function (err) {
    return Promise.reject(err)
  }
)

export const secureAxios = axios.create({
  baseURL: BASE_URL,
  headers: {}
})

secureAxios.interceptors.response.use(
  function (res) {
    return res.data
  },
  function (err) {
    // return Promise.reject(err);
    const originalReq = err.config

    // log.d(originalReq, "Original Req");

    if (
      err.response.status === 401 &&
      originalReq.url.endsWith('/auth/refresh')
    ) {
      log.p('Invalid Refresh token, redirecting to login')
      // router.push("/login")
      history.push('/login')
      return Promise.reject(err)
    }

    if (err.response.status === 401 && !originalReq._retry) {
      log.p('Token Expired')
      log.p('Refreshing Token')
      originalReq._retry = true

      const refresh_token = restoreRefreshToken()

      return axios
        .post('/auth/refresh', {
          refresh_token: refresh_token
        })
        .then(res => {
          log.d(res, 'Refresh Token Response')
          log.d(res.status, 'response status')
          if (res.statusText === 'OK') {
            log.p('Updating tokens')
            const data = res.data?.data
            log.d(data, 'New Data')
            saveTokens(data)

            setAuthToken(data.access_token)
            // log.d(originalReq, "Original Req");
            return secureAxios({
              ...originalReq,
              headers: {
                ...originalReq.headers,
                Authorization: `Bearer ${data?.access_token}`
              }
            })
          }
        })
    }
    return Promise.reject(err)
  }
)

export function setAuthToken (token) {
  if (token) {
    secureAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete secureAxios.defaults.headers.common['Authorization']
  }
}
