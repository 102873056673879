import React from "react";
import { useQuery } from "react-query";
import { secureAxios, restoreUserId } from "../_helper/auth";

export const useFiles = (folder, filetype = null) => {
    let queryString = "filter[hidden][_eq]=false&";
    if (folder) {
        queryString += `filter[folder][_eq]=${folder.id}&`;
    } else {
        queryString += `filter[folder][_null]=true&`;
    }

    if (filetype) {
        queryString += `filter[type][_starts_with]=${filetype}&`;
    }
    return useQuery(["files", folder?.id, filetype], () =>
        secureAxios
            .get(
                `/files?${queryString}filter[uploaded_by][_eq]=${restoreUserId()}&sort=-uploaded_on`
            )
            .then((res) => res.data)
    );
};

export const usePhotos = (folder, type = "image") => {
    let queryString = `filter[type][_starts_with]=${type}&filter[hidden][_eq]=false&filter[archived][_eq]=false&`;
    if (folder) {
        queryString += `filter[folder][_eq]=${folder.id}&`;
    } else {
        queryString += `filter[folder][_null]=true&`;
    }
    return useQuery(["photos", folder?.id, type], () =>
        secureAxios
            .get(
                `/files?${queryString}filter[uploaded_by][_eq]=${restoreUserId()}&sort=-uploaded_on`
            )
            .then((res) => res.data)
    );
};

export const useArchivedPhotos = () => {
    let queryString = `filter[type][_starts_with]=image&filter[hidden][_eq]=false&filter[archived][_eq]=true&`;

    return useQuery(["photos", "trash"], () =>
        secureAxios
            .get(
                `/files?${queryString}filter[uploaded_by][_eq]=${restoreUserId()}&sort=-uploaded_on`
            )
            .then((res) => res.data)
    );
};

export const useFolders = (folder) => {
    let queryString = "";
    if (folder) {
        queryString = `filter[parent][_eq]=${folder.id}`;
    } else {
        queryString = `filter[parent][_null]=true`;
    }
    return useQuery(["folders", folder?.id], () =>
        secureAxios
            .get(
                `/folders?${queryString}&filter[user_created][_eq]=${restoreUserId()}`
            )
            .then((res) => res.data)
    );
};
