/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css } from "@emotion/react";
import React from "react";
import { default as AudioPlayerLib, RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { decodeStyle, parseStyle } from "../../../../junctionAbs";
import ElementWrapper from "../wrappers/ElementWrapper";

function AudioPlayer({ id, db, gs }) {
	const data = db[id];
	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	// const audioSrc = input?.audioFile?.value;
	const audioSrc = `https://api.qureal.com/passets/${ps.input?.audioFile?.params?.filename_disk}`

	const color = input.themeColor;
	const timeColor = input.timeTextColor;
	const barColor = input.barColor;

	return (
		<ElementWrapper id={id}>
			<div
				className="q-no-swipe"
				style={{
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					...decodeStyle(ps.style, gs),
				}}
				css={css`
                    .rhap_progress-indicator,
                    .rhap_volume-filled,
                    .rhap_volume-indicator,
                    .rhap_progress-filled {
                        background: ${color};
                    }
                    .rhap_progress-bar-show-download {
                        background-color: rgba(${barColor}, 0.5);
                    }
                    .rhap_repeat-button,
                    .rhap_main-controls-button,
                    .rhap_volume-button {
                        color: ${color};
                    }
                    .rhap_time {
                        color: ${timeColor};
                    }
                    .rhap_volume-bar,
                    .rhap_progress-bar,
                    .rhap_download-progress {
                        background: ${barColor};
                    }
                    .rhap_progress-bar-show-download {
                        background-color: rgba(${barColor}, 0.5);
                    }
                `}
			>
				<AudioPlayerLib
					src={audioSrc}
					preload="auto"
					autoPlay={input.autoPlay}
					loop={input.loop}
					layout={
						input.showProgressBelow ? "stacked-reverse" : "stacked"
					}
					showJumpControls={input.showJumpControls}
					customVolumeControls={
						input.showVolumeControls ? [RHAP_UI.VOLUME] : []
					}
					customAdditionalControls={
						input.showLoopButton ? [RHAP_UI.LOOP] : []
					}
					customProgressBarSection={
						input.showProgressbar
							? [
								RHAP_UI.CURRENT_TIME,
								RHAP_UI.PROGRESS_BAR,
								RHAP_UI.DURATION,
							]
							: []
					}
					style={{
						backgroundColor: "transparent",
					}}
				/>
			</div>
		</ElementWrapper>
	);
}

export default AudioPlayer;
