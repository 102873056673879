import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import log from "cslog";
import { toPng } from "html-to-image";

export const exportHomeState = atom({
	key: "exportHome",
	default: {
		open: false,
		export_settings: {
			format: "JPEG",
			scale: 3,
			quality: 100
		},
		download: false,
	},
});

export const screenshotState = atom({
	key: "screenshot",
	default: {
		take: false,
		image: null,
	},
});



export const exportImageState = atom({
	key: "exportImage",
	default: {
		take: false,
		image: null,
	},
});

export function useCaptureScreen(id, boxRef, type) {
	const [scshot, setScshot] = useRecoilState(screenshotState);

	useEffect(() => {
		const captureScreen = async () => {
			log.warn("Taking Screenshot");

			// const w = 350;
			const { w, h } = scshot;

			const ratioW = w / boxRef.current.offsetWidth;
			const ratioH = h / boxRef.current.offsetHeight;
			// const h = (w / dims.width) * dims.height;

			const finalRatio = ratioW < ratioH ? ratioW : ratioH;

			toPng(boxRef.current, {
				cacheBust: true,
				quality: 1.0,
				// width: 100,
				pixelRatio: finalRatio,
				// width: pageRef.current.offsetWidth,
				// height: pageRef.current.scrollHeight,
			})
				.then((dataUrl) => {
					setScshot({
						take: false,
						image: dataUrl,
					});
					log.warn("Screenshot taken");
				})
				.catch((err) => {
					console.log(err);
					log.error("Error while taking screenshot");
					setScshot({
						take: false,
						error: true,
					});
				});
			// .finally(() => {
			//     setScshot((old_val) => ({
			//         ...old_val,
			//         take: false,
			//     }));
			// });
		};
		if (scshot.take && scshot.type === type) {
			if (scshot.id === id) {
				captureScreen();
			}
		}
	}, [scshot, setScshot, boxRef]);

	return {};
}

function downloadImage(dataUrl) {
	const link = document.createElement("a");
	link.download = "my-image-name.png";
	link.href = dataUrl;
	link.click();
}
