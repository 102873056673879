import log from "cslog";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Icon } from "semantic-ui-react";
import { useFolders, usePhotos } from "../actions/assets";
import {
    useCreateFolder,
    useUploadPhoto,
    useDeleteFile,
    useArchiveFile,
} from "../actions/mutations/assets";
import { imageLibConfigState } from "../db/editorConfig";
import { selectedImageState } from "../db/elementDb";
import { getAssetURL } from "../_helper/image";
import CameraDialog from "./CameraDialog";
import GalleryView from "./GalleryView";
import SingleInputPopup from "./SingleInputPopup";
import ImageUploader from "./ImageUploader";
import UploadButton from "./UploadButton";

const SVGLibrary = ({ placeholder, multiple }) => {
    // const [data, setData] = useState();
    const [imageLibConfig, setImageLibConfig] =
        useRecoilState(imageLibConfigState);
    // const [folder, setFolder] = useState(null);
    const { isLoading, isSuccess, data } = usePhotos(null, "image/svg");

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);
    // const mutation = useUploadPhoto();
    const [enableOptions, setEnableOptions] = useState(false);

    log.d(data, "Photos Data");

    return (
        <div>
            {data ? (
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {/* {folder && ( */}
                            <Icon
                                name="home"
                                size="large"
                                style={{
                                    marginRight: "20px",
                                }}
                                color="blue"
                                link
                            />
                            {/* )} */}
                            <span
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                            >
                                SVG
                            </span>
                        </div>
                        <UploadButton
                            primary={true}
                            compress={false}
                            accept=".svg"
                            btn_label="Upload SVG"
                        />
                    </div>

                    {/* <Divider /> */}
                    {data.length > 0 ? (
                        <div
                            style={{
                                height: "70vh",
                                overflow: "auto",
                                marginTop: "10px",
                                // border: "2px dashed red",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                }}
                            >
                                {data.map((item) => (
                                    <div
                                        style={{
                                            // width: "150px",
                                            backgroundColor: "#f7f7f7",
                                            border:
                                                selectedImage?.id === item.id
                                                    ? "2px dashed royalblue"
                                                    : "2px solid transparent",
                                            padding: "5px",
                                        }}
                                        onClick={() => {
                                            setSelectedImage({
                                                srcType: "lib",
                                                id: item.id,
                                                width: item.width,
                                                height: item.height,
                                                title: item.title,
                                                filename_disk:
                                                    item.filename_disk,
                                                filename_download:
                                                    item.filename_download,
                                                storage: item.storage,
                                                type: item.type,
                                                filesize: item.filesize,
                                                full: getAssetURL(item),
                                            });
                                        }}
                                    >
                                        {/* {JSON.stringify(item)} */}
                                        <img
                                            src={getAssetURL(item)}
                                            alt={item.title}
                                            style={{
                                                width: "150px",
                                                height: "150px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <GalleryView
                                data={data}
                                multiple={multiple}
                                makeThumb={(item, index) => {
                                    return {
                                        width: item.width + 0,
                                        height: item.height + 0,
                                        src: getAssetURL(item),
                                        id: item.id,
                                        dindex: index,
                                    };
                                }}
                                makeImgObj={(photo) => {
                                    return {
                                        srcType: "lib",
                                        id: photo.id,
                                        width: photo.width,
                                        height: photo.height,
                                        title: photo.title,
                                        filename_disk: photo.filename_disk,
                                        filename_download:
                                            photo.filename_download,
                                        storage: photo.storage,
                                        type: photo.type,
                                        filesize: photo.filesize,
                                        full: getAssetURL(photo),
                                    };
                                }}
                                selectedImage={selectedImage}
                                setSelectedImage={(newImg) => {
                                    setSelectedImage(newImg);
                                    setEnableOptions(true);
                                }}
                            />
                            <div
                                style={{
                                    height: "70px",
                                }}
                            ></div>
                        </div>
                    ) : (
                        <h3 style={{ color: "gray" }}>No SVG uploaded</h3>
                    )}
                </div>
            ) : (
                placeholder
            )}
        </div>
    );
};

export default SVGLibrary;
