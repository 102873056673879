export const FORM_DEFAULT = {
    contactForm: {
        styleGroup: {
            form: {},
            field: {},
            submitButton: {},
        },
        mobileGroup: {
            form: {},
            field: {},
            submitButton: {},
        },
        input: {},
        imobile: {},
        itype: {},
    },
};

export const DEFAULT = {
    section: {
        dims: {
            width: "100%",
            height: "100%",
        },
        cstate: "normal",
        style: {
            background: {
                type: "simple",
                params: "white",
                value: "white",
            },
            height: {
                type: "auto",
                params: "auto",
                value: "auto",
            },
            backgroundPosition: "bottom right",
            backgroundAttachment: "scroll",
            transition: "1.1s",
            // gap: 0,
            padding: {
                type: "single",
                params: 0,
                value: "0px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            // clipPath: "polygon(100% 0, 100% 91%, 69% 100%, 43% 91%, 21% 100%, 1% 93%, 0 0)",
        },
        tablet: {},
        mobile: {},
        input: {
            isInner: false,
            width: 980,
            name: "Landing",
            zIndex: 1,
            orientation: "h",
            widths: [100],
            alignChilds: "center",
            shapeDivider: {
                type: "none", //none
                path: "none",
                // "M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z",
                color: "black",
                position: "bottom",
                height: 70,
            },
        },
        itablet: {
            orientation: "h",
        },
        imobile: {
            // width: 95,
            orientation: "v",
        },
        itype: {
            width: "sectionWidth",
            name: "shorttext",
            zIndex: "zIndex",
            widths: "sectionLayout",
            alignChilds: "optionRowIcon",
            orientation: "optionRowIcon",
            shapeDivider: "shapeClip",
        },
    },
    box: {
        css: null,
        cstate: "normal",
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: "0px",
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            alignChilds: "stretch",
            hoverAnimation: "qureal-none",
        },
        itype: {
            alignChilds: "optionRowIcon",
            hoverAnimation: "selectOption",
        },
    },
    header: {
        css: null,
        fixStyle: {
            fontWeight: "bold",
            boxSizing: "border-box",
        },
        style: {
            color: "black",
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: `inherit`,
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            // fontSize: 30,
            // letterSpacing: "normal",
            transition: "0.5s",
            lineHeight: 1.3,
        },
        mobile: {},
        input: {
            headingTag: {
                type: "h2",
                params: 30,
                value: "30px",
            },
            content: "Heading",
            uppercase: false,
            textAlign: "left",
        },
        itype: {
            content: "text",
            uppercase: "boolean",
            headingTag: "headingTag",
            textAlign: "optionRowIcon",
        },
        // aa.indexOf("newClass")===-1 && aa.push("newClass")
        classes: {
            q_hide_on_mobile: true,
        },
        dataFields: {
            content: "text",
        },
    },
    p: {
        css: null,
        style: {
            color: "#4A4A4A",
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            boxSizing: "border-box",
            fontSize: 16,
            lineHeight: 1.6,
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: `inherit`,
            letterSpacing: "normal",
        },
        mobile: {},
        input: {
            formatting: true,
            content: "I am paragraph",
            columnCount: 1,
            textAlign: "left",
        },
        itype: {
            formatting: "textEdit",
            content: "text",
            columnCount: "optionRow",
            textAlign: "optionRowIcon",
        },
        dataFields: {
            content: "text",
        },
    },
    richtext: {
        css: null,
        style: {
            fontSize: 18,
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            boxSizing: "border-box",
        },
        mobile: {},
        input: {
            html: "I am paragraph",
            columnCount: 1,
        },
        itype: {
            html: "html",
            columnCount: "optionRow",
        },
        dataFields: {
            html: "html",
        },
    },
    button: {
        css: null,
        fixStyle: {
            outline: "none",
            boxSizing: "border-box",
            textShadow: "0 1px 1px rgba(0, 0, 0, 0.2)",
            backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
        },
        style: {
            color: "white",
            // background: {
            //     type: "simple",
            //     params: "#41b589",
            //     value: "#41b589",
            // },
            background: {
                type: "simple",
                params: "#41b589",
                value: "#41b589",
            },
            fontSize: 15,
            // fontFamily: `Arial, Helvetica, sans-serif`,
            fontFamily: `inherit`,
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
            padding: {
                type: "multiple",
                params: {
                    cside: "top",
                    top: 12,
                    bottom: 12,
                    left: 24,
                    right: 24,
                },
                value: "12px 20px 12px 20px",
            },
            margin: {
                type: "single",
                params: 10,
                value: "10px",
            },
            borderRadius: {
                type: "single",
                params: 3,
                value: 3,
            },
            transition: "0.3s",
        },
        hover: {
            cursor: "pointer",
            // background: {
            //     //simple, gradient, image
            //     type: "simple",
            //     params: "#349670",
            //     value: "#349670",
            // },
        },
        mobile: {},
        input: {
            content: "Button",
            uppercase: true,
            buttonAlign: "flex-start",
            hoverAnimation: "qureal-grow",
            action: {
                type: "none",
            },
        },
        itype: {
            content: "text",
            uppercase: "boolean",
            buttonAlign: "optionRowIcon",
            action: "action",
            hoverAnimation: "selectOption",
        },
        dataFields: {
            content: "text",
        },
    },
    img: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            transition: "0.3s",
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            imageSource: {
                params: null,
                value: process.env.PUBLIC_URL + "img_pp.png",
            },
            imageSize: {
                type: "full",
                params: 0,
                value: "100%",
            },
            alignImage: "center",
            hoverAnimation: "qureal-none",
        },
        itype: {
            imageSource: "imgSrc",
            imageSize: "imgSize",
            alignImage: "optionRowIcon",
            hoverAnimation: "selectOption",
        },
        dataFields: {
            imageSource: "imgSrc",
        },
    },
    video: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            transition: "0.3s",
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            youtubeVideoURL: {
                params: "www.youtube.com/embed/rUWxSEwctFU",
                value: "rUWxSEwctFU",
            },
            videoSize: {
                type: "full",
                params: 0,
                value: "100%",
            },
            alignVideo: "center",
            autoplay: false,
            playInLoop: false,
            showControls: true,
        },
        itype: {
            youtubeVideoURL: "ytSource",
            videoSize: "imgSize",
            alignVideo: "optionRowIcon",
            autoplay: "boolean",
            playInLoop: "boolean",
            showControls: "boolean",
        },
        dataFields: {
            imageSource: "imgSrc",
        },
    },
    icon: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            color: "black",
            background: {
                type: "simple",
                params: "#E0E1E2",
                value: "#E0E1E2",
            },
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            transition: "0.3s",
        },
        hover: {},
        mobile: {},
        input: {
            iconSource: {
                params: {
                    type: "fa",
                    name: "coffee",
                },
                value: "fa fa-coffee",
            },
            iconSize: {
                type: "small",
                params: 30,
                value: "30px",
            },
            iconGap: 2,
            alignImage: "center",
            frameType: "frame",
            frameShape: { type: "circle", value: "circle" },
            hoverAnimation: "qureal-none",
        },
        itype: {
            iconSource: "iconSrc",
            iconSize: "iconSize",
            iconGap: "iconGap",
            frameType: "optionRowIcon",
            frameShape: "frameShape",
            alignImage: "optionRowIcon",
            hoverAnimation: "selectOption",
        },
        dataFields: {
            iconSource: "iconSrc",
        },
    },
    menu: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            color: "black",
            background: {
                type: "simple",
                params: "#E0E1E2",
                value: "#E0E1E2",
            },
            transition: "0.3s",
            fontFamily: `inherit`,
        },
        hover: {},
        mobile: {},
        input: {
            iconSource: {
                params: {
                    type: "fa",
                    name: "coffee",
                },
                value: "fa fa-coffee",
            },
            iconSize: {
                type: "small",
                params: 30,
                value: "30px",
            },
            iconGap: 2,
            alignImage: "center",
            frameType: "solid",
            frameShape: { type: "circle", value: "circle" },
        },
        itype: {
            iconSource: "iconSrc",
            iconSize: "iconSize",
            iconGap: "iconGap",
            frameType: "optionRowIcon",
            frameShape: "frameShape",
            alignImage: "optionRowIcon",
        },
    },
    blank: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {},
        mobile: {},
        input: {
            height: 20,
        },
        itype: {
            height: "height",
        },
    },
    divider: {
        css: null,
        fixStyle: {
            boxSizing: "border-box",
            maxWidth: "100%",
        },
        style: {
            margin: {
                type: "multiple",
                params: {
                    cside: "bottom",
                    top: 0,
                    bottom: 10,
                    left: 0,
                    right: 0,
                },
                value: "0px 0px 10px 0px",
            },
            color: "#9B9B9B",
            transition: "0.3s",
        },
        mobile: {},
        input: {
            thickness: 2,
            lineStyle: "solid",
        },
        itype: {
            thickness: "standardRange",
            lineStyle: "selectOption",
        },
    },
    list: {
        css: null,
        style: {
            margin: {
                type: "single",
                params: 0,
                value: "0px",
            },
            padding: {
                type: "single",
                params: 10,
                value: "10px",
            },
            color: "black",
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            fontSize: 18,
            fontFamily: `inherit`,
        },
        hover: {},
        mobile: {},
        input: {
            items: ["Item 1", "Item 2", "Item 3"],
            listStyle: {
                tag: "ul",
                style: "disc",
            },
            itemGap: 1,
        },
        itype: {
            items: "list",
            listStyle: "listStyle",
            itemGap: "iconGap",
        },
        dataFields: {
            items: "list",
        },
    },
};

export const TOP_HEADER_ID = "QUREAL_TOP_HEADER";
export const TOP_NAV_MENU = "QUREAL_NAV_MENU";
export const NAV_MENU = "Q_NAV_MENU";
