import log from "cslog";
import { startCase } from "lodash";
import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { Button, Icon, Input } from "semantic-ui-react";
import { dataPageState } from "../db/dataDb";
import { selectedBothPageIDState, selectedPageState } from "../db/siteConfig";
import styles from "./styles/DynamicDataView.module.css";
import { bookInfoState, currentBookPageState } from "../db/bookConfig";

const DynamicDataView = ({ value, reset, compact = false }) => {
    const current_page_id = useRecoilValue(selectedBothPageIDState);

    // const currentPage = useRecoilValue(selectedPageState);
    const db = useRecoilValue(dataPageState(current_page_id));

    const table = value?.id === "parent" ? value?.id : db[value?.id]?.name;

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <div className={styles.db}>
                    <Icon name="database" />
                </div>
                <div className={styles.content}>
                    <span className={styles.table}>{startCase(table)}</span>
                    <span className={styles.field}>
                        {startCase(value?.field)}
                    </span>
                </div>
                <div className={styles.close} onClick={reset}>
                    <Icon name="times" />
                </div>
            </div>
        </Fragment>
    );
};

export default DynamicDataView;
