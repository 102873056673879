import React from "react";
import { useMutation } from "react-query";
import { secureAxios } from "../../_helper/auth";
import { queryClient } from "../../_helper/query";

export const useCreateItem = (
    table,
    onStart = () => {},
    onSuccess = () => {},
    onError = () => {},
    onDone = () => {}
) => {
    return useMutation(
        (newItem) => {
            return (
                secureAxios
                    // .post(`/items/qp_magazine`, newItem)
                    .post(`/items/${table}`, newItem)
                    .then((res) => res.data)
            );
        },
        {
            onMutate: (newItem) => {
                onStart();
            },
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", table]);
                onSuccess(data);
            },
            onError: (error, variables, context) => {
                // console.log(error);
                onError(error);
            },
            onSettled: (data, error) => {
                onDone();
            },
        }
    );
};

export const useSaveCreation = (id) => {
    return useMutation((newItem) => {
        return secureAxios
            .patch(`/items/qp_creation/${id}`, newItem)
            .then((res) => res.data);
    });
};

export const usePublishCreation = (id) => {
    return useMutation((newItem) => {
        return (
            secureAxios
                // .patch(`/items/qp_magazine/${id}`, newItem)
                // .post(`/qp_publish/publish/${id}`, newItem)
                // .post(`/qp_publish/publish_creation/${id}`, newItem)
                .post(`/qe_publish/publish_creation/${id}`, newItem)
                .then((res) => res.data)
        );
    });
};

export const useUpdateItem = (id) => {
    return useMutation((newItem) => {
        return (
            secureAxios
                // .patch(`/items/qp_magazine/${id}`, newItem)
                .patch(`/items/qp_creation/${id}`, newItem)
                .then((res) => res.data)
        );
    });
};
