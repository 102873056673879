import log from "cslog";

// merge properties of two elements (child extends parent)
export function combineSingleElement(parent, child) {
    const out = {
        ...parent,
        ...child,
    };
    ["fixStyle", "style", "input", "mobile", "itype", "classes"].forEach(
        (key) => {
            out[key] = {
                ...parent[key],
                ...child[key],
            };
        }
    );
    return out;
}

// object two extends object 1
export function combineElements(parent, child) {
    // log.p("Combining objects");
    const out = {};
    Object.keys(child).forEach((elementKey) => {
        out[elementKey] = combineSingleElement(
            parent[elementKey] || {},
            child[elementKey]
        );
    });
    return out;
}
