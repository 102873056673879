import React from "react";
import { atom } from "recoil";

export const seoBasicState = atom({
    key: "seoBasic",
    default: {
        title: "",
        description: "",
        keywords: "",
        previewImage: {
            params: null,
            value: "/img_pp.png",
        },
        favicon: process.env.PUBLIC_URL + "img_pp.png",
        ogTitle: "",
        ogImage:
            "https://images.unsplash.com/photo-1565118531796-763e5082d113?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTIyMTV8MHwxfHNlYXJjaHw1fHx8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080",
    },
});

// export const seoBasicState = atom({
//     key: "seoBasic",
//     default: {
//         title: "Smile Foundation - Always Smile",
//         description:
//             "Smile Foundation - an NGO working for children's education and health across India. Donate money to NGO for underprivileged children's education & health",
//         favicon: process.env.PUBLIC_URL + "img_pp.png",
//         ogTitle: "",
//         ogImage:
//             "https://images.unsplash.com/photo-1565118531796-763e5082d113?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTIyMTV8MHwxfHNlYXJjaHw1fHx8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080",
//     },
// });
