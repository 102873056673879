import { css } from "@emotion/css";
import React from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function List({ id, db, gs }) {
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	function renderChilds() {
		return input.textList.map((item) => {
			if (item !== "") {
				return (
					<li
						key={item}
						style={{
							marginBottom: input.itemGap - 0.5 + "rem",
						}}
					>
						{item}
					</li>
				);
			}
		});
	}

	const ss = decodeStyle(ps.style, gs);

	return (
		<ElementWrapper id={id}>
			{input.listStyle.tag === "ul" && (
				<ul
					style={{
						...decodeStyle(ps.fixStyle, gs),
						...ss,
						lineHeight: ss.lineHeight,
						listStyleType: input.listStyle.style,
						listStylePosition: "inside",
						fontFamily: ss.fontFamily,
						margin: 0,
					}}
				>
					{renderChilds()}
				</ul>
			)}
			{input.listStyle.tag === "ol" && (
				<ol
					type={input.listStyle.style}
					style={{
						...decodeStyle(ps.fixStyle, gs),
						...ss,
						lineHeight: ss.lineHeight,
						listStyleType: input.listStyle.style,
						listStylePosition: "inside",
						margin: 0,
						fontFamily: ss.fontFamily,
					}}
				>
					{renderChilds()}
				</ol>
			)}
		</ElementWrapper>
	);
}

export default List;
