import { useFocused, useSelected, useSlate } from 'slate-react'
import { removeLink } from '../utils/link'
import styles from './style/Link.module.css'

const Link = ({ attributes, element, children }) => {
  const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div className={styles.wrapper}>
      <a {...attributes} href={element.href}>
        {children}
      </a>
      {selected && focused && (
        <div className={styles.popup} contentEditable={false}>
          <a href={element.href} rel='noreferrer' target='_blank'>
            <i className='fas fa-link fa-xs' />
            {element.href}
          </a>
          <button onClick={() => removeLink(editor)}>
            <i className='fas fa-unlink fa-xs' />
          </button>
        </div>
      )}
    </div>
  )
}

export default Link
