import log from "cslog";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";
import { addElement, elementState } from "../db/elementDb";

export const formulateEssance = (essance) => {
    const patterns = [];
    let current_pattern = [];
    let current_pattern_start_depth = -1;
    for (let i = 0; i < essance.length; i++) {
        const ele = essance[i];
        const d = ele[0];
        const e = ele[1];

        if (e === "box") {
            if (current_pattern_start_depth === -1) {
                // starting
                current_pattern.push(e);
                current_pattern_start_depth = d;
            } else if (d > current_pattern_start_depth) {
                // box found inside box
                current_pattern.push(e);
            } else {
                // box found is sibling or chacha
                patterns.push([...current_pattern]);
                current_pattern = [e];
                current_pattern_start_depth = d;
            }
        } else {
            if (
                current_pattern_start_depth !== -1 &&
                d > current_pattern_start_depth
            ) {
                current_pattern.push(e);
            }
        }
    }

    patterns.push(current_pattern);
    return patterns;
};

// convert template data into short rep like H_P_P_I (header, p, p, img)
export const extractEssance = async (data, snapshot, out = [], depth = 0) => {
    log.d(data, `next type: ${depth}_${data.type}`);
    out.push([depth, data.type]);
    if ("section" in data) {
        for (const child of data.section) {
            let childData = await snapshot.getPromise(elementState(child));
            await extractEssance(childData, snapshot, out, depth + 1);
        }
    } else if ("child" in data) {
        for (const child of data.child) {
            let childData = await snapshot.getPromise(elementState(child));
            await extractEssance(childData, snapshot, out, depth + 1);
        }
    } else if ("childs" in data) {
        for (const child of data.childs) {
            let childData = await snapshot.getPromise(elementState(child));
            await extractEssance(childData, snapshot, out, depth + 1);
        }
    }
};

export const extractMinimalFormat = async (data, snapshot) => {
    log.d(data, `mini type: ${data.type}`);
    const etype = data.type;

    if (etype === "section") {
        const out = [];
        for (const child of data.childs) {
            let childData = await snapshot.getPromise(elementState(child));
            const temp = await extractMinimalFormat(childData, snapshot);
            out.push({ [childData?.type]: temp });
        }
        return out;
    } else if (etype === "box") {
        const out = [];
        for (const child of data.childs) {
            let childData = await snapshot.getPromise(elementState(child));
            const temp = await extractMinimalFormat(childData, snapshot);
            out.push({ [childData?.type]: temp });
        }
        return out;
    } else {
        if (["header", "p"].includes(etype)) {
            return data?.input?.content;
        } else if (etype === "button") {
            return {
                text: data?.input?.buttonType?.params?.text,
            };
        } else {
            return etype;
        }
    }
};

// this is in use
export const extractData = async (id, pid, data, snapshot, arr) => {
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    if ("sections" in data) {
        await Promise.all(
            data.sections.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                data.sections[index] = childId;
                let childData = await snapshot.getPromise(elementState(child));
                childData = { ...childData, id: childId };
                await extractData(childId, id, childData, snapshot, arr);
            })
        );
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = await snapshot.getPromise(elementState(data.child));
        await extractData(cid, id, cdata, snapshot, arr);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        // log.d(data, "DATA");
        if (Array.isArray(data.childs)) {
            await Promise.all(
                data.childs.map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[index] = child;
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractData(child, id, childData, snapshot, arr);
                })
            );
        } else {
            await Promise.all(
                Object.keys(data.childs).map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[child] = data.childs[child];
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractData(child, id, childData, snapshot, arr);
                })
            );
        }
    } else if ("fixChild" in data) {
        const cid = data.fixChild;
        const cdata = await snapshot.getPromise(elementState(data.fixChild));
        await extractData(cid, id, cdata, snapshot, arr);
        data = { ...data, fixChild: cid };
    }
    if ("bg" in data) {
        log.p("Making Promise");
        await Promise.all(
            data.bg.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                // data.childs[index] = childId;
                data.bg[index] = child;
                let childData = await snapshot.getPromise(elementState(child));
                // childData = { ...childData, id: childId };
                childData = { ...childData, id: child };
                // await extractData(childId, id, childData, snapshot, arr);
                await extractData(child, id, childData, snapshot, arr);
            })
        );
    }

    arr[id] = data;
};

export const extractDataMinimal = async (id, pid, data, snapshot, arr) => {
    data = cloneDeep(data);
    log.d(data, "Data Not Minimal");
    //Minimalize it================

    //=============================
    data = { ...data, id: id, pid: pid };
    if ("sections" in data) {
        await Promise.all(
            data.sections.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                data.sections[index] = childId;
                let childData = await snapshot.getPromise(elementState(child));
                childData = { ...childData, id: childId };
                await extractDataMinimal(childId, id, childData, snapshot, arr);
            })
        );
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = await snapshot.getPromise(elementState(data.child));
        await extractDataMinimal(cid, id, cdata, snapshot, arr);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        // log.d(data, "DATA");
        if (Array.isArray(data.childs)) {
            await Promise.all(
                data.childs.map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[index] = child;
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractDataMinimal(
                        child,
                        id,
                        childData,
                        snapshot,
                        arr
                    );
                })
            );
        } else {
            await Promise.all(
                Object.keys(data.childs).map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[child] = data.childs[child];
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractDataMinimal(
                        child,
                        id,
                        childData,
                        snapshot,
                        arr
                    );
                })
            );
        }
    }
    if ("bg" in data) {
        log.p("Making Promise");
        await Promise.all(
            data.bg.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                // data.childs[index] = childId;
                data.bg[index] = child;
                let childData = await snapshot.getPromise(elementState(child));
                // childData = { ...childData, id: childId };
                childData = { ...childData, id: child };
                // await extractData(childId, id, childData, snapshot, arr);
                await extractDataMinimal(child, id, childData, snapshot, arr);
            })
        );
    }

    arr[id] = data;
};

export const cultivateData = async (id, pid, data, db) => {
    log.d(id, `cultivating, pid: ${pid}`);
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    // log.d(data, `Extracting ${id}`);
    if ("sections" in data) {
        data.sections.map(async (child, index) => {
            // const childId = nanoid(10);
            const childId = child;
            data.sections[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateData(childId, id, childData, db);
        });
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = db[data.child];
        cultivateData(cid, id, cdata, db);
        data = { ...data, child: cid };
    } else if ("fixChild" in data) {
        const cid = data.fixChild;
        const cdata = db[data.fixChild];
        cultivateData(cid, id, cdata, db);
        data = { ...data, fixChild: cid };
    } else if ("childs" in data) {
        if (Array.isArray(data.childs)) {
            data.childs.map(async (child, index) => {
                // const childId = nanoid(10);
                const childId = child;
                data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                cultivateData(childId, id, childData, db);
            });
        } else {
            Object.keys(data.childs).map(async (child, index) => {
                // const childId = nanoid(10);
                const childId = child;
                // data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                cultivateData(childId, id, childData, db);
            });
        }
    }
    if ("bg" in data) {
        data.bg.map(async (child, index) => {
            // const childId = nanoid(10);
            const childId = child;
            data.bg[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateData(childId, id, childData, db);
        });
    }

    // log.d(data, `wData Cult: ${id}`);
    addElement(id, data);
};

export const createItAbsolute = async (id, pid, data, db, update = false) => {
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    if ("child" in data) {
        const cid = nanoid(10);
        const cdata = db[data.child];
        createItAbsolute(cid, id, cdata, db);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        if (Array.isArray(data.childs)) {
            data.childs.map(async (child, index) => {
                const childId = nanoid(10);
                data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                createItAbsolute(childId, id, childData, db);
            });
        } else {
            Object.keys(data.childs).map(async (child, index) => {
                const childId = nanoid(10);
                data.childs[childId] = {
                    ...data.childs[child],
                    id: childId,
                };
                delete data.childs[child];
                let childData = db[child];
                childData = { ...childData, id: childId };
                await createItAbsolute(childId, id, childData, db);
            });
        }
    }
    if ("bg" in data) {
        data.bg.map(async (child, index) => {
            const childId = nanoid(10);
            data.bg[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            createItAbsolute(childId, id, childData, db);
        });
    }
    if (update) {
        log.d([id, data], "Updating");
        return [id, data];
    } else {
        log.d(data, `Adding element ${id}`);
        addElement(id, data);
    }
};

export const cultivateDataUniquely = async (id, pid, data, db) => {
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    // log.d(data, `Extracting ${id}`);
    if ("sections" in data) {
        data.sections.map(async (child, index) => {
            const childId = "q_" + nanoid(10);
            // const childId = child;
            data.sections[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateDataUniquely(childId, id, childData, db);
        });
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = db[data.child];
        cultivateDataUniquely(cid, id, cdata, db);
        data = { ...data, child: cid };
    } else if ("fixChild" in data) {
        const cid = data.fixChild;
        const cdata = db[data.fixChild];
        cultivateDataUniquely(cid, id, cdata, db);
        data = { ...data, fixChild: cid };
    } else if ("childs" in data) {
        if (Array.isArray(data.childs)) {
            data.childs.map(async (child, index) => {
                const childId = nanoid(10);
                // const childId = child;
                data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                cultivateDataUniquely(childId, id, childData, db);
            });
        } else {
            Object.keys(data.childs).map(async (child, index) => {
                log.d(child, "Now start");
                const childId = nanoid(10);
                // const childId = child;
                data.childs[childId] = data.childs[child];
                let childData = db[child];
                childData = { ...childData, id: childId };
                await cultivateDataUniquely(childId, id, childData, db);
                log.d(child, "Now end");
            });
        }
    }
    if ("bg" in data) {
        data.bg.map(async (child, index) => {
            const childId = nanoid(10);
            // const childId = child;
            data.bg[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateDataUniquely(childId, id, childData, db);
        });
    }

    log.d(data, `wData Cult: ${id}`);
    addElement(id, data);
};
