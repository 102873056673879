export const SIZE_ICONS = {
    xs: {
        vb: "0 0 420.76 240.82",
        d: "M409.71,133.71q-11.06-14.39-30.62-22.4a124.69,124.69,0,0,0-19.46-6.16q-9.88-2.25-26.71-5.58-15.45-2.92-24.65-5.18a73,73,0,0,1-16-5.77,33.31,33.31,0,0,1-11.73-9.39,21.85,21.85,0,0,1-4.11-13.5q0-16.81,11.83-26.8t33.36-10q21.71,0,38.73,9.3a64.12,64.12,0,0,1,26.41,27.09q2.75,5.09,10.17,5.08,8,0,11.35-2.93c2.22-2,3.33-5.21,3.33-9.78v-45q0-6.85-3.33-9.79T396.89,0q-8,0-11.34,2.93t-3.33,9.79V24.65a83.44,83.44,0,0,0-27.39-18A86.07,86.07,0,0,0,321.58,0q-22.11,0-39.42,7.92a62.39,62.39,0,0,0-27,22.79q-9.67,14.88-9.68,35,0,19.56,8.7,31.59t24.36,19.08a121.85,121.85,0,0,0,20,6.84q10.86,2.74,26.9,5.87,15.25,3.13,24.06,5.19a89.4,89.4,0,0,1,16.24,5.38q11.73,5.08,17.9,12.81t6.16,20q0,17.81-13.4,28.56T338,211.86q-25.63,0-43.13-12.13t-25.53-34.82q-2.94-8.21-11.35-8.21-8,0-11.35,2.93t-3.32,9.78V228.1a15.32,15.32,0,0,0,1.69,7.74H213.71a8.86,8.86,0,0,0,4.12-2.36q2.83-3,2.84-10.27t-2.84-10.27q-2.84-3-9.49-3H197l-67.69-94.1,60.45-84.9h12.71q6.65,0,9.49-3t2.84-10.27q0-7.25-2.84-10.27t-9.49-3H143q-6.65,0-9.49,3t-2.83,10.27q0,7.23,2.83,10.27t9.49,3h11.74L111.31,92.73,68.08,30.91H85.49q6.65,0,9.49-3t2.83-10.27q0-7.25-2.83-10.27t-9.49-3H18.19c-4.43,0-7.6,1-9.48,3S5.87,12.78,5.87,17.61s.94,8.25,2.84,10.27,5,3,9.48,3h13.5l61,84.51L24.45,209.91H12.32q-6.65,0-9.48,3T0,223.21q0,7.24,2.84,10.27t9.48,3H75.71q6.65,0,9.49-3T88,223.21q0-7.23-2.83-10.27t-9.49-3H59.47l51.06-71.41,49.88,71.41H139.09q-6.66,0-9.49,3t-2.83,10.27q0,7.24,2.83,10.27t9.49,3h69.25a20,20,0,0,0,5-.57h31.74a8.17,8.17,0,0,0,1.58,1.94q3.33,2.94,11.35,2.94t11.35-2.94c2.21-2,3.32-5.21,3.32-9.78V212.84a81.86,81.86,0,0,0,29.05,20.83A91.47,91.47,0,0,0,338,240.82q26.22,0,44.9-8.61t28.26-24q9.58-15.35,9.59-35.7Q420.76,148.09,409.71,133.71Z",
    },
    s: {
        vb: "0 0 240.43 326.31",
        d: "M223.46,4Q228,8,228,17.23v61q0,9.28-4.51,13.25t-15.37,4q-10.08,0-13.79-6.89a87,87,0,0,0-35.78-36.72Q135.45,39.24,106,39.23q-29.16,0-45.19,13.52t-16,36.32q0,10.86,5.57,18.29t15.9,12.72A98.75,98.75,0,0,0,88,127.9q12.45,3,33.4,7,22.78,4.52,36.18,7.56A169.34,169.34,0,0,1,184,150.83q26.5,10.88,41.49,30.35t15,52.62q0,27.57-13,48.38t-38.31,32.47q-25.31,11.66-60.83,11.66a124.06,124.06,0,0,1-49.17-9.67,111.14,111.14,0,0,1-39.37-28.23v20.67q0,9.29-4.5,13.25t-15.38,4q-10.87,0-15.37-4T0,309.08V229.56q0-9.28,4.51-13.26t15.37-4q11.4,0,15.38,11.13,10.86,30.75,34.59,47.18t58.45,16.44q33.93,0,52.09-14.58t18.15-38.7q0-16.7-8.35-27.17t-24.25-17.36a120.53,120.53,0,0,0-22-7.29q-11.92-2.79-32.61-7Q89.6,170.72,74.88,167a166.22,166.22,0,0,1-27.17-9.28q-21.21-9.54-33-25.84T2.92,89.07q0-27.3,13.12-47.45T52.62,10.74Q76.08,0,106,0A116.78,116.78,0,0,1,151.1,9,113.1,113.1,0,0,1,188.21,33.4V17.23q0-9.27,4.5-13.25t15.38-4Q219,0,223.46,4Z",
    },
    m: {
        vb: "0 0 309.61 314.65",
        d: "M297.82,4.11q3.84,4.11,3.84,13.92t-3.84,13.91Q294,36.05,285,36.05H271.71L278.6,278.6h14.31q9,0,12.86,4.11t3.84,13.91q0,9.81-3.84,13.92t-12.86,4.11H209.68q-9,0-12.86-4.11T193,296.62q0-9.8,3.84-13.91t12.86-4.11H239.1L233.27,61.5,179.19,212.59q-3.45,9.28-9.14,13.26t-15.24,4q-9.56,0-15.25-4t-9.14-13.26L76.08,61.5,70.51,278.6H99.94q9,0,12.85,4.11t3.84,13.91q0,9.81-3.84,13.92t-12.85,4.11H16.7q-9,0-12.86-4.11T0,296.62q0-9.8,3.84-13.91T16.7,278.6H31L37.64,36.05h-13q-9,0-12.85-4.11T8,18Q8,8.22,11.8,4.11T24.65,0H80.58c4.25,0,7.6.71,10.08,2.12A11.88,11.88,0,0,1,96,8.75l58.85,161.43L213.39,8.75Q216.3,0,229,0H285Q294,0,297.82,4.11Z",
    },
    l: {
        vb: "0 0 270.38 314.65",
        d: "M176.41,4.11q3.84,4.11,3.84,13.92t-3.84,13.91q-3.84,4.11-12.86,4.11H99.4V278.6H230.62V191.39q0-9.28,4.5-13.26t15.38-4q10.86,0,15.37,4t4.51,13.26V296.62q0,9.81-3.84,13.92t-12.86,4.11H16.7q-9,0-12.86-4.11T0,296.62q0-9.8,3.84-13.91T16.7,278.6H59.64V36.05H16.7q-9,0-12.86-4.11T0,18Q0,8.22,3.84,4.11T16.7,0H163.55Q172.57,0,176.41,4.11Z",
    },
    xl: {
        vb: "0 0 422.29 229.32",
        d: "M419,129.83q-3.28-2.91-11.2-2.9t-11.21,2.9q-3.29,2.89-3.28,9.66v63.56H297.68V26.27h46.75q6.57,0,9.37-3t2.8-10.14Q356.6,6,353.8,3t-9.37-3h-107Q230.83,0,228,3t-2.8,10.15q0,7.14,2.8,10.14t9.37,3h31.3V203.05H237.4q-6.57,0-9.37,3t-2.8,10.14q0,7.15,2.8,10.15a9.65,9.65,0,0,0,5.43,2.65H209.69a9.65,9.65,0,0,0,5.43-2.65q2.81-3,2.8-10.15T215.12,206q-2.81-3-9.37-3H194.54L127.7,110.12l59.7-83.85H200c4.37,0,7.5-1,9.36-3s2.81-5.38,2.81-10.14S211.19,5,209.32,3s-5-3-9.36-3H141.22q-6.57,0-9.37,3t-2.8,10.15q0,7.14,2.8,10.14t9.37,3h11.6L109.93,87.32l-42.7-61H84.42q6.57,0,9.37-3T96.6,13.14Q96.6,6,93.79,3T84.42,0H18Q11.4,0,8.6,3T5.79,13.14q0,7.14,2.81,10.14t9.37,3H31.3l60.27,83.46L24.15,203.05h-12q-6.57,0-9.37,3T0,216.18q0,7.15,2.8,10.15t9.37,3h62.6c4.37,0,7.5-1,9.36-3s2.81-5.38,2.81-10.15S86,208,84.13,206s-5-3-9.36-3h-16l50.42-70.52,49.27,70.52H137.36q-6.57,0-9.37,3t-2.8,10.14q0,7.15,2.8,10.15t9.37,3h68.39a23.07,23.07,0,0,0,3.36-.24H234a23.18,23.18,0,0,0,3.36.24H410.12q6.57,0,9.37-3t2.8-10.15V139.49Q422.29,132.72,419,129.83Z",
    },
};
