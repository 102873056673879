import log from "cslog";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Icon } from "semantic-ui-react";
import { useFolders, usePhotos } from "../actions/assets";
import {
    useCreateFolder,
    useUploadPhoto,
    useDeleteFile,
    useArchiveFile,
} from "../actions/mutations/assets";
import { imageLibConfigState } from "../db/editorConfig";
import { selectedImageState } from "../db/elementDb";
import { getAssetURL } from "../_helper/image";
import CameraDialog from "./CameraDialog";
import GalleryView from "./GalleryView";
import SingleInputPopup from "./SingleInputPopup";
import ImageUploader from "./ImageUploader";
import UploadButton from "./UploadButton";
import { ALL_SHAPE_DATA } from "../data/extra/allshapeData";

const ShapeLibrary = ({ placeholder, multiple }) => {
    // const [data, setData] = useState();
    const [imageLibConfig, setImageLibConfig] =
        useRecoilState(imageLibConfigState);
    // const [folder, setFolder] = useState(null);
    const { isLoading, isSuccess, data } = usePhotos(null, "image/svg");

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);
    // const mutation = useUploadPhoto();
    const [enableOptions, setEnableOptions] = useState(false);

    log.d(data, "Photos Data");

    return (
        <div>
            {data ? (
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {/* {folder && ( */}
                            <Icon
                                name="home"
                                size="large"
                                style={{
                                    marginRight: "20px",
                                }}
                                color="blue"
                                link
                            />
                            {/* )} */}
                            <span
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Shapes
                            </span>
                        </div>
                        {/* <UploadButton
                            primary={true}
                            compress={false}
                            accept=".svg"
                            btn_label="Upload SVG"
                        /> */}
                    </div>

                    {/* <Divider /> */}

                    <div
                        style={{
                            height: "70vh",
                            overflow: "auto",
                            marginTop: "10px",
                            // border: "2px dashed red",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                flexWrap: "wrap",
                            }}
                        >
                            {Object.keys(ALL_SHAPE_DATA).map((key, index) => {
                                const item = ALL_SHAPE_DATA[key];
                                return (
                                    <div
                                        style={{
                                            width: "100px",
                                            backgroundColor: "#F9F9F9",
                                            border:
                                                selectedImage?.id === key
                                                    ? "2px dashed royalblue"
                                                    : "2px solid transparent",
                                            padding: "5px",
                                        }}
                                        onClick={() => {
                                            setSelectedImage({
                                                id: key,
                                                ...item,
                                            });
                                        }}
                                    >
                                        {/* {JSON.stringify(item)} */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            // viewBox="0 0 346.6 261.4"
                                            viewBox={`0 0 ${item.w} ${item.h}`}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                fill: "#D5E5EF",
                                                // stroke: "green",
                                            }}
                                        >
                                            <title>Asset 97</title>
                                            <path
                                                d={item.d}
                                                fillRule="nonzero"
                                            />
                                        </svg>
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            style={{
                                height: "70px",
                            }}
                        ></div>
                    </div>
                </div>
            ) : (
                placeholder
            )}
        </div>
    );
};

export default ShapeLibrary;
