import log from 'cslog'
import React, { useEffect, useState } from 'react'
import { useAnalyseCurrentComponent } from './analyser'
import styles from '../styles/QurealBot.module.css'
import { useElementSuggestions, useSuggestions } from './suggestions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { elementState, selectedElementState } from '../../../db/elementDb'
import { useActions } from './actions'
import classNames from 'classnames'
import { Button, Icon } from 'semantic-ui-react'

function QurealBotAbolute () {
  const selected_element = useRecoilValue(selectedElementState)
  const [data, setData] = useRecoilState(elementState(selected_element))

  const suggestions = useElementSuggestions(data)
  // const global_ctx = useRecoilValue(globalContextState);
  const comp_aly = useAnalyseCurrentComponent()

  const actor = useActions(
    data,
    setData,
    () => {
      //start action
    },
    () => {
      //end of action
      setCurrentAction(null)
    }
  )
  const [current_action, setCurrentAction] = useState(null)
  const [selected_chip, setSelectedChip] = useState(null)

  log.d(actor, 'Actor object')

  suggestions.push({
    label: 'Change All Content',
    value: [
      'change_all_content',
      'change_all_content',
      null,
      [
        {
          name: 'prompt',
          type: 'text',
          label: 'Describe new content...'
        }
      ]
    ]
  })
  suggestions.push({
    label: 'Auto Label',
    value: ['auto_label', 'auto_label', null, []]
  })
  suggestions.push({
    label: 'Auto label',
    value: ['auto_label', 'auto_label', null, []]
  })

  useEffect(() => {
    setCurrentAction(null)
  }, [selected_element])

  const takeAction = action => {
    log.d(action, 'Taking action')
    const inputs = action?.[3] || []
    if (inputs.length > 0) {
      setCurrentAction(action)
    } else {
      actor.execute({
        type: action[0],
        subtype: action[1],
        params: action[2]
      })
    }
  }

  // log.d(selected_element, "QBA selected element");
  log.d(data, 'QBA selected element data')
  log.d(suggestions, 'QBA suggestions')

  const input = current_action?.[3] || []

  return (
    <div>
      <p>Absolute Bot</p>
      {!input.length > 0 && (
        <div className={styles.chips_wrapper}>
          {suggestions.map((item, index) => (
            <span
              className={classNames({
                [styles.suggestion_chip]: true,
                [styles.chip_disabled]: actor.isLoading,
                [styles.chip_selected]:
                  actor.isLoading && selected_chip === index
              })}
              onClick={() => {
                setSelectedChip(index)
                takeAction(item.value)
              }}
            >
              {item.label}{' '}
              {selected_chip === index && actor.isLoading && (
                <Icon
                  name='asterisk'
                  loading
                  style={{
                    marginLeft: '5px'
                  }}
                />
              )}
            </span>
          ))}
        </div>
      )}
      {/* {current_action && <p>{JSON.stringify(current_action)}</p>} */}
      {input?.length > 0 &&
        input.map((inp, index) => {
          if (inp.type === 'text') {
            return (
              <SingleInputBox
                key={index}
                input={inp}
                onSubmit={val => {
                  takeAction([
                    current_action[0],
                    current_action[1],
                    {
                      ...current_action[2],
                      [inp.name]: val
                    },
                    null
                  ])
                }}
                isLoading={actor?.isLoading}
              />
            )
          } else {
            return <p>{inp.type} not available yet</p>
          }
        })}

      {/* <ChatInterface /> */}
    </div>
  )
}

export default QurealBotAbolute

function SingleInputBox ({ input, onSubmit, isLoading }) {
  const [text, setText] = useState('')
  return (
    <div className={styles.single_input_box}>
      <p className={styles.single_input_label}>{input.label}</p>
      <ChatInputBox
        text={text}
        onChange={newText => setText(newText)}
        onSend={() => onSubmit(text)}
        isLoading={isLoading}
        // isLoading={true}
      />
    </div>
  )
}

function ChatInterface () {
  return (
    <div>
      <div className={styles.message_list}>
        <div className={classNames(styles.message, styles.message_user)}>
          Hello How are you
        </div>
        <div className={classNames(styles.message, styles.message_bot)}>
          I am fine. you say. I wam an Atrtificial inteligence assitent who can
          write code, poems and anything you want.
        </div>
      </div>
      <ChatInputBox />
    </div>
  )
}

function ChatInputBox ({ text, onChange, onSend, isLoading }) {
  return (
    <div className={styles.input_box_wrapper}>
      <textarea
        rows={3}
        className={styles.input_box}
        value={text}
        onChange={e => onChange(e.target.value)}
        disabled={isLoading}
      />
      <button className={styles.send_btn} onClick={onSend} disabled={isLoading}>
        {isLoading ? (
          <Icon name='asterisk' loading />
        ) : (
          <Icon name='arrow up' />
        )}
      </button>
    </div>
  )
}
