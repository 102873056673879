import React, { useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";

const SingleInputPopup = ({
    trigger,
    onSubmit,
    label,
    btnText,
    size = "tiny",
}) => {
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);

    return (
        <Modal
            size={size}
            open={open}
            onOpen={() => {
                setOpen(true);
                setValue("");
            }}
            onClose={() => setOpen(false)}
            trigger={trigger}
        >
            <Modal.Content>
                <Form>
                    <Form.Input
                        label={label}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={() => {
                        onSubmit(value);
                        setOpen(false);
                    }}
                >
                    {btnText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SingleInputPopup;
