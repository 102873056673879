import { Editor } from "slate";

export const toggleStyle = (editor, key, value) => {
    const isActive = isStyleActive(editor, key);

    if (isActive) {
        Editor.removeMark(editor, key);
    } else {
        Editor.addMark(editor, key, value);
    }
};

export const setStyle = (editor, key, value) => {
    const isActive = isStyleActive(editor, key);

    if (isActive) {
        Editor.removeMark(editor, key);
    }
    Editor.addMark(editor, key, value);
};

export const removeOneStyle = (editor, key) => {
    const isActive = isStyleActive(editor, key);

    if (isActive) {
        Editor.removeMark(editor, key);
    }
};

export const isStyleActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] !== undefined : false;
};

export const removeStyle = (editor) => {
    ["bold", "italic", "underline", "color", "bgColor", "heading-two"].forEach(
        (key) => {
            const isActive = isStyleActive(editor, key);
            if (isActive) {
                Editor.removeMark(editor, key);
            }
        }
    );
};
