import { useEffect, useState } from "react";
import log from "cslog";
import { useAtom } from "jotai";
import { actionUIAtom } from "./db";
import { getFileURL } from "./juntion";
// import { getContent } from "../dynamicdata/parser";
import { getContent } from "./junctionAbs";

const isString = (value) => {
    return typeof value === "string" || value instanceof String;
};

export function getPageURLFromAction(query, action, is_custom_domain = false) {
    let pathname = is_custom_domain ? `/` : `/w/${query[0]}/`;

    // if (action.params?.paths?.length > 0) {
    //     pathname += action.params.slug_base;
    //     action.params.paths.forEach((path) => {
    //         const pathval = action.params.dyno?.[path];
    //         if (!isString(pathval)) {
    //             pathname += `/${getContent(action.dydb, pathval)}`;
    //         } else {
    //             pathname += `/${pathval}`;
    //         }
    //     });
    // } else {
    //     pathname += `/${action.params.id}`;
    //     // pathname = is_custom_domain
    //     //     ? `/${action.params.id}`
    //     //     : `/w/${query[0]}/${action.params.id}`;
    // }
    return pathname;
}

export function useAction() {
    const [_, setActionUI] = useAtom(actionUIAtom);
    const [action, performAction] = useState(null);
    // const router = useRouter();
    // const query = router.query["id"];

    // const is_custom_domain = !router.asPath?.startsWith("/w/");
    const is_custom_domain = false;

    useEffect(() => {
        if (action) {
            //========================================
            switch (action.type) {
                case "none":
                    break;
                case "page":
                    setActionUI({
                        type: "none",
                        params: {},
                    });

                    // let pathname = is_custom_domain ? `/` : `/w/${query[0]}/`;

                    // if (action.params?.paths?.length > 0) {
                    //     pathname += action.params.slug_base;
                    //     action.params.paths.forEach((path) => {
                    //         const pathval = action.params.dyno?.[path];
                    //         if (!isString(pathval)) {
                    //             pathname += `/${getContent(
                    //                 action.dydb,
                    //                 pathval
                    //             )}`;
                    //         } else {
                    //             pathname += `/${pathval}`;
                    //         }
                    //     });
                    // } else {
                    //     pathname += `/${action.params.id}`;
                    // }

                    // router.push(
                    //     {
                    //         pathname: pathname,
                    //         hash: action.params.section?.name
                    //             ? action.params.section.name
                    //             : "",
                    //     },
                    //     undefined,
                    //     // { shallow: true }
                    //     { shallow: false }
                    // );
                    break;
                case "download":
                    const file = action.params?.params;
                    if (file) {
                        const url = getFileURL(file?.id);
                        console.log("Download url", url);
                        // saveAs(url, file?.filename_download);
                        window.open(`${url}?download`, "_self");
                    }
                    break;
                case "link":
                    let link = action.params.link;
                    if (isString(link)) {
                        if (!link.startsWith("http")) {
                            link = "http://" + link;
                        }
                        if (action.params.newTab) {
                            window.open(link, "_blank");
                        } else if (action.params.popup) {
                            window.open(link, "popup", "width=600,height=600");
                        } else {
                            window.open(link, "_self");
                        }
                    } else {
                        //link is dynamic
                        log.d(link, "Dynamic link value");
                        log.d(action.dydb, "action dydb");
                        const parsed_link = getContent(action.dydb, link);
                        log.d(parsed_link, "Parsed link");
                        window.open(parsed_link, "_blank");
                    }
                    break;
                case "call":
                case "mail":
                case "map":
                    window.open(action.value, "_blank");
                    break;
                default:
                    if (action.value) {
                        window.open(action.value, "_blank");
                    }
                    console.log("No action");
            }
            //========================================
            performAction(null);
        }
    }, [action]);

    return {
        performAction,
        // currentPage: query[1]
    };
}
