import "normalize.css";
import "./styles/semantic_overwrite.css";
import React, { lazy, Suspense, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery } from "react-query";
import { useParams, useRouteMatch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import "semantic-ui-css/semantic.min.css";
import { authState } from "./db/editorConfig";
import { previewState } from "./db/previewDb";
import Loading from "./Loading";
import { secureAxios } from "./_helper/auth";
// import "./semantic/dist/semantic.min.css";
import "./styles/global.scss";
import "./styles/navs.css";
import "./styles/qhover.css";
import "./styles/anims.css";
import "./styles/layouts.css";
import "./styles/form.css";
import Decoder from "./Decoder";
// import Preview from "./Preview";
import "animate.css";
// import InitStore from "./store/InitStore";
import { TouchBackend } from "react-dnd-touch-backend";
import { CustomDragLayer } from "./CustomDragLayer";
import useMedia from "react-use/lib/useMedia";
import "pattern.css";
import Preview from "./Preview";
import QurealBotWidget from "./ai/bot/wrappers/QurealBotWidget";
import { ENABLE_AI_BOT } from "./constants";

// import App from "./App";
const App = lazy(() => import("./App"));

const DEBUG = process.env.REACT_APP_ENV === "dev";

if (!DEBUG) {
	console.log = function () { };
}

function Application() {
	const { id } = useParams();
	const preview = useRecoilValue(previewState);
	const isMobile = useMedia("(max-width: 800px)");


	return (
		<DndProvider
			backend={TouchBackend}
			options={{
				enableTouchEvents: true,
				enableMouseEvents: true,
				// delayTouchStart: 500,
				...(isMobile
					? {
						scrollAngleRanges: [
							{ start: 300 },
							{ end: 60 },
							{ start: 120, end: 240 },
						],
					}
					: {}),
			}}
		>
			<CustomDragLayer />
			<Decoder id={id} />
			{/* <InitStore /> */}
			<Suspense fallback={<Loading full={true} />}>
				{preview?.isPreview && <Preview />}
				{!preview?.isPreview && <App debug={DEBUG} id={id} />}
			</Suspense>
			{ENABLE_AI_BOT && <QurealBotWidget />}
		</DndProvider>
	);
}

export default Application;
