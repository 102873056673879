import React from "react";
import { BASE_URL } from "../../constants";
import { restoreAccessToken } from "./auth";

export function getFileURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?access_token=${restoreAccessToken()}`;
    } else {
        // return AvatarPhoto;
        return null;
    }
}
