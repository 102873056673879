import styles from "./styles/FilePreviews.module.css";
import React from "react";
import classNames from "classnames";

function getIcon(type) {
    if (type.startsWith("audio")) {
        return "fas fa-music";
    } else if (type.startsWith("video")) {
        return "fas fa-video";
    }
    return "fas fa-file";
}

export const FilePreview = ({ item }) => {
    return (
        <div className={styles.item}>
            <i className={classNames(getIcon(item.type), styles.icon)}></i>
            <p className={styles.label}>{item.filename_download}</p>
        </div>
    );
};
