/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React, { createRef, useLayoutEffect } from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useAction } from "../../../hooks";

// TODO: work on responsive style
function InvisibleButton({ id, db, gs }) {
    const { performAction } = useAction();
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;

    let borderRadius = "10000px";
    if (input.frameShape.type === "square") {
        borderRadius = "0px";
    } else if (input.frameShape.type === "custom") {
        borderRadius = input.frameShape.value;
    }

    return (
        <ElementWrapper id={id}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <span
                    className={input.hoverAnimation}
                    css={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        opacity: 0,
                        "&:hover": {
                            ...decodeStyle(ps.hover, gs),
                            opacity: input.opacity + 0.1,
                        },
                        "&:active": {
                            opacity: input.opacity,
                        },
                        // alignSelf: input.alignImage,
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: borderRadius,
                    }}
                    onClick={() => performAction(input.action)}
                ></span>
            </div>
        </ElementWrapper>
    );
}

export default InvisibleButton;
