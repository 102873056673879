import log from "cslog";
import { nanoid } from "nanoid";
import { GSTYLE, THEME_COLORS, THEME_FONTSET } from "../../data/gstyle";
// import { ELEMENTS } from "../absolute/data/elements";
// import { ELEMENTS } from "../responsive/data/elements";
// import { ELEMENTS } from "../../data/elements/responsive/elements";
import { ELEMENTS } from "../../data/elements/absolute/elements";
// import { DEFAULT_HEADER_SECTION, DEFAULT_NAV_MENU } from "./defaults";
import {
    DEFAULT_NAV_MENU,
    DEFAULT_HEADER_SECTION,
    PAGE_BEFORE_ID,
    PAGE_AFTER_ID,
} from "../../data/defaults";

export function addApp(name, slug) {
    const screen1_id = nanoid(10);
    const screen2_id = nanoid(10);
    const app1_id = slug;
    const out_app1 = {};
    const tool_id = "root";

    const screen1_data = {
        ...ELEMENTS["qureal_screen"],
        id: screen1_id,
        pid: app1_id,
    };
    out_app1[screen1_id] = screen1_data;

    const screen2_data = {
        ...ELEMENTS["qureal_screen"],
        id: screen2_id,
        pid: app1_id,
    };
    out_app1[screen2_id] = screen2_data;

    const app1_data = {
        ...ELEMENTS["qureal_app"],
        id: app1_id,
        childs: [screen1_id, screen2_id],
        pid: tool_id,
    };
    out_app1[app1_id] = app1_data;

    return out_app1;
}

function addBox(box_id, section_id) {
    const out = {};

    //==========
    const header_id = nanoid(10);
    const header_data = {
        ...ELEMENTS["header"],
        id: header_id,
        pid: box_id,
    };
    out[header_id] = header_data;
    //==========

    const box_data = {
        ...ELEMENTS["box"],
        id: box_id,
        pid: section_id,
        childs: [header_id],
    };

    out[box_id] = box_data;
    return out;
}

const getNewTool = () => {
    log.p("Generating tool data");
    const data = {};

    const name = "Unnamed Tool";
    data["title"] = name;
    data["slug"] = `tool_${nanoid(5)}`;
    data["type"] = "tool";

    const info = {
        id: 1,
        type: "tool",
        layout: "grid",
        pagination: {
            type: "named",
            fixed: ["home", "404"],
            floatingIndex: true,
            allowAdd: true,
            pageMultiple: 1,
        },
        devices: ["desktop", "tab", "mobile"],
        homePage: "home",
    };
    data["info"] = info;

    const gconfig = {
        colors: THEME_COLORS,
        fontSets: THEME_FONTSET,
    };

    const gstyles = {};
    Object.keys(GSTYLE).map((key) => {
        const style_data = { id: key, ...GSTYLE[key] };
        gstyles[key] = style_data;
    });

    const globalConfig = {
        defaultFontFamily: `Arial, Helvetica, sans-serif`,
        pageBackground: `#f9f6ff`,
    };

    data["gconfig"] = {
        gconfig: gconfig,
        gstyles: gstyles,
        globalConfig: globalConfig,
    };

    //-------------------------------------------------
    // Create new screen
    const header1_id = nanoid(10);
    const button1_id = nanoid(10);
    const gb1_id = nanoid(10);
    const gb2_id = nanoid(10);
    const screen1_id = nanoid(10);
    const screen2_id = nanoid(10);
    const app1_id = "home";
    const out_app1 = {};
    const tool_id = "root";

    const header1_data = {
        ...ELEMENTS["header"],
        id: header1_id,
        pid: gb1_id,
    };
    out_app1[header1_id] = header1_data;

    const button1_data = {
        ...ELEMENTS["button"],
        id: button1_id,
        pid: gb1_id,
    };
    out_app1[button1_id] = button1_data;

    const gb1_data = {
        ...ELEMENTS["gridbox"],
        id: gb1_id,
        pid: screen1_id,
        childs: [header1_id, button1_id],
        layouts: {
            [header1_id]: {
                i: header1_id,
                x: 0,
                y: 0,
                w: 12,
                h: 5,
            },
            [button1_id]: {
                i: button1_id,
                x: 3,
                y: 10,
                w: 10,
                h: 10,
            },
        },
    };
    out_app1[gb1_id] = gb1_data;

    const gb2_data = {
        ...ELEMENTS["gridbox"],
        id: gb2_id,
        pid: screen2_id,
    };
    out_app1[gb2_id] = gb2_data;

    const screen1_data = {
        ...ELEMENTS["qureal_screen"],
        id: screen1_id,
        pid: app1_id,
        childs: [gb1_id],
    };
    out_app1[screen1_id] = screen1_data;

    const screen2_data = {
        ...ELEMENTS["qureal_screen"],
        id: screen2_id,
        pid: app1_id,
        childs: [gb2_id],
    };
    out_app1[screen2_id] = screen2_data;

    const app1_data = {
        ...ELEMENTS["qureal_app"],
        id: app1_id,
        childs: [screen1_id, screen2_id],
        pid: tool_id,
    };
    out_app1[app1_id] = app1_data;

    //Create new took
    const tool_data = {
        ...ELEMENTS["qureal_tool"],
        id: tool_id,
        apps: [app1_id],
    };
    //-------------------------------------------------

    const out_404 = addApp("404", "404");
    const out_about_us = addApp("About Us", "about_us");

    const page_info = [
        { name: "Home", id: "home" },
        { name: "About Us", id: "about_us" },
        { name: "404", id: "404" },
    ];
    data["data"] = {
        page_info: page_info,
        pages: [
            {
                id: "home",
                name: "Home",
                info: { id: "home", name: "Home" },
                visibility: "PUBLIC",
                html: out_app1,
            },
            {
                id: "about_us",
                name: "About Us",
                info: { id: "about_us", name: "About Us" },
                visibility: "PUBLIC",
                html: out_about_us,
            },
            {
                id: "404",
                name: "404",
                info: { id: "404", name: "404" },
                visibility: "PUBLIC",
                html: out_404,
            },
        ],
    };

    data["others"] = {};

    const seo = {
        title: "",
        description: "",
        favicon: process.env.PUBLIC_URL + "img_pp.png",
        previewImage: {
            params: null,
            value: "/img_pp.png",
        },
        ogTitle: "",
        ogImage:
            "https://images.unsplash.com/photo-1565118531796-763e5082d113?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTIyMTV8MHwxfHNlYXJjaHw1fHx8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080",
    };
    data["seo"] = seo;

    log.d(data, "CREATED DATA");
    return data;
};

export default getNewTool;
