import React, { useState, useEffect } from "react";
import log from "cslog";
import { Modal, Button } from "semantic-ui-react";
import SlateEditor from "./SlateEditor";

export function SlateEditorDialog({ html, setHtml, trigger }) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(html);

    useEffect(() => {
        setData(html);
    }, [html]);

    const custom_style = {
        fontSize: "1.1rem",
        minHeight: "75vh",
    };

    // log.d(html, "HTML in QTEDialog");

    return (
        <Modal
            size="auto"
            open={open}
            trigger={trigger}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
            style={{
                marginTop: "-10px",
                backgroundColor: "yellow",
            }}
        >
            {/* <Modal.Header>Qureal Text Editor</Modal.Header> */}
            <Modal.Content
                style={{
                    padding: 0,
                    paddingTop: "1px",
                    marginTop: "-12px",
                    backgroundColor: "rgb(240, 240, 240)",
                    marginBottom: "-20px",
                }}
            >
                {/* <h2>Slate Editor</h2> */}
                <SlateEditor data={data} setData={setData} />
            </Modal.Content>
            <Modal.Actions
                style={{
                    textAlign: "center",
                }}
            >
                <Button
                    color="black"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancle
                </Button>
                <Button
                    primary
                    onClick={() => {
                        setHtml(data);
                        log.d(data, "HTML Data");
                        setOpen(false);
                    }}
                >
                    Done
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SlateEditorDialog;
