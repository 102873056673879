import React, { useEffect, useState } from "react";
import Viewer from "./showcase/ViewerReact";
import log from "cslog";
import { encodeData } from "./editors/encode";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { Button, Icon } from "semantic-ui-react";
import { previewState } from "./db/previewDb";
import ViewerCustomizer from "./customize/ViewerCustomizer";

function Preview() {
	const setPreview = useSetRecoilState(previewState);
	const [data, setData] = useState(null);

	const prepareData = useRecoilCallback(({ snapshot }) => async () => {
		log.p("Loading data....")
		const data = await encodeData(snapshot, null);
		// const data = await encodeData(snapshot, null, true);
		return data;
	});

	useEffect(() => {
		async function loadData() {
			const temp = await prepareData();
			temp["type"] = temp.info["type"];
			setData(temp);
		}
		if (!data) {
			loadData();
		}
	}, []);

	const layout = data?.info?.layout;

	return data ? (
		<div
			style={{
				overflow: "hidden",
				backgroundColor: "cyan",
			}}
		>
			<Viewer data={data} />

			{/* {layout === "absolute" && <ViewerCustomizer />} */}
			<div
				style={{
					position: "fixed",
					top: 0,
					right: 0,
					padding: "5px",
					color: "#777",
					zIndex: 200,
					backgroundColor: "white",
					border: "1px solid black",
					borderRadius: "5px",
					cursor: "pointer",
				}}
				onClick={() => setPreview({ isPreview: false })}
			>
				<Icon link name="close" size="big" />
			</div>
			<div style={{
				position: "fixed",
				top: 0,
				left: 0,
				zIndex: 200,
				height: "80px",
				// border: "2px solid red"
			}}>
				{layout === "absolute" && <ViewerCustomizer />}
			</div>
		</div>
	) : (
		<h1>Loading,...</h1>
	);
}

export default Preview;
