import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import styles from "./styles/PanelSection.module.css";
import { useRecoilValue } from "recoil";
import { dataPageState } from "../db/dataDb";
import { bookInfoState, currentBookPageState } from "../db/bookConfig";
import log from "cslog";
import { startCase } from "lodash";
import { getAssetURL, getFileURL } from "../_helper/image";
import { height } from "../customize/ComplexOptions";
import { selectedPageState } from "../db/siteConfig";
import { useDrag } from "react-dnd";

function DataComponents() {
	// const currentBookPage = useRecoilValue(currentBookPageState);
	const info = useRecoilValue(bookInfoState)
	const currentPage = useRecoilValue(selectedPageState)
	const currentBookPage = useRecoilValue(currentBookPageState)
	const current_page_id =
		(info?.layout === 'responsive' || info?.layout === 'document') ? currentPage?.id : currentBookPage?.pid
	const page_db = useRecoilValue(dataPageState(current_page_id)) || {};

	log.d(page_db, "page_db in DC");

	return (
		<div>
			<div className={styles.main_heading}>Data Components</div>
			{Object.keys(page_db).map((key) => {
				const item = page_db[key];
				if (!item.isValid) {
					return null;
				}
				const fields = item?.data?.fields || [];
				if (item.block_type === "fillable" || item.block_type === "qureal_preset") {
					return (
						<DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={fields}
							layout={info.layout}
						/>
					);
				} else if (item.block_type === "CSV") {
					return (
						<DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={fields}
							layout={info.layout}
						/>
					);
				} else if (item.block_type === "qureal_database") {
					if (item?.data?.display) {
						return <DataComponentPanel
							item={item}
							key={key}
							id={key}
							fields={Object.keys(item?.data?.display).map(key => ({
								name: key,
								type: item?.data?.display[key].type
							}))}
							layout={info.layout}
						/>
					} else {
						return <div>
							<p style={{ padding: "10px" }}>Error with {item.name}</p>
							<p>{JSON.stringify(item)}</p>
						</div>
					}
				}
				return null;
			})}
		</div>
	);
}

export default DataComponents;

function DataComponentPanel({ id, item, fields, layout }) {
	const [active, setActive] = useState(true);

	return (
		<Accordion fluid styled key={"data"} style={{ background: "#F3F3F3" }}>
			<Accordion.Title
				active={active}
				style={{ background: "#F3F3F3" }}
				onClick={() => setActive(!active)}
			>
				<Icon name="dropdown" />
				{item.name}
			</Accordion.Title>
			<Accordion.Content active={active}>
				<div className={styles.panelSection}>
					{fields.map((f, index) => {
						if (f.type === "image") {
							return <ImageItem
								label={f.name}
								content={{
									id: id,
									field: f.name,
								}}
							/>
						} else {
							if (layout === "absolute") {
								return (
									<HeaderItem
										label={f.name}
										content={{
											id: id,
											field: f.name,
										}}
									/>
								)
							} else {
								return <HeaderItemResponsive
									label={f.name}
									content={{
										id: id,
										field: f.name,
									}}
								/>
							}
						}
					})}
				</div>
			</Accordion.Content>
		</Accordion>
	);
}

function ImageItem({ label, content }) {
	log.d(content, "ImageItem content");
	return (
		<div
			draggable={true}
			onDragStart={(e) => {
				e.dataTransfer.setData(
					"text/plain",
					JSON.stringify({
						type: "img",
						elementGroup: "basic",
						data: {
							input: {
								imageSource: {
									...content,
									temp_info: {
										width: 200,
										height: 200,
									}
								},
								imageFit: "contain"
							},
						},
					})
				);
			}}
			className={styles.panelItem}
		>
			<div>{startCase(label)}</div>
			<div className={styles.item_type}>(Image)</div>
		</div>
	);
}


function HeaderItem({ label, content }) {
	const [type, setType] = useState("header");
	return (
		<div
			draggable={true}
			onDragStart={(e) => {
				e.dataTransfer.setData(
					"text/plain",
					JSON.stringify({
						// type: "header",
						type: type,
						elementGroup: "basic",
						data: {
							input: {
								content: content,
							},
						},
					})
				);
			}}
			onClick={() => setType(type === "header" ? "p" : "header")}
			className={styles.panelItem}
		>
			<div>{startCase(label)}</div>
			<div className={styles.item_type}>({type === "p" ? "Text" : "Header"})</div>
		</div>
	);
}

function HeaderItemResponsive({ label, content }) {
	const [type, setType] = useState("header");
	const [{ opacity, isDragging }, dragRef] = useDrag(
		() => ({
			type: "primary",
			item: { data: {} },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1,
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[]
	);

	return (
		<div
			ref={dragRef}
			onClick={() => setType(type === "header" ? "p" : "header")}
			className={styles.panelItem}
		>
			<div>RES{startCase(label)}</div>
			<div className={styles.item_type}>({type === "p" ? "Text" : "Header"})</div>
		</div>
	);
}