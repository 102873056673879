import { atom, useAtom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { useEffect, useMemo } from "react";
import { BASE_URL } from "../constants";
import log from "cslog";

export const actionUIAtom = atom({
	type: "none",
	params: {},
});

export const selectedPageAtom = atom({
	id: "home",
	name: "Home",
});

export const lightboxAtom = atom({
	type: "lightbox",
	params: [],
});

export const globalVarsAtom = atom({});

export const globalDbConfig = atom({});

export const usePageDbConfig = (page_id) => {
	const [dbp, setDbp] = useAtom(globalDbConfig);

	return [
		dbp[page_id] || {},
		(newVal) =>
			setDbp((old_dbp) => ({
				...old_dbp,
				[page_id]: newVal,
			})),
	];
};

// export const baseURLAtom = atom(BASE_URL);
export const baseURLAtom = atom(null);

//---E-commerce-----------------------------
export const shopingCartAtom = (website_id) =>
	// atom(`qecom_cart_${website_id}`, { count: 0 });
	atomWithStorage(`qecom_cart_${website_id}`, { count: 0, items: [] });

// export const useShopingCart = (website_id) => {
//     const cartAtom = useMemo(() => {
//         return atomWithStorage(`qcart_${website_id}`, { count: 0, items: {} });
//     }, [website_id]);
//     const [cart, setCart] = useAtom(cartAtom);

//     return [cart, setCart];
// };

// const scAtom = atomWithStorage("qcart_hello", { count: 0, items: [] });
const scAtom = atomFamily((id) => atomWithStorage(id, { count: 0, items: [] }));

// export const useShopingCart = (id) => {
//     const [cart, setCart] = useAtom(scAtom);
//     return [cart, setCart];
// };

export const useShopingCart = (org_id, user_id) => {
	const cart_name = `q__${org_id}__${user_id || "unauth"}__cart`;
	const [cart, setCart] = useAtom(scAtom(cart_name));
	const [unauth_cart, setUnauthCart] = useAtom(
		scAtom(`q__${org_id}__unauth__cart`)
	);

	useEffect(() => {
		if (user_id) {
			if (cart?.count === 0 && unauth_cart?.count > 0) {
				setCart(unauth_cart);
				setUnauthCart({ count: 0, items: [] });
			}
		}
	}, [user_id, cart.count, unauth_cart]);

	function emptyCart() {
		setCart({ count: 0, items: [] });
	}

	return [cart, setCart, emptyCart];
};

// const shoppingAddressAtom = atomWithStorage("qecom_address", null);
const shoppingAddressAtom = atomFamily((id) => atomWithStorage(id, null));

// export const useShoppingAddress = () => {
//     const [address, setAddress] = useAtom(shoppingAddressAtom);
//     return [address, setAddress];
// };

export const useShoppingAddress = (org_id, user_id) => {
	const address_name = `q__${org_id}__${user_id || "unauth"}__address`;
	const [address, setAddress] = useAtom(shoppingAddressAtom(address_name));

	return [address, setAddress];
};

//------------------------------------------

// Fillable====================================
export const fillableAllState = atom({});

export const useFillableBlock = (block_id) => {
	const [fdb, setFdb] = useAtom(fillableAllState);

	return [
		fdb[block_id] || {},
		(newVal) =>
			setFdb((old_fdb) => ({
				...old_fdb,
				[block_id]: newVal,
			})),
	];
};

//=============================================
