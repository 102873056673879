import React from "react";
import { atom, selector, useRecoilValue } from "recoil";
import { pageState, selectedPageState } from "../../../db/siteConfig";
import { bookInfoState } from "../../../db/bookConfig";

export const globalContextState = selector({
    key: "globalContext",
    get: ({ get }) => {
        const info = get(bookInfoState);
        const pages = get(pageState);
        return {
            info,
            pages,
        };
    },
});

export const pageContextState = selector({
    key: "pageContext",
    get: ({ get }) => {
        const page = get(selectedPageState);
        return {
            page: page,
        };
    },
});
