import styles from "./styles/DataGridView.module.css";
import React from "react";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import { selectedFileState } from "../db/elementDb";

function getIcon(type) {
    if (type.startsWith("audio")) {
        return "fas fa-music";
    } else if (type.startsWith("video")) {
        return "fas fa-video";
    }
    return "fas fa-bars";
}

export const FileItemView = ({ item, isSelected, setSelected }) => {
    return (
        <div
            className={classNames(styles.item, {
                [styles.selected]: isSelected,
            })}
            onClick={() => setSelected(item)}
        >
            <i className={classNames(getIcon(item.type), styles.icon)}></i>
            <p className={styles.label}>{item.filename_download}</p>
        </div>
    );
};

function DataGridView({ data, loading, success }) {
    const [selected, setSelected] = useRecoilState(selectedFileState);
    return (
        <div className={styles.wrapper}>
            {data &&
                data.map((item, index) => {
                    return (
                        <FileItemView
                            item={item}
                            isSelected={selected?.id === item.id}
                            setSelected={setSelected}
                        />
                    );
                })}
        </div>
    );
}

export default DataGridView;
