import PRIMARY from "./primary";
import WIDGETS from "./widgets";
import PANEL from "./panel";
import TOOLS from "./tools";

const ELEMENTS = {
    // primary: PRIMARY,
    // widgets: WIDGETS,
    ...PRIMARY,
    ...WIDGETS,
    ...TOOLS,
};

export { PRIMARY, WIDGETS, PANEL, ELEMENTS };
