import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMe } from "./editor/actions/auth";
import {
    restoreAccessToken,
    restoreRefreshToken,
    saveTokens,
    saveUser,
    setAuthToken,
} from "./editor/_helper/auth";
import { history } from "./editor/_helper/history";
import log from "cslog";
import Loading from "./editor/Loading";

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyAuth({ children }) {
    const queryParams = useQueryParams();
    const [isAuth, setAuth] = useState(false);
    const { data: dataMe, status: statusMe, error: errorMe } = useMe(isAuth);
    const location = useLocation();
    const historyL = useHistory();

    // log.d(dataMe, "Data ME");
    // log.d(errorMe, "Erro rMe");

    function removeQeeryParams() {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has("at")) {
            queryParams.delete("at");
            if (queryParams.has("rt")) {
                queryParams.delete("rt");
            }
            historyL.replace({
                search: queryParams.toString(),
            });
        }
    }

    useEffect(() => {
        let access_token = queryParams.get("at");
        let refresh_token = queryParams.get("rt");
        if (access_token) {
            setAuthToken(access_token);
            saveTokens({
                access_token: access_token,
                refresh_token: refresh_token,
            });
            setAuth(true);
        } else if (restoreAccessToken()) {
            access_token = restoreAccessToken();
            setAuthToken(access_token);
            saveTokens({
                access_token: access_token,
                refresh_token: restoreRefreshToken(),
            });
            setAuth(true);
        } else {
            history.push("/login");
            setAuth(false);
        }
    }, []);

    useEffect(() => {
        if (statusMe === "success") {
            saveUser(dataMe);
            removeQeeryParams();
        } else if (statusMe === "error") {
            if (errorMe.response?.status === 401) {
                history.push("/login");
            }
        }
    }, [statusMe, dataMe]);

    // if (isAuth && statusMe === "success") {
    if (isAuth) {
        return children;
    }

    if (errorMe) {
        return <h3>Failed to login</h3>;
    }
    // return <h1>Varifying user...</h1>;
    return <Loading title="Verifying user..." full={true} />;
}

export default VerifyAuth;
