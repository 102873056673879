import log from "cslog";
import React, { useMemo, useRef } from "react";
import styles from "./styles/AutoFit.module.css";
import { useDivSize } from "./utils";
// import FillablePanel from "../../dynamicdata/fillable/FillablePanel";
import { QIFrameComm, ShowBar } from "../junctionAbs";

function AutoFit({ ctx = {}, layout, gap = 5, vi, children, page_content, is_preview, protected_access = {}, creation_id }) {
	// const { width: dw, height: dh } = useWindowDimensions();
	const divRef = useRef(null);
	const { width: dw, height: dh } = useDivSize(divRef);

	const { width, height } = layout?.pageSize?.value;

	log.d([dw, dh], "Device WH");
	log.d([width, height], "Creation WH");

	const is_fit = Object.keys(ctx).includes("fit");
	const vi_gap = is_fit ? 0 : gap;


	const vi_zoom = is_fit ? 100 : ctx?.zoom ? ctx.zoom : vi?.zoom || 100;

	const dims = useMemo(() => {
		const rw = dw / width;
		const rh = dh / height;

		const zoom = rw < rh ? rw : rh;
		return {
			zoom: zoom * (vi_zoom / 100),
		};
	}, [width, height, dw, dh, vi]);


	return (
		<div className={styles.body_wrapper} style={{
			// backgroundColor: ctx?.bg ? `#${ctx.bg}` : "black"
			// backgroundColor: ctx?.bg ? ctx.bg : "black"
			background: ctx?.background ? ctx.background : "black"
		}} >
			<div
				ref={divRef}
				className={styles.wrapper}
			>
				<div
					className={styles.box}
					style={{
						transform: `scale(${dims.zoom - vi_gap / 100})`,
						// backgroundColor: "hotpink",
						...(is_fit ? { boxShadow: "none" } : {}),

					}}
				>
					{children}
				</div>
			</div>


			{!is_preview && <ShowBar
				page_content={page_content}
				protected_access={protected_access}
				creation_id={creation_id}
				dims={{
					width: Math.ceil(width * dims.zoom),
					height: Math.ceil(height * dims.zoom),
				}}
			/>}
			<QIFrameComm />
		</div>
	);
}

export default AutoFit;
