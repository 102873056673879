import { useSlate } from "slate-react";
import { Button as CButton, Icon } from "../components";
import { Modal, Button, Popup } from "semantic-ui-react";
import {
    toggleStyle,
    isStyleActive,
    setStyle,
    removeStyle,
    removeOneStyle,
} from "../utils/style";
import { SketchPicker } from "react-color";
import { useState } from "react";
import log from "cslog";
import { QColorPicker } from "../../../customize/SimpleOptions";

const StyleButton = ({
    format,
    icon,
    onOpen,
    onChange = () => {},
    onDone = () => {},
}) => {
    const editor = useSlate();
    const [col, setCol] = useState("black");

    return (
        <Popup
            style={{
                zIndex: 11100,
            }}
            trigger={
                <CButton active={isStyleActive(editor, format)}>
                    <Icon>{icon}</Icon>
                    <div
                        style={{
                            width: "100%",
                            height: "3px",
                            backgroundColor: col,
                        }}
                    ></div>
                </CButton>
            }
            eventsEnabled={true}
            on="click"
            onOpen={onOpen}
        >
            <QColorPicker
                color={col}
                // setColor={onChange}
                setColor={(color) => {
                    onChange();
                    let new_col = null;
                    if (format === "rgba") {
                        new_col = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                        setCol(new_col);
                    } else {
                        new_col = color.hex;
                        setCol(new_col);
                    }
                    setStyle(editor, format, new_col);
                    onDone();
                }}
            />
        </Popup>
    );
};

export default StyleButton;
