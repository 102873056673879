import React, { Fragment } from 'react'
import { FloatingToolbar } from './components'
import BlockButton, { FontButton } from './inputs/BlockButton'
import InsertLinkBtn from './inputs/InsertLinkBtn'
import InsertImageBtn from './inputs/InsetImageBtn'
import MarkButton from './inputs/MarkButton'
import StyleButton from './inputs/StyleButton'
import './styles/slate_design.css'
import { Toolbar } from './components'
import QVarBtn from './inputs/QVarBtn'

function SlateToolbar ({ onOpenMenu, onChangeMenu, onDone }) {
  return (
    <Fragment>
      <QVarBtn />
      <MarkButton format='bold' icon='fas fa-bold' />
      <MarkButton format='italic' icon='fas fa-italic' />
      <MarkButton format='underline' icon='fas fa-underline' />
      <MarkButton format='code' icon='fas fa-code' />
      <MarkButton format='strikethrough' icon='fas fa-strikethrough' />
      {/* <RemoveStyleButton icon="fas fa-strikethrough" /> */}
      <BlockButton format='heading-one' icon='fas fa-heading' />
      <BlockButton format='heading-two' icon='fas fa-heading fa-xs' />
      <StyleButton format='color' icon='fas fa-font' />
      <StyleButton
        format='bgColor'
        icon='fas fa-fill-drip'
        onOpen={onOpenMenu}
        onChange={onChangeMenu}
        onDone={onDone}
      />
      <BlockButton format='block-quote' icon='fas fa-quote-left' />
      <BlockButton format='numbered-list' icon='fas fa-list-ol' />
      <BlockButton format='bulleted-list' icon='fas fa-list-ul' />
      <BlockButton format='left' icon='fas fa-align-left' />
      <BlockButton format='center' icon='fas fa-align-center' />
      <BlockButton format='right' icon='fas fa-align-right' />
      <BlockButton format='justify' icon='fas fa-align-justify' />
      {/* <FontButton format="fontChange" icon="fas fa-quote-left" /> */}
      <InsertLinkBtn />
      <InsertImageBtn />
    </Fragment>
  )
}

export function SlateToolbarFloating ({ onOpenMenu, onChangeMenu, onDone }) {
  return (
    <FloatingToolbar>
      <SlateToolbar
        onOpenMenu={onOpenMenu}
        onChangeMenu={onChangeMenu}
        onDone={onDone}
      />
    </FloatingToolbar>
  )
}

export function SlateToolbarAttached ({
  onOpenMenu = () => {},
  onChangeMenu = () => {},
  onDone
}) {
  return (
    <Toolbar>
      <SlateToolbar
        onOpenMenu={onOpenMenu}
        onChangeMenu={onChangeMenu}
        onDone={onDone}
      />
    </Toolbar>
  )
}
