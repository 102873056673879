import React, { useEffect, useState } from "react";
import { useAllFields } from "./apis/collections";
import { Dropdown } from "semantic-ui-react";
import startCase from "lodash/startCase";
import log from "cslog";

const checkType = (field, ftype) => {
    const itype = field.interface.type;
    const vtype = field.value_type;
    if (field.name === "id") {
        return true;
    }
    switch (ftype) {
        case "all":
            return true;
        case "text":
        case "string":
            if (
                ["text", "number", "float", "textarea", "richtext"].includes(
                    itype
                )
            ) {
                if (
                    ["string", "integer", "float", "text", "uuid"].includes(
                        vtype
                    )
                ) {
                    return true;
                }
            } else if (itype === "datetime") {
                return true;
            } else if (
                itype === "relationM2O" &&
                field.interface.foreign_key_table === "directus_users"
            ) {
                return true;
            }
            return false;
        case "number":
            if (["number"].includes(itype)) {
                if (["integer"].includes(vtype)) {
                    return true;
                }
            }
            return false;
        case "image":
            if (["image"].includes(itype)) {
                return true;
            }
            return false;
        case "color":
            if (itype === "select-color") return true;
            return false;
        case "imgArray":
            if (
                itype === "relationM2M" &&
                field.interface?.foreign_key_table === "directus_files"
            )
                return true;
            return false;
        default:
            return false;
    }
};

const FieldDropdown = ({ table, ftype, value, setValue, ...props }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (table) {
            const options = [];
            table.fields.forEach((item) => {
                if (checkType(item, ftype)) {
                    options.push({
                        key: item.name,
                        text: item.label,
                        value: item.name,
                    });
                }
            });
            setOptions(options);
        }
    }, [table]);

    return (
        <Dropdown
            selection
            value={value}
            onChange={(e, { value }) => setValue(value)}
            // loading={isLoading}
            options={options}
            {...props}
        />
    );
};

export default FieldDropdown;
