import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { secureAxios } from "../../_helper/auth";

export const useAllCollections = (enabled) => {
	return useQuery(
		["tables"],
		() =>
			secureAxios
				.get(
					// "/items/qdb_table?fields=*,fields.*&filter[id][_starts_with]=qd_"
					"/items/qdb_table?fields=*,fields.*"
				)
				.then((res) => res.data),
		{
			enabled: enabled,
		}
	);
};

export const useSingleTable = (table, enabled = true) => {
	const { isSuccess, isLoading, data, isError } = useAllCollections(
		enabled && table !== null
	);
	const [one, setOne] = useState(null);

	useEffect(() => {
		if (isSuccess && data) {
			const all = data.filter((item) => item.id === table);
			let one = all.length > 0 ? all[0] : null;
			setOne(one);
		}
	}, [table, data, isSuccess])


	return { isSuccess, isLoading, data: one, isError };
};

export const useSingleTable2 = (table, enabled = true) => {
	const { isSuccess, isLoading, data, isError } = useAllCollections(
		enabled && table !== null
	);

	if (isSuccess && data) {
		const all = data.filter((item) => item.id === table);
		let one = all.length > 0 ? all[0] : null;
		return {
			isSuccess,
			isLoading,
			data: one,
		};
	}
	return { isSuccess, isLoading, data, isError };
};

export const useAllItems = (collection, enabled) => {
	let url = `/items/${collection}`;
	if (collection === "directus_users") {
		url = "/users";
	}
	return useQuery(
		["tables", collection],
		() => secureAxios.get(`${url}?fields=*.*`).then((res) => res.data),
		{
			enabled: enabled,
		}
	);
};

export const useAllFields = (table, enabled) => {
	return useQuery(
		["fields", table],
		() =>
			secureAxios
				.get(`/items/qdb_field?filter[table][_eq]=${table}`)
				.then((res) => res.data),
		{
			enabled: enabled,
		}
	);
};
