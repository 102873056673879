import React from "react";



export const parseDynamicSocialIcon = (content) => {
	const out = []
	Object.keys(content).map((key) => {
		if (key === "instagram_handle" && content[key]) {
			// icon, color, text
			out.push(["instagram", "#c13584", content[key]])
		} else if (key === "facebook_handle" && content[key]) {
			out.push(["facebook", "#3b5998", content[key]])
		} else if (key === "twitter_handle" && content[key]) {
			out.push(["twitter", "#1da1f2", content[key]])
		} else if (key === "linkedin_handle" && content[key]) {
			out.push(["linkedin", "#0077b5", content[key]])
		} else if (key === "youtube_handle" && content[key]) {
			out.push(["youtube", "#ff0000", content[key]])
		} else if (key === "pinterest_handle" && content[key]) {
			out.push(["pinterest", "#bd081c", content[key]])
		}
	})
	const items = out.map(item => ({
		icon: {
			params: {
				type: "fab",
				name: item[0],
			},
			value: `fab fa-${item[0]}`,
		},
		color: item[1],
		text: item[2],
		action: {
			type: "link",
			params: {
				link: "https://qureal.com",
				newTab: true,
			},
		},
	}))
	return items;
}