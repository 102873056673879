/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function IconList({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);
    // const ps = data;

    log.d(ps, "IconList data ps");

    let input = ps.input;

    function renderChilds() {
        return input.items.map((item) => {
            if (item !== "") {
                return (
                    <li
                        key={item}
                        style={{
                            margin: input.itemGap - 0.5 + "rem 0px",
                        }}
                    >
                        <span>
                            <span
                                className="fa-li"
                                css={{
                                    ...decodeStyle(ps.styleGroup.icon, gs),
                                }}
                            >
                                <i className={item.icon.value}></i>
                            </span>
                            <span
                                css={{
                                    ...decodeStyle(ps.styleGroup.text, gs),
                                }}
                            >
                                {item.text}
                            </span>
                        </span>
                    </li>
                );
            }
        });
    }

    // const ss = decodeStyle(ps.style, gs);

    return (
        <ElementWrapper id={id}>
            <ul
                className="fa-ul"
                style={{
                    fontSize: input.iconSize.value,
                    lineHeight: input.iconSize.value,
                    ...decodeStyle(ps.styleGroup.iconList, gs),
                    alignSelf: input.alignList,
                    marginTop: "-8px",
                    marginBottom: "-8px",
                }}
            >
                {renderChilds()}
            </ul>
            {/* <h2 onClick={() => setSelectedElement(id)}>Icon List</h2> */}
        </ElementWrapper>
    );
}

export default IconList;
