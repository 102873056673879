import log from "cslog";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useCreateItem } from "./actions/mutations/items";
import getNewMagazine from "./editors/magazine/new";
import getNewWebsite from "./editors/website/new";
import getNewAd from "./editors/ad/new";
import getNewCardstack from "./editors/cardstack/new";
import getNewCard from "./editors/card/new";
import getNewQDF from "./editors/qdf/new";
import getNewStories from "./editors/stories/new";
import getNewSlider from "./editors/slider/new";
import getNewCube from "./editors/cube/new";
import getNewCards from "./editors/cards/new";
import Loading from "./Loading";
import startCase from "lodash/startCase";
import { Button } from "semantic-ui-react";
import getNewPackagingBox from "./editors/packaging/box/new";
import getNewSinglePage from "./editors/singlepage/new";
import getNewTool from "./editors/solution/new";
import { useSingleItem } from "./actions/items";
import { restoreUser } from "./_helper/auth";
import getNewMultipage from "./editors/multipage/new";
import getNewFlipbook from "./editors/flipbook/new";
import getNewDocument from "./editors/document/new";

function useQueryParams() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CreateNew() {
	const query = useQueryParams();
	const [creation_type_id, setCreationTypeId] = useState(null);
	const {
		isLoading: loadingCT,
		isSuccess: successCT,
		data: dataCT,
	} = useSingleItem(
		"qdb_creation_type",
		`/${creation_type_id}`,
		creation_type_id !== null
	);
	const mutaion = useCreateItem("qp_creation");
	const [type, setType] = useState(null);
	const [isValid, setValid] = useState(true);
	const user = restoreUser();

	const params = useMemo(() => {
		let out = {};
		Array.from(query.entries()).map((item) => {
			out[item[0]] = item[1];
		});
		return out;
	}, [query]);
	// log.d(mutaion, "Mutation Result");
	log.d(params, "Query Params");

	log.d(dataCT, "DataCT");

	useEffect(() => {
		const ct_id = query.get("id");
		setCreationTypeId(ct_id);
	}, [query]);

	useEffect(() => {
		if (successCT) {
			const size = normalizeSize(dataCT.width, dataCT.height, dataCT.max_dimension);
			const config = {
				typebase: dataCT.typebase,
				layout: dataCT.layout,
				width: size.width,
				height: size.height,
				unit: dataCT.unit,
				id: dataCT.id,
				name: dataCT.name,
				ratio: dataCT.ratio,
			};

			// const type = query.get("type");

			const typebase = config.typebase;

			if (typebase) {
				let data = null;
				switch (typebase) {
					case "singlepage":
						data = getNewSinglePage(config);
						break;
					case "multipage":
						data = getNewMultipage(config);
						break;
					case "document":
						data = getNewDocument(config);
						break;
					case "magazine":
					case "flipbook":
						data = getNewFlipbook(config);
						break;
					// case "cardstack":
					//     data = getNewCardstack();
					//     break;
					case "qdf":
						data = getNewQDF();
						break;
					case "card":
						data = getNewCard();
						break;
					// case "ad":
					//     data = getNewAd();
					//     break;
					case "website":
						data = getNewWebsite();
						// getNewWebsite();
						break;
					case "tool":
						data = getNewTool();
						break;
					// case "stories":
					//     data = getNewStories();
					//     break;
					case "slider":
						data = getNewSlider();
						break;
					case "cube":
						data = getNewCube();
						break;
					case "cards":
						data = getNewCards();
						break;
					case "packaging_box":
						data = getNewPackagingBox(params);
						break;
					default:
						data = null;
						setValid(false);
				}

				log.d(data, "Data gen with new");
				if (data && !mutaion.isLoading) {
					log.d(data, "Mutating with DATA");
					const final_data = {
						...data,
						creation_type: creation_type_id,
						organization: user.selected_org,
						subscriptions: [
							{
								qi_subscriptions_id: {
									user: user.id,
									plan: "website_core",
									status: "active",
								},
							},
						],
					};
					log.d(final_data, "Final Data");
					mutaion.mutate(final_data);  // TODO: uncomment this
				}
				setType(type);
			}
		}
	}, [successCT, dataCT]);

	if (mutaion.isSuccess) {
		const id = mutaion.data?.id;
		if (id) {
			return <Redirect to={`/${id}/edit`} />;
		} else {
			alert("Error, check CreateNew");
		}
		// return <Redirect to="/" />;
	}

	return isValid ? (
		<Loading title={`Generating ${startCase(type)}`} full={true} />
	) : (
		<div
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				justifyContent: "center",
				alignItems: "center",
				color: "gray",
			}}
		>
			<h2>Type {type} not supported yet</h2>
			<a href="http://app.qureal.com">
				<Button>Go Back</Button>
			</a>
		</div>
	);
}

function normalizeSize(width, height, max = 720) {
	// const MAX_W = 520;
	// const MAX_H = 520;
	const MAX_W = max;
	const MAX_H = max;
	if (width && height) {
		if (width >= height) {
			const MAX = MAX_W;
			const out_h = parseInt((MAX * height) / width);
			return { width: MAX, height: out_h };
		} else {
			const MAX = MAX_H;
			const out_w = parseInt((MAX * width) / height);
			return { width: out_w, height: MAX };
		}
	}
	return { width: 0, height: 0 };
}
