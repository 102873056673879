/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import Marquee from "react-fast-marquee";
// import { decodeStyle, parseStyle } from "../parser/styleparser";
import { decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function SlidingText({ id, db, gs }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);
    log.d(ps, "PS");

    let input = ps.input;

    return (
        <ElementWrapper id={id}>
            <Marquee
                play={true}
                gradient={false}
                speed={input.speed}
                direction={input.directionReverse ? "right" : "left"}
                // pauseOnHover={true}
                delay={0}
                style={{
                    ...decodeStyle(ps.style, gs),
                    height: "100%",
                    // backgroundColor: "cyan",
                    // color: "yellow",
                }}
            >
                <span
                    style={{
                        fontSize: input.fontSize,
                        textTransform: input.uppercase ? "uppercase" : "none",
                        paddingRight: "20px",
                        fontWeight: input.bold ? "bold" : "normal",
                    }}
                >
                    {input.content}
                </span>
            </Marquee>
        </ElementWrapper>
    );
}

export default SlidingText;
