import React, { useCallback, useState } from 'react'
import { useEditor, useSlate } from 'slate-react'
import { Button, Icon } from '../components'
import { insertParagraph } from '../utils/paragraph'
import { Transforms } from 'slate'
import log from 'cslog'
import { Element as SlateElement, Editor, Range } from 'slate'

const unwrapButton = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button'
  })
}

const wrapButton = editor => {
  if (isButtonActive(editor)) {
    unwrapButton(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const button = {
    // type: 'button',
    type: 'q_var',
    // children: isCollapsed ? [{ text: 'Edit me!' }] : []
    children: isCollapsed ? [{ text: 'Q' }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, button)
    Transforms.collapse(editor, { edge: 'end' })
    Transforms.move(editor, {
      distance: 3
      //   unit: 'word'
    })
    Transforms.insertText(editor, ' ')
    // insertParagraph(editor, ' ')
    // Transforms.insertNodes(
    //   editor,
    //   { text: ' nn' },
    //   {
    //     at: { offset: 3 }
    //   }
    // )
  } else {
    Transforms.wrapNodes(editor, button, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const insertButton = editor => {
  if (editor.selection) {
    wrapButton(editor)
  }
}

const isButtonActive = editor => {
  const [button] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button'
  })
  return !!button
}

const QVarBtn = () => {
  const editor = useSlate()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        if (isButtonActive(editor)) {
          unwrapButton(editor)
        } else {
          insertButton(editor)
        }
      }}
    >
      <Icon style={{ color: '#edd03b' }}>fas fa-database</Icon>
    </Button>
  )
}

export default QVarBtn
